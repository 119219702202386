import { BrowserRouter } from "react-router-dom";
import PageRoutes from './utils/routes'


const App = () => {

  return (
    <BrowserRouter>
      <PageRoutes />
    </BrowserRouter>
  )
}

export default App
