import { Descriptions, Checkbox, Form, Input, Col } from 'antd';



const OtherInfo = () => {



    return (

        <Descriptions column={1} >
            <Descriptions.Item label="Latitude">54.77757</Descriptions.Item>
            <Descriptions.Item label="Longitude">55.676767</Descriptions.Item>
            <Descriptions.Item label="Tax Name">GST</Descriptions.Item>
            <Descriptions.Item label="Tax Number">2273283232732932</Descriptions.Item>
            <Descriptions.Item label="Pan Number">666565656</Descriptions.Item>
            <Descriptions.Item label="Address">#123, time square,bhuj-kutch</Descriptions.Item>

        </Descriptions>
    )

}

export default OtherInfo