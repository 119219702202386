import React, { useState, useEffect } from 'react'

import { ExclamationCircleOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, TableOutlined, ReloadOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Divider, Button, Tag, Table, Modal, Card, Col, Row, Drawer, Form, Input, Radio, InputNumber, Select } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import Main from '../../component/layoutnew/Main';
import PlanEdit from '../../component/pageComponents/planEdit';
import { motion } from 'framer-motion'
const { confirm } = Modal;




function Plan() {
    const [form] = Form.useForm();

    const [plans, setplans] = useState([])
    const [isDataChanged, setisDataChanged] = useState(false)
    const [isLoading, setisLoading] = useState(true)
    const [isCreate, setisCreate] = useState(false)
    const [isCreating, setisCreating] = useState(false)
    const [editData, seteditData] = useState({})
    const [features, setfeatures] = useState([{ isAvailable: false, title: "hello world" }])

    useEffect(() => {
        getAllPlans()
    }, [isDataChanged])



    const onSuccessCreate = values => createPlan(values)
    const closeEdit = () => seteditData({})
    const dataChanged = () => setisDataChanged(!isDataChanged)

    const removeFeature = (index) => {
        const tempFeatures = [...features]
        tempFeatures.splice(index, 1)
        setfeatures(tempFeatures)
    }

    const createPlan = async (data) => {
        try {
            setisCreating(true)
            const response = await axios.post(apiRoutes.createPlan, data)
            if (response?.data?.success) {
                setisCreating(false)
                setisCreate(false)
                setisDataChanged(!isDataChanged)
            }
        } catch (error) {
            setisCreating(false)
            console.log(error)
        }
    }





    const getAllPlans = async () => {
        try {
            setisLoading(true)
            const response = await axios.get(apiRoutes.plans)
            if (response?.data?.data) {
                setplans(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }



    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'id',
            width: '20%',
        },
        {
            title: 'Subtitle',
            dataIndex: 'subTitle',
            key: 'id',
            width: '20%',


        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'id',
        },
        {
            title: 'Product Count',
            dataIndex: 'productCount',
            key: 'id',
        },
        {
            title: 'Type',
            dataIndex: 'commissionType',
            key: 'id',
            render: (_, record) => record?.commissionType === parseInt(1) ? "Fixed" : "Percentage"
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'id',

        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Published
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                Unpublished
            </Tag>,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="small">
                    <Button type="link" onClick={() => seteditData(record)}>Edit </Button>
                    <Divider type="vertical" />
                    <Button type="link" onClick={() => showPromiseConfirm(record.id)}>Delete</Button>
                </Space>
            ),
            width: "2%"
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.trashPlan, { planId: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setisDataChanged(!isDataChanged)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
        });
    };




    return (
        <Main page='plan'>
            <motion.div className="tabled" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title="Plans"
                            extra={<Space>
                                <Button onClick={getAllPlans} icon={<ReloadOutlined />} size="middle" />
                                <Button icon={<TableOutlined />} size="middle" />
                                <Button icon={<DownloadOutlined />} size="middle" />


                                <Button onClick={() => setisCreate(true)} size="small" type="primary">Create</Button></Space>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey="id" bordered={false} size='small' columns={columns} dataSource={plans} pagination={true} search={true} loading={isLoading} style={{ margin: 0 }} />

                                <Drawer
                                    title="Create a new plan"
                                    width={720}
                                    onClose={() => setisCreate(false)}
                                    open={isCreate}
                                    bodyStyle={{
                                        paddingBottom: 80,
                                    }}
                                    extra={
                                        <Space>
                                            <Button disabled={isCreating} onClick={() => setisCreate(true)}>Cancel</Button>
                                            <Button loading={isCreating} onClick={() => form.submit()} type="primary">Submit  </Button>
                                        </Space>
                                    }
                                >
                                    <Form layout="vertical" form={form} onFinish={onSuccessCreate}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="title"
                                                    label="Title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="subTitle"
                                                    label="Subtitle"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="price"
                                                    label="Price"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber className="w-100" />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    name="productCount"
                                                    label="Product"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber className="w-100" />
                                                </Form.Item>
                                            </Col>

                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="commissionType"
                                                    label="Commission Type"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group buttonStyle="solid">
                                                        <Radio.Button value="1">Fixed</Radio.Button>
                                                        <Radio.Button value="0">Percentage</Radio.Button>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="commission"
                                                    label="Commission"
                                                    extra="If plan accept commision on order"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber className="w-100" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>

                                            <Col span={12}>
                                                <Form.Item
                                                    name="planType"
                                                    label="Plan Type"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group buttonStyle="solid">
                                                        <Radio.Button value="1">Monthly</Radio.Button>
                                                        <Radio.Button value="2">Yearly</Radio.Button>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="validity"
                                                    label="Validity"
                                                    extra="How much days avilble this benefit"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber className="w-100" />
                                                </Form.Item>
                                            </Col>
                                        </Row>


                                        <Row gutter={24}>
                                            <Col span={24}>

                                                <Form.List name={'features'} initialValues={[
                                                    { isAvailable: true, title: "yes" }

                                                ]}>
                                                    {(fields, { add, remove }) => (
                                                        <>

                                                            <Button type="link" onClick={() => add()} >Create feature</Button>


                                                            {fields.map(({ key, name, ...restField }, index) => {
                                                                return (

                                                                    <Input.Group compact className="w-100" key={key}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            style={{ width: '20%' }}
                                                                            name={[name, 'isAvailable']} >
                                                                            <Select>
                                                                                <Select.Option value={true}>Available</Select.Option>
                                                                                <Select.Option value={false}>Not available</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            style={{ width: '72%' }}
                                                                            name={[name, 'title']} >
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Button onClick={() => remove(name)} type="primary" size="large"><PlusOutlined /></Button>
                                                                    </Input.Group>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </Form.List>


                                                {false && <Form.Item label="Features" className='W-100' name="featurfes">

                                                    <Input.Group compact className="w-100">
                                                        <Form.Item
                                                            style={{ width: '20%' }}
                                                            name={['features', 0, 'isAvailable']} >
                                                            <Select>
                                                                <Select.Option value={true}>Available</Select.Option>
                                                                <Select.Option value={false}>Not available</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ width: '72%' }}
                                                            name={['features', 0, 'title']} >
                                                            <Input />
                                                        </Form.Item>
                                                        <Button onClick={() => setfeatures([1, ...features])} type="primary" size="large"><PlusOutlined /></Button>
                                                    </Input.Group>

                                                    {features.map((item, index) => <Input.Group compact className="w-100" key={index}>
                                                        <Form.Item
                                                            style={{ width: '20%' }}
                                                            name={['features', index + 1, 'isAvailable']} >
                                                            <Select>
                                                                <Select.Option value={true}>Available</Select.Option>
                                                                <Select.Option value={false}>Not available</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ width: '72%' }}
                                                            name={['features', index + 1, 'title']} >
                                                            <Input />
                                                        </Form.Item>
                                                        <Button onClick={() => removeFeature(index + 1)} type="danger" size="large"><DeleteOutlined /></Button>
                                                    </Input.Group>)}

                                                </Form.Item>}


                                            </Col>
                                        </Row>
                                    </Form>

                                </Drawer>

                                {!!Object.keys(editData).length && <PlanEdit editData={editData} close={closeEdit} dataChanged={dataChanged} />}


                            </div>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </Main>
    )
}

export default Plan