import { memo } from 'react'
import { Col, Form, Input, Button, Space } from 'antd'

function StoreInfo({ next, prev, data }) {
    return (
        <Col span={24} className="mt-25">
            <Form
                labelAlign="left"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={data}
                onFinish={(data) => next(data)}
                autoComplete="off"
            >


                <Form.Item label="Store Name" name="store" className="mt-25" rules={[
                    {
                        required: true,
                        message: 'Store name required',
                    },
                ]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Store Address" name="address" className="mt-25" rules={[
                    {
                        required: true,
                        message: 'Store address required',
                    },

                ]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Store Descritpion" name="description" className="mt-25">
                    <Input />
                </Form.Item>




                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button onClick={() => prev()}>
                        Prev
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                </Space>

            </Form>


        </Col>
    )
}

export default memo(StoreInfo)