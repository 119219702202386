import React from 'react'

import { Checkbox, Card, Col, Row, Form, Button, Spin } from 'antd'

import Main from '../../component/layoutnew/Main'
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useParams,useNavigate } from 'react-router-dom'




function SpecificationGroupItem() {
    let { id } = useParams();
    const navigate = useNavigate()

    const [specifications, setSpecifications] = React.useState([])
    const [selectedSpecifications, setSelectedSpecificatins] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [isUpdating, setIsUpdating] = React.useState(false)

    React.useEffect(() => {
        getSpecifications()
    }, [])


    const getSpecifications = async (req, res) => {
        setIsLoading(true)
        try {
            const response = await axios.post(apiRoutes.getSpecificationGroup, { groupId: id })
            if (response?.data?.data?.attributes) {
                setSpecifications(response.data.data.attributes)
            }
            response?.data?.data?.selectedAttributes && (setSelectedSpecificatins(response?.data?.data?.selectedAttributes))
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }



    const updateSpecificationGroup = async (data) => {
        try {
            setIsUpdating(true)
            const temp = {
                groupId: id,
                ...data
            }
            const response = await axios.post(apiRoutes.updateSpecificationGRoup, temp)
            console.log(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            navigate('/specificationgroup')

        }
    }


    return (

        <Main page='specificationgroup'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={'specification attribute'}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px",paddingTop:20 }}>
                                {!isLoading ?
                                    <Form
                                        name="basic"
                                        labelCol={{
                                            span: 0,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        onFinish={(e) => updateSpecificationGroup(e)}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 0,
                                                span: 24,
                                            }}
                                            name="attributes"
                                            initialValue={selectedSpecifications}
                                        >
                                            <Checkbox.Group style={{ width: '100%' }}>
                                                <Row gutter={[0, 24]}>
                                                    {specifications.map(item =>
                                                        <Col span={4} key={item._id}>
                                                            <Checkbox value={item._id} checked>{item.title}</Checkbox>
                                                        </Col>)}
                                                </Row>
                                            </Checkbox.Group>

                                        </Form.Item>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={isUpdating}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form> : <Row type="flex" justify="center" align="middle" style={{ minHeight: '59vh' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><Spin /></div>
                                    </Row>}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

        </Main>

    )
}

export default SpecificationGroupItem
