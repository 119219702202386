import React from 'react'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';


import { Button, Form, Input, Upload, Modal } from 'antd'

function EditAttribute({ hideEdit, dataEdit, showList }) {
    const [form] = Form.useForm();

    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [fileList, setfileList] = React.useState([])
    const [editData, seteditData] = React.useState({})
    const [confirmLoading, setConfirmLoading] = React.useState(false);


    React.useEffect(() => {
        console.log(dataEdit)
        seteditData(dataEdit)
    }, [dataEdit])


    const onFinish = (values) => {
        editAttribute(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const editAttribute = async (data) => {
        console.log(data);

        try {
            setConfirmLoading(true)
            const result = await axios.post(apiRoutes.updateAttribute, { title: data?.title, attributeId: dataEdit._id })
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
        } finally {
            setConfirmLoading(false)
            hideEdit()
        }
    }


    return (
        <Modal title="Edit Attribute" confirmLoading={confirmLoading} okText="Update" visible={dataEdit?.title} onOk={() => form.submit()} onCancel={() => { hideEdit() }}>
            <Form
                form={form}
                multiple={true}
                name="basic"
                colon={false}
                labelCol={{
                    span: 6,
                }}
                labelAlign="left"
                wrapperCol={{
                    span: 20,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    initialValue={dataEdit?.title}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>


            </Form>
        </Modal>
    );
}

export default EditAttribute