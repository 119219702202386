import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Space, Button, Input, Table, Modal, Card, Col, Row } from 'antd'
import Highlighter from 'react-highlight-words';
import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import EditAttributeItem from '../../component/pageComponents/attributeEditItem';
import CreateAttributeItem from '../../component/pageComponents/attributeItemCreate';
import Main from '../../component/layoutnew/Main'
const { confirm } = Modal;

function AttributeItems() {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [attributes, setattributes] = useState([])
    const [pageAction, setpageAction] = useState({ list: true, title: 'Attribute items' })
    const [isDataChanged, setisDataChanged] = useState(false)
    const [dataEdit, setdataEdit] = useState({})

    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getAllAttributes()
    }, [isDataChanged])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const showList = (isdone = false) => {
        setpageAction({ list: true, title: 'Attribute items' })
        if (isdone) {
            setisDataChanged(!isDataChanged)
        }
    }

    const showCreate = () => {
        setpageAction({ create: true, title: 'Create Attribute Items' })
    }
    const hideEdit = () => setdataEdit(false)

    const getAllAttributes = async () => {
        try {
            const response = await axios.get(apiRoutes.attributesItems)
            if (response?.data?.data) {
                setattributes(response.data.data)
                console.log(response.data.data)
            }
        } catch (error) {
            console.log(error)

        }finally{
            setisLoading(false)
        }
    }

    const deleteAttribute = async () => {
        try {
            setTimeout(() => { }, 200)
        } catch (error) {

        }
    }


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1,
            dataIndex: '_id',
            key: '_id'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // render: (text) => <span>{text}</span>,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.age - b.age,
        },
        {
            title: 'Attribute',
            dataIndex: 'attribute.title',
            key: 'attribute._id',
            render: (record, data) => data.attribute?.title
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (record, data) => (
                <Space size="middle" >
                    <Button onClick={() => setdataEdit(data)}>Edit </Button>
                    <Button onClick={() => showPromiseConfirm(record)}>Delete</Button>
                </Space>
            ),
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.trashAttributeItems, { attributeItemId: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setisDataChanged(!isDataChanged)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };

    return (

        <Main page="attributeitems">
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={pageAction?.title}
                            extra={<Button onClick={pageAction?.list ? showCreate : showList} size="small" type="primary">{pageAction?.list ? 'Create' : 'Back to list'}</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {pageAction.create && <div className="mt-25">
                                <CreateAttributeItem showList={showList} />
                                </div>}

                                {pageAction.list && <>
                                    {dataEdit?.title && <EditAttributeItem dataEdit={dataEdit} hideEdit={hideEdit} showList={showList} />}
                                    <Table columns={columns} dataSource={attributes} pagination={true} search={true} loading={isLoading} size="small" rowKey="_id" />
                                </>}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Main>

    )
}

export default AttributeItems