import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Space, Avatar, Button, Input, Table, Modal, Tag, Col, Row, Card,Typography } from 'antd'
import Highlighter from 'react-highlight-words';
import apiRoutes from '../../api/apiRoutes'
import CreateSubCategory from '../../component/pageComponents/subcategoryCreate';
import EditSubCategory from '../../component/pageComponents/subcategoryEdit'
import Main from '../../component/layoutnew/Main'
import axios from '../../api/axios';
const { confirm } = Modal;

function SubCategory() {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [subCategory, setsubCategory] = useState([])
    const [category, setcategory] = useState([])
    const [pageAction, setpageAction] = useState({ list: true, title: 'SubCategories' })
    const [isDataChanged, setisDataChanged] = useState(false)
    const [dataEdit, setdataEdit] = useState({})

    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getAllCategory()
    }, [isDataChanged])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const showList = (isdone = false) => {
        setpageAction({ list: true, title: 'All SubCategory' })
        console.log(isdone, 'is done')
        if (isdone) {
            setisDataChanged(!isDataChanged)
        }
    }

    const showCreate = () => {
        setpageAction({ create: true, title: 'Create SubCategory' })
    }
    const hideEdit = () => setdataEdit(false)

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllSubcategory)
            if (response?.data?.data?.subCategory) {
                setsubCategory(response.data?.data?.subCategory)
            }
            if (response?.data?.data?.category) {
                setcategory(response?.data?.data?.category)
            }
        } catch (error) {
            console.log(error)
        }finally{
            setisLoading(false)
        }
    }



    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'id',
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.title}
                </Typography.Text>,
            ...getColumnSearchProps('title')
        },

        {
            title: 'Image',
            dataIndex: 'logo',
            key: 'id',
            render: (_, record) => (<Avatar src={record.logo} />)
        },
        {
            title: 'Category',
            dataIndex: 'category?.title',
            key: 'id',
            render: (_, record) => record.category?.title
        },
        {
            title: 'CreatedBy',
            dataIndex: 'addedBy?.userName',
            key: 'id',
            render: (_, record) => record.addedBy?.userName
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Published
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                Unpublished
            </Tag>,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <Button onClick={() => { setdataEdit(record); console.log(dataEdit) }}>Edit </Button>
                    <Button onClick={() => showPromiseConfirm(record.id)}>Delete</Button>
                </Space>
            ),
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.deleteSubCategory, { id: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setisDataChanged(!isDataChanged)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };

    return (

        <Main page='subcategory'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={pageAction?.title}
                            extra={<Button onClick={pageAction?.list ? showCreate : showList} size="small" type="primary">{pageAction?.list ? 'Create' : 'Back to list'}</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {pageAction.create && <div className="mt-25">
                                    <CreateSubCategory showList={showList} category={category} />
                                </div>}

                                {pageAction.list && <>
                                    {dataEdit?.title && <EditSubCategory dataEdit={dataEdit} hideEdit={hideEdit} showList={showList} category={category} />}
                                    <Table columns={columns} dataSource={subCategory} pagination={true} search={true} loading={isLoading} size="small" />
                                </>}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

        </Main>

    )
}

export default SubCategory