import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

const { Footer } = Layout


const AppFooter = () => {
    return <StyledFooter>IPIX ECOM ©{new Date().getFullYear()}</StyledFooter>
}

const StyledFooter = styled(Footer)`
  text-align: center;
`

export default AppFooter
