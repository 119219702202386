import React from 'react'
import { Card, Row, Col, Form, Upload, Input, Button, Switch, Skeleton, message, Tag, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'

function ProductVeriation({ id, tax }) {

    const [veriants, setveriants] = React.useState([])
    const [isUpdating, setisUpdating] = React.useState({})
    const [isLoading, setisLoading] = React.useState(true)

    React.useEffect(() => {
        getVeriants()
    }, [])




    const updateProductVeriation = async (data, index) => {
        try {
            const response = await axios.post(apiRoutes.updatePeoductVeriation, data)
            if (response?.data?.success) {
                message.success('product veriation updated');
            } else {
                message.warning('product veriation failed');
            }
            console.log(response)
        } catch (error) {
            message.error(error.message);
            console.log(error)
        } finally {
            const temp = {}
            temp[`data${index}`] = false
            setisUpdating(temp)
        }
    }



    const getVeriants = async () => {
        try {
            const response = await axios.get(`${apiRoutes.getProductsByGroup}${id}`)
            console.log(response.data)
            if (response?.data?.data) {
                setveriants(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }

    }

    const onSuccess = (values, index) => {
        const temp = {}
        temp[`data${index}`] = true
        setisUpdating(temp)
        const formData = new FormData()
        formData.append("id", values.id)
        formData.append("sku", values.sku)
        formData.append("mrp", values.mrp)
        formData.append("price", values.price)
        formData.append("tax",values.tax)
        formData.append("stock", values.stock)
        formData.append('productrel', JSON.stringify({
            isDealoftheDay: values.isDealoftheDay,
            isSponsored: values.isSponsored,
        }))
        values?.files?.fileList.forEach(item => {
            if (item?.originFileObj) return formData.append('files', item.originFileObj)
            if (item?.url) return formData.append('images', item.url)
        })


        updateProductVeriation(formData, index)

    }


    return (
        <>
            {isLoading && [1, 2, 3, 4].map(item =>
                <Skeleton key={item} loading={true} active avatar size="small"></Skeleton>)}
            {veriants.map((item, index) =>
                <div key={index}>
                    <Form name="basic" onFinish={(value) => onSuccess(value, index)}>

                        <Card
                            className="header-solid mb-24"
                            size="small"
                            title={item.name}
                            style={{
                                width: '100%',
                            }}
                            extra={
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isUpdating[`data${index}`]}>Update</Button>
                                </Form.Item>
                            }
                        >
                            <Row>
                                <Col span={10}>
                                    <div style={{ paddingBottom: 5 }}>
                                        {item.veriation.map((veriatns, index) => <Tag key={index} color="magenta">{veriatns?.title}</Tag>)}
                                    </div>
                                    <Form.Item label="" name="files">
                                        <Upload listType="picture-card" action={"image/*"}
                                            defaultFileList={item.images}
                                            showUploadList={true}>
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={14}>




                                    <Form.Item
                                        hidden={true}
                                        name="id"
                                        initialValue={item._id}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Sku"
                                        name="sku"
                                        initialValue={item.sku}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Mrp"
                                        name="mrp"
                                        initialValue={item.mrp}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Price"
                                        name="price"
                                        initialValue={item.price}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Stock"
                                        name="stock"
                                        initialValue={item.stock}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Tax"
                                        name="tax"
                                        initialValue={item.tax}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Select>
                                            {tax.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                layout="veriticle"
                                                name="isDealoftheDay"
                                                label="Deal of the Day"
                                                labelAlign="right"
                                                initialValue={item?.productrel?.isDealoftheDay ? true : false}
                                            >
                                                <Switch size="small" defaultChecked={item?.productrel?.isDealoftheDay} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                layout="veriticle"
                                                name="isSponsored"
                                                label="Sponcered"
                                                labelAlign="right"
                                                initialValue={item?.productrel?.isSponsored ? true : false}
                                            >
                                                <Switch size="small" defaultChecked={item?.productrel?.isSponsored} />
                                            </Form.Item></Col>
                                    </Row>



                                </Col>
                            </Row>

                        </Card>
                    </Form>
                </div>)
            }

        </>
    )
}

export default React.memo(ProductVeriation)