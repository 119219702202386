


module.exports = {
    findAllBanner: "banner",
    findAllSellerBanner: "banner/seller",
    createPlan: "plan/create",
    updatePlan: "plan/update",
    trashPlan: "/plan/trash",
    plans: "plans",
    createBanner: "banner/create",
    deleteBanner: "banner/delete",
    updateBanner: "banner/update",
    approveBanner: "banner/approve",
    findAllCategory: "category",
    createCategory: "category/create",
    updateCategory: "category/update",
    deleteCategory: "category/delete",
    findAllSubcategory: "subcategory",
    getAllSubCategory: "subcategorys",
    createSubCategory: "subcategory/create",
    updateSubCategory: "subcategory/update",
    deleteSubCategory: "subcategory/delete",
    findAllProduct: "product",
    findAllPendingProduct: "pendingproducts",
    editProduct: "product/edit",
    updateProduct: "product/update",
    updateProductStatus: "product/update/status",
    findAllorders: "order",
    getOrderDetails: "orders",
    findAllUsers: "user",
    getAllUsers: "user/list",
    getOrderItemDetails: "order/items",
    updateOrderItemStatus: "order/changestatus",
    createProduct: "product/create",
    findAllAttributes: "attributes",
    createAttribute: "attribute/create",
    updateAttribute: "attribute/update",
    deleteAttribute: "attribute/delete",
    attributeItemList: "attribute/list",
    attributesItems: "attributeitems",
    createAttributeItems: "attributeItem/create",
    updateAttributeItems: "attributeItem/update",
    trashAttributeItems: "attributeItem/trash",
    attributeList: "attributes",
    listSpecificationAttributes: "specificationAttribute/list",
    createSpecificationAttribute: "specificationAttribute/create",
    updateSpecificationAttribute: "specificationAttribute/update",
    trashSpecificationAttribute: "specificationAttribute/trash",
    getProductsByGroup: "getproducts/",
    updatePeoductVeriation: "product/updateProductVeriation",
    updateProductImage: "product/update/image",
    getSpecifications: "specifications",
    createSpecification: "specifications/create",
    updateProductSpecification: "specification/update",
    getUserInfo: "userinfo",
    changeUserStatus: "user/status",
    getUserOrder: "user/orders",
    listSpecificationGroup: "specificationGroup/list",
    createSpecificationGroup: "specificationGroup/create",
    updateSpecificationGRoup: "specificationGroup/update",
    trashSpecificationGroup: "specificationGroup/delete",
    getSpecificationGroup: "specificationGroup",
    findAllPromocodes: "promocodes",
    createPromocode: "promocode/create",
    updatePromocode: "promocode/update",
    trashPromocode: "promocode/trash",
    sendNotification: "notification/sent",
    getAllReviews: "reviews",
    updateReviews: "review/update",
    dealoftheday: "dealoftheday",
    sponsored: "sponsored",
    getAllOffers: "offers",
    createOffer: "offer/create",
    orderItems: "orderitems",
    orderReturn: "order/return",
    allBrand: "brands",
    createBrand: "brand/create",
    updateBrand: "brand/update",
    deleteBrand: "brand/trash",
    getDashbordCount: "/",
    getDashbordOrder: "recentorder",
    getProduts: "products",
    updateOffers: "offer/update",
    trashOffer: "offer/trash",
    updateSettings: "/settings/update",
    getSettings: "settings",
    getSales: "sales",
    getPerfomaceChart: "perfomancechart",
    getAllVendor: "vendors",
    pendingVendors: "vendor/pending",
    createVendor: "vendor/create",
    getVendorInfo: "vendor/info",
    createTax: "tax/create",
    getTax: "taxs",
    updateTax: "tax/update",
    trashTax: "tax/trash",
    bankInfo: "vendor/bankinfo",
    sellerStatus: "vendor/status",
    allRevenue: "revenues",
    settlementRequest:"settlement/request",
    settlementRequestInfo:"settlement/requestinfo",
    settlementCreate:"settlement/create",
    settlements:"settlements",
    getInventoryCount:"inventory/count",
    getOutOfStock:"inventory/outofstock",
    getLowStock:"inventory/lowstock",
    updateStock:"inventory/updatestock",
    inventorySalesReport:"report/inventory/sales"
    

}


