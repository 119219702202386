import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Paragraph, Text } = Typography;



const Error = (props) => {
    const navigate = useNavigate()

    return (
        <Result
            status="error"
            title={props?.title || "Submission Failed"}
            subTitle="Please check and modify the following information before resubmitting."
            extra={[
                <Button type="primary" key="console" onClick={() => navigate('/')}>
                    Go Back
                </Button>,
                <Button key="buy" onClick={props.retry}>Try again</Button>,
            ]}
        >
            <div className="desc">
                <Paragraph>
                    <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        The content you submitted has the following error:
                    </Text>
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> {"You don't have permission to access this resource"} <a>Go to Home </a>
                </Paragraph>
                <Paragraph>
                    <CloseCircleOutlined className="site-result-demo-error-icon" /> Try again later
                </Paragraph>
            </div>
        </Result>
    )


}

export default Error;