

// import { useState } from "react";
import { Menu, Button } from "antd";
import { useNavigate } from 'react-router-dom'



import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  UnorderedListOutlined,
  InsertRowAboveOutlined,
  AppstoreOutlined,
  UserOutlined,
  CodeSandboxOutlined,
  ShoppingCartOutlined,
  GoldOutlined
} from '@ant-design/icons'



function Sidenav({ page }) {
  let navigate = useNavigate();

  const onClick = (event) => {
    const { key } = event
    navigate(`/${key}`, { replace: true })
  };



  const menuItems = [
    {
      key: '',
      icon: <DashboardOutlined />,
      label: 'Dashboard',
    },

    {
      type: 'group',
      key: 'general',
      label: 'General',
      children: [
        {
          key: 'Home',
          icon: <InsertRowAboveOutlined />,
          label: 'Home',
          children: [
            {
              key: 'deal-of-the-day',
              icon: <InsertRowAboveOutlined />,
              label: 'Deal of the Day',
            },
            {
              key: 'sponsored',
              icon: <InsertRowAboveOutlined />,
              label: 'Sponsored',
            }
          ]
        },
        {
          key: 'banner-menu',
          icon: <InsertRowAboveOutlined />,
          label: 'Banner',
          children: [
            {
              key: 'banner',
              icon: <InsertRowAboveOutlined />,
              label: 'Banner'
            },
            {
              key: 'banner/seller',
              icon: <InsertRowAboveOutlined />,
              label: 'Pending Banner'
            },


          ]
        },
        {
          key: 'category-menu',
          icon: <AppstoreOutlined />,
          label: 'Category',
          children: [
            {
              key: 'category',
              icon: <AppstoreOutlined />,
              label: 'Category'
            },
            {
              key: 'subcategory',
              icon: <UnorderedListOutlined />,
              label: 'Subcategory'
            },
          ]
        },
        {
          key: 'brand',
          icon: <AppstoreOutlined />,
          label: 'Brand'
        },
        {
          key: 'plans',
          icon: <AppstoreOutlined />,
          label: 'Plan'
        },

      ]
    },
    {
      type: 'divider',
    },
    {
      key: 'ecom',
      label: 'E-commerce',
      type: 'group',
      children: [
        {
          key: 'Attributes',
          icon: <AppstoreOutlined />,
          label: 'Attributes',
          children: [
            {
              key: 'attributes',
              icon: <GoldOutlined />,
              label: 'Attributes'
            },
            {
              key: 'attributeitems',
              icon: <GoldOutlined />,
              label: 'Attributeitems'
            },
            {
              key: 'specificationattributes',
              icon: <GoldOutlined />,
              label: 'Specification'
            },
            {
              key: 'specificationgroup',
              icon: <GoldOutlined />,
              label: 'Specification Group'
            },
          ]
        },
        {
          key: 'tax',
          icon: <UnorderedListOutlined />,
          label: 'Tax'
        },
        {
          key: 'products',
          icon: <CodeSandboxOutlined />,
          label: 'Products',
          children: [
            {
              key: 'product',
              icon: <GoldOutlined />,
              label: 'All Products'
            },
            {
              key: 'product/vendor',
              icon: <GoldOutlined />,
              label: 'Pending Products'
            }
          ]
        },
        {
          key: 'order-menu',
          icon: <ShoppingCartOutlined />,
          label: 'Order',
          children: [
            {
              key: 'order',
              icon: <ShoppingCartOutlined />,
              label: 'Order'
            },
            {
              key: 'orderitems',
              icon: <ShoppingCartOutlined />,
              label: 'Order Items'
            },
            {
              key: 'order/return',
              icon: <ShoppingCartOutlined />,
              label: 'Return Order'

            }
          ]
        },
        {
          key: 'off-promo',
          icon: <ShoppingCartOutlined />,
          label: 'Offers & Promotions',
          children: [
            {
              key: 'promocode',
              icon: <ShoppingCartOutlined />,
              label: 'Promocode'
            },
            {
              key: 'offers',
              icon: <ShoppingCartOutlined />,
              label: 'Offers'
            }
          ]
        },
        {
          key: 'inv',
          icon: <ShoppingCartOutlined />,
          label: 'Inventory',
          children: [
            {
              key: 'inventory',
              icon: <ShoppingCartOutlined />,
              label: 'Overview'
            },
            {
              key: 'inventory/lowstock',
              icon: <ShoppingCartOutlined />,
              label: 'Low stock'
            },
            {
              key: 'inventory/outofstock',
              icon: <ShoppingCartOutlined />,
              label: 'Out of stock'
            }
          ]
        },
        {
          key: 'revenues',
          icon: <ShoppingCartOutlined />,
          label: 'Revenus',
          children: [
            {
              key: 'settlement',
              icon: <ShoppingCartOutlined />,
              label: 'Settlement'
            },
            {
              key: 'settlement/request',
              icon: <ShoppingCartOutlined />,
              label: 'Serrlement request'
            },
            {
              key: 'seller/revenue',
              icon: <ShoppingCartOutlined />,
              label: 'Seller revenue'
            }
          ]
        },
        {
          key: 'review',
          icon: <ShoppingCartOutlined />,
          label: 'Review'
        }
      ]
    },
    {
      type: 'divider',
    },
    {
      key: 'account',
      label: 'Account Managment',
      type: 'group',
      children: [
        {
          key: 'user',
          icon: <UserOutlined />,
          label: 'User'
        },
        {
          key: 'vendor',
          icon: <UserOutlined />,
          label: 'Seller',
          children: [
            {
              key: 'seller',
              icon: <ShoppingCartOutlined />,
              label: 'Seller'
            },
            {
              key: 'seller/pending',
              icon: <ShoppingCartOutlined />,
              label: 'Seller request'
            }
          ]
        },
      ]
    },

  ]

  return (
    <>
      <div className="brand" style={{ padding: 10,display:"flex", justifyItems:"center",width:"100%",alignItems:"center" }}>
        <img src={'/img/colatree.png'} alt=""  />
        {/* <span>IPIX ECOMMERCE</span> */}
      </div>

      <Menu theme="light"
        onClick={onClick}
        style={{
          width: 256,
        }}
        defaultSelectedKeys={[page]}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={menuItems}
      />

    </>
  );
}

export default Sidenav;