import React, { useState, useEffect } from 'react'
import { EyeTwoTone,DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import Main from '../../component/layoutnew/Main'
import { Descriptions, Card, Col, Row, Table, Avatar, Skeleton, Button } from 'antd'
import { useParams } from "react-router-dom";
import apiRoutes from '../../api/apiRoutes'

import axios from '../../api/axios';



function OrderDetails() {
    const navigate = useNavigate()
    const [orderDetails, setorderDetails] = useState({})
    const [orderItems, setorderItems] = useState([])

    let { id } = useParams();

    useEffect(() => {
        console.log(apiRoutes.getOrderDetails)
        getOrderDetails()
    }, [])



    const getOrderDetails = async () => {
        try {
            const url = `${apiRoutes.getOrderDetails}/${id}`
            const response = await axios.get(url)
            if (response?.data?.data?.orderInfo) {
                setorderDetails(response.data.data.orderInfo)
                if (response?.data?.data?.orders) {
                    setorderItems(response?.data?.data?.orders)
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const navigateToProductDetail = (id) => {
        const url = `/orderitem/${id}`
        navigate(url)
    }


    // const total = () => {
    //     return (
    //         <Row>
    //             <Col span={19}>Total</Col>
    //             <Col span={4}>34444</Col>
    //         </Row>)
    // }


    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (_, text, index) => index + 1
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'id',
            render: (_, record) => <Avatar src={record.image} />
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'id',
            render: (_, record) => `₹ ${record.price}`
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'address',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Total',
            key: 'price',
            dataIndex: 'price',
            render: (_, record) => `₹ ${record.price * record.quantity}`
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Button type="text" className="box-shadow-none" onClick={() => navigateToProductDetail(record.id)} icon={<EyeTwoTone />} />
            ),
        }
    ];

    return (
        <Main page='product'>
            <div className="tabled">
                <Row gutter={16}>
                    <Col span={12}>
                        {orderDetails.createdAt ?
                            <Card title="Order Info" bordered={false} extra={orderDetails.invoice &&
                                <Button type='link' icon={<DownloadOutlined />}><a href={`${apiRoutes.apiUrl}${orderDetails.invoice.file}`} >Download Invoice</a> </Button>
                            }>
                                <Descriptions title="" column={2} labelStyle={{ fontWeight: 'bold' }}>
                                    <Descriptions.Item label="Order ID">{orderDetails.id}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions title="" column={2} labelStyle={{ fontWeight: 'bold' }}>
                                    <Descriptions.Item label="Order ON">{new Date(orderDetails.createdAt).toLocaleDateString("en-US")}</Descriptions.Item>
                                    <Descriptions.Item label="Customer Name">{orderDetails?.customerId?.name}</Descriptions.Item>
                                    <Descriptions.Item label="Order Status">{orderDetails?.status}</Descriptions.Item>
                                    <Descriptions.Item label="Payment Method">{orderDetails?.paymentMethod}</Descriptions.Item>
                                    <Descriptions.Item label="Payment Status">{orderDetails?.paymentStatus}</Descriptions.Item>
                                    <Descriptions.Item label="Bill Amount">{`₹ ${orderDetails?.totalAmount}`}</Descriptions.Item>
                                </Descriptions>
                            </Card> : <Skeleton active={true} />}
                    </Col>
                    <Col span={12}>
                        {orderDetails?.shippingAddress ? <Card title="Shipping Address" bordered={false}>
                            {/* <Descriptions title="" column={2} labelStyle={{ fontWeight: 'bold' }}>
                                            <Descriptions.Item label="Name">{orderDetails?.shippingAddress?.fullName}</Descriptions.Item>
                                        </Descriptions> */}
                            <Descriptions title="" column={2} labelStyle={{ fontWeight: 'bold' }}>

                                <Descriptions.Item label="Address line">{orderDetails?.shippingAddress?.address1}</Descriptions.Item>
                            </Descriptions>
                            <Descriptions title="" column={2} labelStyle={{ fontWeight: 'bold' }}>

                                <Descriptions.Item label="Lankmark">{orderDetails?.shippingAddress?.landmark}</Descriptions.Item>
                                <Descriptions.Item label="City">{orderDetails?.shippingAddress?.city}</Descriptions.Item>
                                <Descriptions.Item label="State">{orderDetails?.shippingAddress?.state}</Descriptions.Item>
                                <Descriptions.Item label="Country">{orderDetails?.shippingAddress?.country}</Descriptions.Item>
                                <Descriptions.Item label="Phone">{orderDetails?.shippingAddress?.mobile}</Descriptions.Item>
                                <Descriptions.Item label="Email">{orderDetails?.customerId?.email}</Descriptions.Item>
                            </Descriptions>
                        </Card> : <Skeleton active={true} />}
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Card title="Order Items" bordered={false}>
                            <Table columns={columns} dataSource={orderItems} pagination={false} size="small" loading={!orderItems?.length} />
                        </Card>
                    </Col>
                </Row>

            </div>
        </Main>
    )
}




export default OrderDetails