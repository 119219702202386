import React, { useRef, useState, useEffect } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteTwoTone, EditTwoTone, SearchOutlined, } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Avatar, Button, Table, Modal, Tag, Rate, Card, Col, Row, Typography, Input } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import CreateSubCategory from '../../component/pageComponents/subcategoryCreate';
import EditSubCategory from '../../component/pageComponents/subcategoryEdit'
import Main from '../../component/layoutnew/Main'
import { useNavigate } from 'react-router-dom'
import Highlighter from 'react-highlight-words';

import axios from '../../api/axios';
const { confirm } = Modal;

function Product() {
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [product, setproduct] = useState([])
    const [pageAction, setpageAction] = useState({ list: true, title: 'All Products' })
    const [isDataChanged, setisDataChanged] = useState(false)
    const [dataEdit, setdataEdit] = useState({})

    const [isLoading, setisLoading] = useState(true)
    const [category, setCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])


    useEffect(() => {
        getAllProduct()
    }, [isDataChanged])

    useEffect(() => {
        getAllCategory()
    }, [])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const showList = (isdone = false) => {
        setpageAction({ list: true, title: 'All Products' })
        if (isdone) {
            setisDataChanged(!isDataChanged)
        }
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>


                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (

                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Typography.Text>
            ) : (
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {text}
                </Typography.Text>
            ),
    });


    const hideEdit = () => setdataEdit(false)

    const getAllProduct = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllProduct)
            if (response?.data?.data) {
                setproduct(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllSubcategory)
            if (response?.data?.data?.subCategory) {
                setSubCategory(response.data?.data?.subCategory)
            }
            if (response?.data?.data?.category) {
                setCategory(response?.data?.data?.category)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }


    const navigateToProductCreate = () => {
        navigate('/product/create')

    }




    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1,
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.name}
                </Typography.Text>,
            ...getColumnSearchProps('name')
        },

        {
            title: 'Image',
            dataIndex: 'logo',
            key: 'logo',
            render: (_, record) => (<Avatar src={record.images[0]} />)
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Category',
            dataIndex: 'productGroup?.category?.title',
            key: 'productGroup?.category?.title',
            render: (_, record) => record.productGroup?.category?.title,
            filters: category.map(item => { return { text: item.title, value: item.id } }),
            onFilter: (value, record) => record.productGroup?.category?.id === value
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: (_, record) => <Rate disabled allowHalf defaultValue={parseFloat(record.rating)} />
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Active
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                InActive
            </Tag>,
            filters: [{ text: "Active", value: true }, { text: "In Active", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'Action',
            render: (id) => (
                <Space size="middle" >
                    <Button onClick={() => navigate(`/product/edit/${id}`)} icon={<EditTwoTone />}></Button>
                    <Button onClick={() => showPromiseConfirm(id)} icon={<DeleteTwoTone />} />
                </Space>
            ),
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            async onOk() {
                try {
                    return await new Promise((resolve, reject) => {
                        axios.post(apiRoutes.deleteSubCategory, { id: data })
                            .then(res => {
                                if (res?.data?.success) {
                                    setisDataChanged(!isDataChanged);
                                }
                                resolve(res);
                            })
                            .catch(err => reject(err));
                    });
                } catch {
                    return console.log('Oops errors!');
                }
            },
            onCancel() { },
        });
    };

    return (

        <Main page='product'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title={pageAction?.title}
                            extra={<Button onClick={pageAction?.list ? navigateToProductCreate : showList} size="small" type="primary">{pageAction?.list ? 'Create' : 'Back to list'}</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {pageAction.create && <div className="mt-25">
                                    <CreateSubCategory showList={showList} />
                                </div>}

                                {pageAction.list && <>
                                    {dataEdit?.title && <EditSubCategory dataEdit={dataEdit} hideEdit={hideEdit} showList={showList} />}
                                    <Table columns={columns} dataSource={product} pagination={true} search={true} loading={isLoading} size="small" rowKey="_id" />
                                </>}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Main>

    )
}

export default Product