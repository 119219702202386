import React from 'react'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';


import { Switch, Form, Input, Upload, Modal } from 'antd'

function EditCategory({ hideEdit, dataEdit, showList }) {
    const [form] = Form.useForm();

    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState('');
    const [previewTitle, setPreviewTitle] = React.useState('');
    const [fileList, setfileList] = React.useState([])
    const [editData, seteditData] = React.useState({})
    const [confirmLoading, setConfirmLoading] = React.useState(false);


    React.useEffect(() => {
        console.log(dataEdit)
        if (dataEdit.logo) {
            setfileList([{
                url: dataEdit.logo, status: 'done', uid: '-4',
                name: 'image.png',
            }])
        }
        seteditData(dataEdit)
    }, [dataEdit])


    const onFinish = (values) => {
        // console.log(values)
        // console.log(fileList)
        const formData = new FormData();
        formData.append('isActive', values.isActive)
        formData.append('title', values.title)
        formData.append('category', dataEdit.id)
        formData.append('file', values?.image?.file?.originFileObj || '')
        editCategory(formData)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const editCategory = async (data) => {
        try {
            setConfirmLoading(true)
            const result = await axiosInstance.post(apiRoutes.updateCategory, data)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
        } finally {
            setConfirmLoading(false)
            hideEdit()
        }
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewVisible(false);
    const handleChange = ({ fileList: newFileList }) => setfileList(newFileList);


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
            alt="example"
            style={{
                width: '100%',
            }}
            src={previewImage}
        />
    </Modal>

    return (
        <Modal title="Edit Catgory" confirmLoading={confirmLoading} okText="Update" visible={dataEdit?.title} onOk={() => form.submit()} onCancel={() => { hideEdit() }}>
            <Form
                form={form}
                multiple={true}
                name="basic"
                colon={false}
                labelCol={{
                    span: 6,
                }}
                labelAlign="left"
                wrapperCol={{
                    span: 20,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    initialValue={dataEdit?.title}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                

                <Form.Item
                    label="Image"
                    name="image"
                    initialValue={dataEdit?.logo}
                    
                >
                    <Upload maxCount={1} listType="picture-card" fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
                        {fileList.length >= 1 ? null : uploadButton}
                        {/* {<Button icon={<UploadOutlined />}>Select File</Button>} */}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Published"
                    name="isActive"
                    initialValue={dataEdit?.isActive}
                >
                    <Switch size="small" defaultChecked={dataEdit?.isActive} />
                </Form.Item>

            </Form>
        </Modal>
    );
}

export default EditCategory