import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Main from '../../component/layoutnew/Main'
import { Table, Col, Card, Row, Avatar, Typography } from 'antd'
import apiRoutes from '../../api/apiRoutes'
import { useNavigate } from "react-router-dom";
import axios from '../../api/axios';


function DealOfTheDay() {
    const navigate = useNavigate()
    const [order, setorder] = useState([])
    const [isDataChanged, setisDataChanged] = useState(false)


    useEffect(() => {
        getDeals()
    }, [])






    const getDeals = async () => {
        setisDataChanged(true)
        try {
            const response = await axios.get(apiRoutes.dealoftheday)
            if (response?.data?.data) {
                setorder(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisDataChanged(false)
        }
    }




    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Image',
            dataIndex: 'logo',
            key: 'logo',
            render: (_, record) => (<Link to={`/product/edit/${record.id}`}><Avatar src={record.images[0]} /></Link>)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (<Link to={`/product/edit/${record.id}`}><Typography.Text>{record.name}</Typography.Text></Link>)

        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price'
        }
    ];



    return (
        <Main page='deal-of-the-day'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={'Deal of the Day'}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table columns={columns} dataSource={order} pagination={true} search={true} loading={isDataChanged} size="small" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Main>
    )
}

export default DealOfTheDay