import React from 'react'
import { UploadOutlined } from '@ant-design/icons';
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';
import { Button, Form, Input, Select } from 'antd'


function CreateAttributeItem({ showList, dataEdit }) {
    const [attributes, setAttributes] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        getAttributes()
    }, [])



    const getAttributes = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllAttributes)
            if (response?.data) {
                setAttributes(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const onFinish = (values) => {
        createAttributeItem(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const createAttributeItem = async (data) => {

        try {
            setIsLoading(true)
            const result = await axios.post(apiRoutes.createAttributeItems, data)
            setIsLoading(false)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
            setIsLoading(false)
        }
    }

    return (
        <Form
            multiple={true}
            name="basic"
            colon={false}
            labelCol={{
                span: 6,
            }}
            labelAlign="left"
            wrapperCol={{
                span: 18,
            }}
            initialValues={{
                remember: true,
                attributeTitle: dataEdit?.attributeTitle || ''
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Title required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Attribute"
                name="attribute"
                rules={[
                    {
                        required: true,
                        message: 'Attribute is required!',
                    },
                ]}
            >
                <Select
                    placeholder="Select a option and change input text above"
                    onChange={() => { }}
                    allowClear
                >
                    {attributes.map(item => <Select.Option key={item._id} value={item._id}>{item.title}</Select.Option>)}
                </Select>
            </Form.Item>







            <Form.Item
                labelAlign="left"
                wrapperCol={{
                    offset: 22,

                }}
            >
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default CreateAttributeItem