import React, { useState, useEffect } from 'react'

import { TableOutlined, ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Space, Button, Table, Card, Col, Row, Typography, Badge } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import Main from '../../component/layoutnew/Main';
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { SETTLEMENT_STATUS } from '../../utils/constants/revenue';




function SettlementRequest() {
    const [plans, setplans] = useState([])
    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getRevenue()
    }, [])






    const getRevenue = async () => {
        try {
            setisLoading(true)
            const response = await axios.post(apiRoutes.settlementRequest)
            if (response?.data?.data) {
                setplans(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }



    const columns = [
        {
            title: '#',
            key: "createdAt",
            render: (_, record, index) => index + 1
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Seller',
            key: "seller",
            render: (_, record) => record?.seller?.name
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => <Typography.Text>₹ {record?.amount}</Typography.Text>
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (_, record) => {
                var date = new Date(record?.settlementDate)
                return date.toLocaleDateString("en-US")
            }
        },

        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => <Badge status={SETTLEMENT_STATUS[record?.status].status} text={SETTLEMENT_STATUS[record?.status].title} />,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => <Link to={`/settlement/request/${record.id}`}>View</Link>
        },
    ];




    return (
        <Main page='Revenue'>
            <motion.div className="tabled" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title="Settlement Request"
                            extra={<Space>
                                <Button onClick={getRevenue} icon={<ReloadOutlined />} size="middle" />
                                <Button icon={<TableOutlined />} size="middle" />
                                <Button icon={<DownloadOutlined />} size="middle" /></Space>}                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey="id" bordered={false} size='small' columns={columns} dataSource={plans} pagination={true} search={true} loading={isLoading} style={{ margin: 0 }} />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </Main>
    )
}

export default SettlementRequest