import React from 'react'
import { Form, Input, Button, Checkbox, Skeleton, notification } from 'antd'
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import Blank from '../layoutnew/empty'

function Specifications({ category, subCategory, productId }) {

    const [specifications, setspecifications] = React.useState([])
    const [productSpecification, setproductSpecification] = React.useState([])
    const [productSpecificationId, setproductSpecificationId] = React.useState({})
    const [isLoading, setisLoading] = React.useState(true)
    const [isUpdating, setisUpdating] = React.useState(false)


    React.useEffect(() => {
        getSpecifications()
    }, [])





    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            description: message
        });
    };


    const onUpdateSuccess = (value) => {
        const tempData = {};
        const newData = []
        Object.entries(value).forEach(([key, value]) => {
            if (value) return newData.push({ attribute: key, value: value })
        })
        tempData.specifications = newData
        tempData.product = productSpecificationId?.product
        tempData.specificationId = productSpecificationId?.id
        updateSpecification(tempData)

    }

    const updateSpecification = async (data) => {
        setisUpdating(true)
        try {
            const response = await axios.post(apiRoutes.updateProductSpecification, data)
            openNotificationWithIcon('success', 'success', response?.data?.message || 'Updated successfully')
        } catch (error) {
            openNotificationWithIcon('error', 'Error', 'Failed to Update')
            console.log(error)
        } finally {
            setisUpdating(false)
        }
    }


    const getSpecifications = async () => {
        try {
            const data = {}
            category && (data.category = category)
            subCategory && (data.subCategory = subCategory)
            productId && (data.product = productId)
            const response = await axios.post(apiRoutes.getSpecifications, data)
            if (response?.data?.data?.specifications) {
                if (response?.data?.data?.product) {
                    console.log('response')
                    setproductSpecificationId({ id: response?.data?.data?._id, product: response?.data?.data?.product })
                    return setproductSpecification(response.data?.data?.specifications)
                }
                setproductSpecification(response.data?.data?.specifications)
            }
            console.log(response.data.data)
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }

    if (!productSpecification.length) {
        return (
            <div>
                {isLoading ? <>{[1, 2, 3, 4].map(item => <Skeleton key={item} loading={true} active avatar size="small"></Skeleton>)}</> : <Blank />}
            </div>
        )
    }


    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}

            onFinish={onUpdateSuccess}
            onFinishFailed={() => { }}
            autoComplete="off"
        >
            {productSpecification.map(item =>

                <div key={item._id}>
                    {item.type === 'Boolean' ?
                        <Form.Item
                            label={item.title}
                            labelAlign="left"
                            name={item._id}
                            valuePropName="checked"
                            initialValue={item.value}
                        >
                            <Checkbox />
                        </Form.Item>
                        :
                        <Form.Item
                            label={item.title}
                            labelAlign="left"
                            name={item._id}
                            initialValue={item.value}
                        >
                            <Input />
                        </Form.Item>

                    }




                </div>
            )}


            {!!productSpecification.length && <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={isUpdating}>
                    Update
                </Button>
            </Form.Item>}

        </Form>
    )




}

export default React.memo(Specifications)