import React from 'react'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';
import moment from 'moment';
import ErrorNotification from '../main/notification'


import { notification, DatePicker, Form, Input, Upload, Modal, Switch } from 'antd'

function EditBanner({ data, close }) {
    const [form] = Form.useForm()

    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState('');
    const [previewTitle, setPreviewTitle] = React.useState('');
    const [fileList, setfileList] = React.useState([{
        url: data.image, status: 'done', uid: '-4',
        name: 'image.png',
    }])
    const [confirmLoading, setConfirmLoading] = React.useState(false);


    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('bannerTitle', values.bannerTitle)
        formData.append('alternateTitle', values.alternateTitle)
        formData.append('action', values.action)
        formData.append('startDate', values.startDate._d)
        formData.append('endDate', values.endDate._d)
        formData.append('file', values?.image?.file?.originFileObj || '')
        formData.append('bannerId', data?.id || data?._id)
        formData.append('isActive',values.isActive)
        updateBanner(formData)

    };

    const updateBanner = async (data) => {
        try {
            setConfirmLoading(true)
            const result = await axiosInstance.post(apiRoutes.updateBanner, data)
            if (result?.data?.success) {
                close()
            }
            notification['success']({
                message: 'Success',
                description: result.data?.message || 'Banner updated successfully',
            })
        } catch (error) {
            notification['error']({
                message: 'Failed',
                description: error.message || 'Banner updated successfully',
            })
            // console.log('Failed:', error)

        } finally {
            setConfirmLoading(false)
        }
    }


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = ({ fileList: newFileList }) => setfileList(newFileList);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };



    <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img
            alt="example"
            style={{
                width: '100%',
            }}
            src={previewImage}
        />
    </Modal>

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );



    return (
        <Modal
            title="Title"
            visible={true}
            onOk={() => form.submit()}
            okText="Update"
            confirmLoading={confirmLoading}
            onCancel={close}
            centered={false}
        >

            <Form
                form={form}
                multiple={true}
                name="basic"
                colon={false}
                labelCol={{
                    span: 6,
                }}
                labelAlign="left"
                wrapperCol={{
                    span: 18,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    initialValue={data?.bannerTitle}
                    label="Title"
                    name="bannerTitle"
                    rules={[
                        {
                            required: true,
                            message: 'Title required!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    initialValue={data?.alternateTitle}
                    label="Sub Title"
                    name="alternateTitle"
                    rules={[
                        {
                            required: true,
                            message: 'Subtitle required!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Start Date" name="startDate" initialValue={moment(data?.startDate)}
                    rules={[
                        {
                            required: true,
                            message: 'Start Date required!'
                        },
                    ]}>
                    <DatePicker style={{
                        width: '100%',
                    }} />
                </Form.Item>

                <Form.Item label="End Date" name="endDate" initialValue={moment(data?.endDate)}
                    rules={[
                        {
                            required: true,
                            message: 'End Date required!'
                        },
                    ]}>
                    <DatePicker style={{
                        width: '100%',
                    }} />
                </Form.Item>

                <Form.Item
                    label="Action"
                    name="action"
                    initialValue={data?.action}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Image"
                    name="image"
                    initialValue={data?.image}

                >
                    <Upload maxCount={1} listType="picture-card" fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
                        {fileList.length >= 1 ? null : uploadButton}
                        {/* {<Button icon={<UploadOutlined />}>Select File</Button>} */}
                    </Upload>
                    
                </Form.Item>

                <Form.Item
                    label="Publish"
                    name="isActive"
                    initialValue={data?.isActive}
                >
                    <Switch size="small" defaultChecked={data?.isActive} />
                </Form.Item>

            </Form>
        </Modal>
    );
}

export default EditBanner