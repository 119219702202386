import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Row, Col, Typography, notification } from 'antd';
import '../../assets/css/login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const { Title } = Typography;
function Login() {
    const navigate = useNavigate();
    const [isLogin, setisLogin] = React.useState(false)

    const onFinish = (values) => {
        setisLogin(true)
        login(values)
        console.log('Received values of form: ', values);
    };

    const login = async (auth) => {
        try {
            const response = await axios.post('/api/auth/v2/dashbordlogin', {
                username: auth.username,
                password: auth.password
            })
            console.log(response?.data?.data?.data.token)
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: 'Login Successfully'
                })
                localStorage.setItem('token', response?.data?.data?.data?.token ?? '')
                localStorage.setItem('userName',response?.data?.data?.data?.user?.userName ?? '')
                navigate('/', { replace: true })

            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'Login Failed'
                })
            }

        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message || 'Login Failed'
            })

        } finally {
            setisLogin(false)
        }
    }

    return (

        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Col span={6} sm={12} lg={6} xl={6} md={12} xs={20}>
                <Title level={2} style={{ textAlign: 'left', marginBottom: '30px' }}>Login</Title>
                <Form
                    name="normal_login"
                    size="small"
                    
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" size="small" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            size="small"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={12} style={{ textAlign: "start" }}>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ textAlign: "end" }}>
                                <span className="login-form-forgot">
                                    Forgot password
                                </span>
                            </Col>
                        </Row>
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit"  block size="large" loading={isLogin} >
                            Log in
                        </Button>

                    </Form.Item>
                </Form>

            </Col>
        </Row>

    );
}

export default Login