import { useState, useEffect } from 'react'
import { Card, Form, Skeleton, Button, Input,message } from 'antd'
import constant from '../../utils/constant'
import axios from '../../api/axios'
import apiUrl from '../../api/apiRoutes'


function AppVersion() {

    const [isLoading, setisLoading] = useState(true)
    const [data, setData] = useState({})
    const [isUpdating, setisUpdating] = useState(false)

    useEffect(() => {
        getAppVersion()
    }, [])




    const getAppVersion = async () => {
        try {
            const response = await axios.post(apiUrl.getSettings, { slug: constant.slug.appVersion })
            if (response?.data?.data) {
                setData(response?.data?.data)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setisLoading(false)
        }
    }




    const updateAppVersion = async (values) => {
        try {
            setisUpdating(true)
            const response = await axios.post(apiUrl.updateSettings, { appVersion: values, slug: constant.slug.appVersion })
            if(response?.data?.status){
                message.success(response?.data?.message ||'Updated');
            }
        } catch (error) {
            console.log(error)
        }finally{
            setisUpdating(false)
        }
    }




    return (
        <Card title="Application Version" bordered={false} className="header-solid mb-24" >
            {isLoading ?
                <Skeleton loading={isLoading} active={true} />
                :
                <div>
                    <Form
                        labelAlign="left"
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={updateAppVersion}
                        onFinishFailed={() => { }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Current Version"
                            name="latest"
                            initialValue={data?.appVersion?.latest}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input latest version of app!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Depricated Version"
                            name="expired"
                            initialValue={data?.appVersion?.expired}
                        >
                            <Input />
                        </Form.Item>



                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={isUpdating}>
                                Update
                            </Button>
                        </Form.Item>
                    </Form>

                </div>

            }
        </Card>
    )
}

export default AppVersion