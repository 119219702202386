
import { memo } from 'react'
import { Col, Input, Form, Button } from 'antd'


function AccountForm({ next, data }) {
    return (


        <Col span={24} className="mt-25">
            <Form
                labelAlign="left"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={data}
                onFinish={(data) => next(data)}
                autoComplete="off"
            >


                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Name required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {/* ant-col ant-col-24 ant-col-offset-22 ant-form-item-control */}

                <Form.Item
                    label="User Name"
                    name="userName"
                    rules={[
                        {
                            required: true,
                            message: 'User Name required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Email required',
                        },
                        {
                            type: 'email',
                            message: "Email id is invalid"
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'password required',
                        }
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <div style={{ marginTop: 35, marginBottom: 35 }}>
                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        Next
                    </Button>
                </div>
            </Form>
        </Col>





    )
}

export default memo(AccountForm)