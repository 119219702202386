import React from 'react'
import { Col, Row, Space, Button, Upload, Drawer, Form, Input, Select, DatePicker, Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons';


import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import moment from 'moment'


function OffersEdit({ isEdit, setIsCreate, close }) {
    const [selectedCondition, setsSelectedCondition] = React.useState(Object.keys({ ...isEdit?.condition }))
    const [actionType, setActionType] = React.useState(isEdit?.action?.actionType || "")
    const [category, setCategory] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [form] = Form.useForm();


    const [fileList, setfileList] = React.useState([{
        url: isEdit.images, status: 'done', uid: '-4',
        name: 'image.png',
    }])
    const [offerTo, setofferTo] = React.useState(isEdit.product.length ? 'product' : isEdit.category.length ? 'category' : 'subcategory')
    const [products, setProducts] = React.useState([])
    const [subCategory, setSubCategory] = React.useState([])


    React.useEffect(() => {
        getAllCategory()
        getAllSubCategory()
        getAllProducts()
    }, [])


    console.log(isEdit)

    const onFinish = (value) => {
        console.log(value)

        const formData = new FormData();
        formData.append('offerId', isEdit.id || isEdit._id)
        formData.append('title', value.title)
        formData.append('startDate', value.duration[0]._d)
        formData.append('endDate', value.duration[1]._d)
        formData.append('termsAndConditions', value.tc)
        formData.append('message', value.message)
        formData.append('isNotification', value.isNotification)
        formData.append('type', value.type)
        value?.category && (formData.append('category', value.category))
        value?.subCategory && (formData.append('subCategory', value.subCategory))
        value?.product && (formData.append('product', value.product))
        value?.files?.file?.originFileObj && (formData.append('file', value.files.file.originFileObj))
        updateOffer(formData)
    }


    const updateOffer = async (data) => {
        setIsLoading(true)
        try {
            const response = await axios.post(apiRoutes.updateOffers, data)
            if (response?.data?.success) {
                setIsLoading(false)
                close()
            }
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }



    const getAllSubCategory = async () => {
        try {
            const response = await axios.post(apiRoutes.getAllSubCategory)
            if (response?.data?.data) {
                setSubCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllProducts = async () => {
        try {
            const response = await axios.get(apiRoutes.getProduts)
            if (response?.data?.data) {
                setProducts(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    return (
        <Drawer
            title="Edit Offer"
            width='50%'
            onClose={close}
            visible={Object.keys(isEdit).length}
            bodyStyle={{
                paddingBottom: 40,
            }}
            extra={
                <Space>
                    <Button onClick={close}>Cancel</Button>
                    <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="title"
                            label="Title"
                            initialValue={isEdit?.title}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Title" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="duration"
                            label="Duration"
                            initialValue={[moment(isEdit?.startDate), moment(isEdit?.endDate)]}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please start date and end date',
                                },
                            ]}
                        >
                            <DatePicker.RangePicker
                                style={{
                                    width: '100%',
                                }}
                                getPopupContainer={(trigger) => trigger.parentElement}
                            />
                        </Form.Item>
                    </Col>

                </Row>



                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="message"
                            label="Message"
                            initialValue={isEdit?.message}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Message" />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="tc"
                            label="Terms & Conditions"
                            initialValue={isEdit?.termsAndConditions.toString().replaceAll(",","\n")}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Input.TextArea rows={2} placeholder="Please Enter Terms & Conditions Separate by Comma" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="offerto"
                            label="Offer Belong"
                            initialValue={offerTo}
                        >
                            <Select
                                onChange={(e) => setofferTo(e)}
                                options={[
                                    {
                                        value: 'Common',
                                        label: 'Common'
                                    },
                                    {
                                        value: 'product',
                                        label: 'Product',
                                    },
                                    {
                                        value: 'category',
                                        label: 'Category',
                                    },
                                    {
                                        value: 'subcategory',
                                        label: 'Sub Category',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        {offerTo === 'product' && <Form.Item
                            name="product"
                            initialValue={isEdit?.product}
                            label="product"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" mode="multiple" defaultValue={isEdit?.product}>
                                {products.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                            </Select>
                        </Form.Item>}

                        {offerTo === 'category' && <Form.Item
                            name="category"
                            label="Category"
                            initialValue={isEdit?.category}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" mode="multiple">
                                {category.map(item => <Select.Option value={item.id}>{item.title}</Select.Option>)}
                            </Select>
                        </Form.Item>}

                        {offerTo === 'subcategory' && <Form.Item
                            name="subCategory"
                            label="Sub Category"
                            initialValue={isEdit?.subCategory}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" mode="multiple">
                                {subCategory.map(item => <Select.Option value={item.id}>{item.title}</Select.Option>)}
                            </Select>
                        </Form.Item>}

                    </Col>
                </Row>

                <Row gutter={6}>
                    <Col span={5}>
                        <Form.Item label="Images" name="files" rules={[{ required: true, message: 'This Field is required' }]} initialValue={isEdit?.images}>

                            <Upload maxCount="1" listType="picture-card" action={"image/*"}
                                defaultFileList={fileList}
                                onChange={({ fileList: newFileList }) => setfileList(newFileList)}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="type"
                            label="Offer Type"
                            initialValue={isEdit?.type}
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        value: 'Bank Offer',
                                        label: 'Bank Offer',
                                    },
                                    {
                                        value: 'Partner Offer',
                                        label: 'Partner Offer',
                                    },
                                    {
                                        value: 'Shipping Offer',
                                        label: 'Shipping Offer',
                                    },
                                    {
                                        value: 'Compo Offer',
                                        label: 'Compo Offer',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>

                        <Col span={24}>
                            <Form.Item
                                name="isNotification"
                                label="Send Notification"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>


                    </Col>
                </Row>

                <Form.Item
                    name="isActive"
                    label="Publish"
                >
                    <Switch size="small" />
                </Form.Item>


            </Form>
        </Drawer>
    )
}

export default React.memo(OffersEdit)