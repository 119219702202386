import React from 'react'
import { UploadOutlined } from '@ant-design/icons';
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';
import { Button, Form, Input, Select } from 'antd'


function CreateSpecificationAttribute({ showList, dataEdit }) {
    const [isLoading, setIsLoading] = React.useState(false)


    const onFinish = (values) => {
        createAttributeItem(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const createAttributeItem = async (data) => {

        try {
            setIsLoading(true)
            const result = await axios.post(apiRoutes.createSpecificationAttribute, data)
            setIsLoading(false)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
            setIsLoading(false)
        }
    }

    return (
        <Form
            multiple={true}
            name="basic"
            colon={false}
            labelCol={{
                span: 6,
            }}
            labelAlign="left"
            wrapperCol={{
                span: 18,
            }}
            initialValues={{
                remember: true,
                attributeTitle: dataEdit?.attributeTitle || ''
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Title"
                name="title"

                rules={[
                    {
                        required: true,
                        message: 'Title required!',
                    },
                ]}
            >
                <Input placeholder="Enter Attribute title" />
            </Form.Item>

            <Form.Item
                label="Type"
                name="type"
                rules={[
                    {
                        required: true,
                        message: 'Attribute is required!',
                    }
                ]}
            >
                <Select
                    placeholder="Choose Attribute type"
                    onChange={() => { }}
                    allowClear
                >
                    <Select.Option key={1} value='String'>String</Select.Option>
                    <Select.Option key={2} value='Boolean'>Boolean</Select.Option>
                    <Select.Option key={3} value='Number'>Number</Select.Option>

                </Select>
            </Form.Item>







            <Form.Item
                labelAlign="left"
                wrapperCol={{
                    offset: 22,

                }}
            >
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default CreateSpecificationAttribute