import axios from "axios";
import { api } from "../utils/constant";


const axiosInstance = axios.create({
    baseURL: api.url + api.path,
})

axiosInstance.interceptors.request.use((config)=>{

    const isLoggedIn = localStorage.getItem('token')
    
    if (isLoggedIn) {
        config.headers.common.Authorization = `Bearer ${isLoggedIn}`;
    }
    return config;
})



export default axiosInstance