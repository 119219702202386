
import Chart from 'react-apexcharts'
import { Typography, Skeleton } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import { useState, useEffect } from 'react'
import axios from "../../api/axios"
import apiRoutes from "../../api/apiRoutes"

function LineChart() {
  const { Title, Paragraph } = Typography;

  const [user, setUser] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [order, setOrder] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    getPerfomanceData()
  }, [])




  const getPerfomanceData = async () => {

    try {
      const response = await axios.post(apiRoutes.getPerfomaceChart)

      response.data?.data?.order && (setOrder(response.data.data.order))
      response.data?.data?.user && (setUser(response.data.data.user))

    } catch (error) {
      console.log(error)

    } finally {
      setisLoading(false)
    }

  }





  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Orders</Title>
          <Paragraph className="lastweek">
            than last week <span className="bnb2">+30%</span>
          </Paragraph>
        </div>
        <div className="sales">
          <ul>
            <li>{<MinusOutlined />} Users</li>
            <li>{<MinusOutlined />} Orders</li>
          </ul>
        </div>
      </div>

      {isLoading ? <>
        <Skeleton loading={true} active /> <Skeleton loading={true} active />  </> :


        <Chart
          className="full-width"
          options={lineChart.options}
          series={[
            {
              name: "Users",
              data: user,
              offsetY: 0,
            },
            {
              name: "Orders",
              data: order,
              offsetY: 0,
            }
          ]}
          type="area"
          height={350}
          width={"100%"}
        />}
    </>
  );
}

export default LineChart;
