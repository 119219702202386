import React from 'react'
import styled from 'styled-components'

import { PoweroffOutlined, UserOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons'
import { Menu, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'



const StyledMenuLabel = styled('span')`
  margin-left: 15px;
`

const StyledArrowTop = styled('span')`
  content: '.';
  width: 10px;
  height: 10px;
  display: block;
  background: #fff;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 70%;
  border: 1px solid #eee;
  border-bottom-width: 0;
  border-right-width: 0;
  transform: rotate(45deg);
  margin-bottom: -4px;
`

const logoutMenuStyle = {
    background: 'rgb(24 144 255)',
    color: '#fff',
    margin: 0,
    padding: 0,
}

const menuContainerStyle = {
    position: 'relative',
    display: 'block',
    height: '100%',
    boxShadow: '1px 6px 5px #ccc',
    color: '#7a8994',
}

const menuFirstItemStyle = {
    marginTop: '0',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
}

const StyledLogoutButton = styled(StyledMenuLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
  &:hover {
    color: #fff;
  }
`


const ProfileMenu = () => {
    const navigate = useNavigate()
    const username = localStorage.getItem('userName')


    return (
        <div style={menuContainerStyle}>
            <StyledArrowTop />
            <Menu style={{ paddingBottom: 0, marginBottom: 0, position: 'relative' }}>
                <Menu.Item
                    key="menu.profile.about"
                    onClick={() => {

                    }}
                    style={menuFirstItemStyle}
                >
                    <StyledMenuLabel>
                        <UserOutlined />
                        <MenuLabelItem>{username}</MenuLabelItem>
                    </StyledMenuLabel>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    key="menu.profile.changePassword"
                    onClick={() => {
                        console.log('logout')
                    }}
                >
                    <StyledMenuLabel>
                        <LockOutlined />
                        <MenuLabelItem>{'Change Password'} </MenuLabelItem>
                    </StyledMenuLabel>
                </Menu.Item>
                <Menu.Item key="menu.profile.logout" onClick={() => {
                    localStorage.clear();
                    navigate('/login');

                }} style={logoutMenuStyle}>
                    <StyledLogoutButton>
                        LogOut
                        {'  '}
                        <PoweroffOutlined style={{ marginLeft: 10 }} />
                    </StyledLogoutButton>
                </Menu.Item>
            </Menu>
        </div>
    )
}

const MenuLabelItem = styled.span`
  margin-left: 10px;
`

export default ProfileMenu
