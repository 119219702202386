import React from 'react'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';


import { Button, Form, Input, Select, Modal } from 'antd'

function EditSpecificationAttributes({ hideEdit, dataEdit, showList }) {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = React.useState(false);



    const types = [
        {
            id: 1,
            title: 'String',
            value: 'String'
        },
        {
            id: 2,
            title: 'Number',
            value: 'Number'
        },
        {
            id: 3,
            title: 'Boolean',
            value: 'Boolean'
        }
    ]



    const onFinish = (values) => {
        console.log(values)
        editAttributeItem(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const editAttributeItem = async (data) => {
        console.log(data)
        try {
            setConfirmLoading(true)
            data.specificationId = dataEdit._id
            const result = await axios.post(apiRoutes.updateSpecificationAttribute, data)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
        } finally {
            setConfirmLoading(false)
            hideEdit()
        }
    }


    return (
        <Modal title="Edit Specification" confirmLoading={confirmLoading} okText="Update" visible={dataEdit?.title} onOk={() => form.submit()} onCancel={() => { hideEdit() }}>
            <Form
                form={form}
                multiple={true}
                name="basic"
                colon={false}
                labelCol={{
                    span: 6,
                }}
                labelAlign="left"
                wrapperCol={{
                    span: 20,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    initialValue={dataEdit?.title}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Type"
                    name="type"
                    initialValue={dataEdit?.type}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Select
                        placeholder="Choose Type"
                        allowClear
                    >
                        {types.map(item => <Select.Option key={item.id} value={item.value}>{item.title}</Select.Option>)}
                    </Select>
                </Form.Item>


            </Form>
        </Modal>
    );
}

export default EditSpecificationAttributes