import { Row, Col, Typography, Skeleton } from "antd";
import { useState, useEffect } from "react";
import eChart from "./configs/eChart";
import Chart from 'react-apexcharts'
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'

function EChart() {
  const { Title, Paragraph } = Typography;

  const [sales, setSales] = useState([450, 200, 100, 220, 500, 100, 400, 230, 500])
  const [isLoading, setisLoading] = useState(true)


  useEffect(() => {
    getSales()
  }, [])


  const items = [
    {
      Title: "3,6K",
      user: "Users",
    },
    {
      Title: "2m",
      user: "Clicks",
    },
    {
      Title: "$772",
      user: "Sales",
    },
    {
      Title: "82",
      user: "Items",
    },
  ];




  const getSales = async () => {
    try {
      const response = await axios.post(apiRoutes.getSales)
      response.data?.data && (setSales(response.data.data))
    } catch (error) {
      console.log(error)

    } finally {
      setisLoading(false)
    }
  }


  return (
    <>
      {isLoading ?

        <Skeleton loading={true} active />
        : <div id="chart">
          <Chart
            className="bar-chart"
            options={eChart.options}
            series={[{
              name: "Sales",
              data: sales,
              color: "#fff",
            }]}
            type="bar"
            height={220}
          />
        </div>}

      <div className="chart-vistior">
        <Title level={5}>Sales</Title>
        <Paragraph className="lastweek">
          than last week <span className="bnb2">+30%</span>
        </Paragraph>
        <Paragraph className="lastweek">
          We have created multiple options for you to put together and customise
          into pixel perfect pages.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;
