import React from 'react'
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';


import { Button, Form, Input, Upload } from 'antd'

function CreateBrand({ showList, dataEdit }) {


    const [isLoading, setIsLoading] = React.useState(false)
    

    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('title', values.title)
        formData.append('file', values.image.file.originFileObj)
        createBrand(formData)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const createBrand = async (data) => {
        setIsLoading(true)
        try {
            const result = await axiosInstance.post(apiRoutes.createBrand, data)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
        }finally{
            setIsLoading(false)
        }
    }

    return (
        <Form
            multiple={true}
            name="basic"
            colon={false}
            labelCol={{
                span: 6,
            }}
            labelAlign="left"
            wrapperCol={{
                span: 18,
            }}
            initialValues={{
                remember: true,
                bannerTitle: dataEdit?.bannerTitle || ''
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{marginTop:20}}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Title required!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Image"
                name="image"
                rules={[
                    {
                        required: true,
                        message: 'Image required!'
                    },
                ]}
            >
                <Upload maxCount={1}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            </Form.Item>




            <Form.Item
                labelAlign="left"
                wrapperCol={{
                    offset: 22,

                }}
            >
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default CreateBrand