import React from 'react'
import { Col, Row, Space, Button, Switch, Drawer, Form, Input, Select, DatePicker, Upload } from 'antd'

import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { PlusOutlined } from '@ant-design/icons';

function OffersCreate({ isCreate, close }) {
    const [category, setCategory] = React.useState([])
    const [subCategory, setSubCategory] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [form] = Form.useForm();

    const [fileList, setfileList] = React.useState([])
    const [offerTo, setofferTo] = React.useState('')
    const [products, setProducts] = React.useState([])



    React.useEffect(() => {
        getAllCategory()
        getAllSubCategory()
        getAllProducts()
    }, [])

    const onFinish = (value) => {
        const formData = new FormData();
        formData.append('title', value.title)
        formData.append('startDate', value.duration[0]._d)
        formData.append('endDate', value.duration[1]._d)
        const filteredTc = value.tc.replace(/\n/g, '')
        formData.append('termsAndConditions', filteredTc.split(","))
        formData.append('message', value.message)
        formData.append('isNotification', value.isNotification)
        formData.append('type', value.type)
        value?.category && (formData.append('category', value.category))
        value?.subCategory && (formData.append('subCategory', value.subCategory))
        value?.product && (formData.append('product', value.product))

        value?.files?.file?.originFileObj && (formData.append('file', value.files.file.originFileObj))
        // console.log(value)
        createOffer(formData)
    }


    const createOffer = async (data) => {
        setIsLoading(true)
        try {
            const response = await axios.post(apiRoutes.createOffer, data)
            if (response?.data?.success) {
                close()
            }
            setIsLoading(false)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllSubCategory = async () => {
        try {
            const response = await axios.post(apiRoutes.getAllSubCategory)
            if (response?.data?.data) {
                setSubCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllProducts = async () => {
        try {
            const response = await axios.get(apiRoutes.getProduts)
            if (response?.data?.data) {
                setProducts(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    return (
        <Drawer
            title="Create New Offers"
            width='50%'
            onClose={close}
            options={isCreate}
            bodyStyle={{
                paddingBottom: 40,
            }}
            extra={
                <Space>
                    <Button onClick={close}>Cancel</Button>
                    <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Title" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="duration"
                            label="Duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please start date and end date',
                                },
                            ]}
                        >
                            <DatePicker.RangePicker
                                style={{
                                    width: '100%',
                                }}
                                getPopupContainer={(trigger) => trigger.parentElement}
                            />
                        </Form.Item>
                    </Col>

                </Row>



                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="message"
                            label="Message"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Message" />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="tc"
                            label="Terms & Conditions"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Input.TextArea rows={2} placeholder="Please Enter Terms & Conditions Separate by Comma" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="offerto"
                            label="Offer Belong"
                        >
                            <Select
                                onChange={(e) => setofferTo(e)}
                                options={[
                                    {
                                        value: 'Common',
                                        label: 'Common'
                                    },
                                    {
                                        value: 'product',
                                        label: 'Product',
                                    },
                                    {
                                        value: 'category',
                                        label: 'Category',
                                    },
                                    {
                                        value: 'subcategory',
                                        label: 'Sub Category',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        {offerTo === 'product' && <Form.Item
                            name="product"
                            label="product"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" mode="multiple">
                                {products.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                            </Select>
                        </Form.Item>}

                        {offerTo === 'category' && <Form.Item
                            name="category"
                            label="Category"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" mode="multiple">
                                {category.map(item => <Select.Option value={item.id}>{item.title}</Select.Option>)}
                            </Select>
                        </Form.Item>}

                        {offerTo === 'subcategory' && <Form.Item
                            name="subCategory"
                            label="Sub Category"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" mode="multiple">
                                {subCategory.map(item => <Select.Option value={item.id}>{item.title}</Select.Option>)}
                            </Select>
                        </Form.Item>}

                    </Col>
                </Row>

                <Row gutter={6}>
                    <Col span={5}>
                        <Form.Item label="Images" name="files" rules={[{ required: true, message: 'This Field is required' }]}>
                            <Upload maxCount="1" listType="picture-card" action={"image/*"}
                                defaultFileList={fileList}
                                onChange={({ fileList: newFileList }) => setfileList(newFileList)}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="type"
                            label="Offer Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        value: 'Bank Offer',
                                        label: 'Bank Offer',
                                    },
                                    {
                                        value: 'Partner Offer',
                                        label: 'Partner Offer',
                                    },
                                    {
                                        value: 'Shipping Offer',
                                        label: 'Shipping Offer',
                                    },
                                    {
                                        value: 'Compo Offer',
                                        label: 'Compo Offer',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>

                        <Col span={24}>
                            <Form.Item
                                name="isNotification"
                                label="Send Notification"

                            >
                                <Switch />
                            </Form.Item>
                        </Col>


                    </Col>
                </Row>



            </Form>
        </Drawer>
    )
}

export default React.memo(OffersCreate)