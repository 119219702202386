import { useState, useEffect } from 'react'
import { Row, Col, Card, Typography } from 'antd'
import { CheckCircleOutlined, StopOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons'

import axios from '../../../api/axios'
import apiRoutes from '../../../api/apiRoutes'

function Count() {

    const [isLoading, setisLoading] = useState(true)
    const [countData, setcountData] = useState({})


    useEffect(() => {
        getCounts()
    }, [])


    const getCounts = async () => {
        try {
            const response = await axios.post(apiRoutes.getInventoryCount)
            const { data } = response?.data
            data && setcountData(data)
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }


    return (
        <div>
            <Row gutter={[24, 0]}>
                <Col span={6}>
                    <Card bordered={false} className="criclebox" loading={isLoading}>
                        <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                    <span>Inventory value</span>
                                    <Typography.Title level={4}>
                                    ₹ {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(countData?.totalValue || 0)}
                                    </Typography.Title>
                                </Col>
                                <Col xs={6}>
                                    <div className="icon-box"><InfoCircleOutlined /></div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false} className="criclebox" loading={isLoading}>
                        <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                    <span>Total Inventory</span>
                                    <Typography.Title level={3}>{countData?.total?.value || 0}</Typography.Title>
                                </Col>
                                <Col xs={6}>
                                    <div className="icon-box" style={{ background: "rgb(0, 227, 150)" }}><CheckCircleOutlined /></div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false} className="criclebox" loading={isLoading} >
                        <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                    <span>Out of stock</span>
                                    <Typography.Title level={3}>{countData?.outOfStock?.value || 0}</Typography.Title>
                                </Col>
                                <Col xs={6}>
                                    <div className="icon-box" style={{ background: "#ff4d4f" }}><StopOutlined /></div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false} className="criclebox" loading={isLoading} >
                        <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                    <span>Low stock</span>
                                    <Typography.Title level={3}>{countData?.lowStock?.value || 0}</Typography.Title>
                                </Col>
                                <Col xs={6}>
                                    <div className="icon-box" style={{ background: "#faad14" }}><WarningOutlined /></div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default Count