import { useEffect, useState, memo } from 'react'
import { Col, Card, Typography, Skeleton, Radio, Avatar, Button, Empty } from 'antd'
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { Link } from 'react-router-dom'

function DashbordOrder() {

    const [orders, setOrders] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [data, setdata] = useState('')



    useEffect(() => {
        getRecentOrders()
        setisLoading(true)
    }, [data])


    useEffect(() => {
        getRecentOrders()
    }, [])





    const getRecentOrders = async () => {
        try {

            const response = await axios.post(apiRoutes.getDashbordOrder, { status: data })
            if (response.data.data) {
                setOrders(response.data.data)

            }
        } catch (error) {

        } finally {
            setisLoading(false)
        }
    }


    const list = [
        {
            img: '/img/logo-shopify.svg',
            Title: "John doe",
            bud: "$14,000",
            progress: <span>1 Mint ago</span>,
            member: <span>I PHONE 7</span>,
        },
        {

            Title: "Ryan Tompson",
            bud: "$3,000",
            progress: <span>20 Mint ago</span>,
            member: <span>Smart watch</span>,
        },
        {

            Title: "Romina Hadid",
            bud: "Not Set",
            progress: <span>6 Hour ago</span>,
            member: <span>Mac book Pro</span>,
        },
        {

            Title: "Alexander Smith",
            bud: "$20,600",
            progress: <span>1 Days ago</span>,
            member: <span>Lenovo Think Pad</span>,
        },
        {

            Title: "Jessica Doe",
            bud: "$4,000",
            progress: <span>2 Days ago</span>,
            member: <span>Redmi Note 12</span>,
        },

        {

            Title: "Romina Hadid",
            bud: "$2,000",
            progress: <span>2 Days ago</span>,
            member: <span>Galaxy M71</span>,
        },
    ];

    return (
        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
            <Card bordered={false} className="criclebox cardbody">

                <div className="project-ant">
                    <div>
                        <Typography.Title level={5}>Recent Orders</Typography.Title>
                        <Typography.Paragraph className="lastweek">
                            done this month<span className="blue">40%</span>
                        </Typography.Paragraph>
                    </div>
                    <div className="ant-filtertabs">
                        <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                            <Radio.Group onChange={(event) => setdata(event.target.value)} defaultValue="">
                                <Radio.Button value="">NEW</Radio.Button>
                                <Radio.Button value="6">DELIVERED</Radio.Button>
                                <Radio.Button value="10">RETRUNED</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </div>

                {!isLoading ? <>
                    {!orders.length ? <div style={{ paddingTop: '25%' }}> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />  </div> : <div className="ant-list-box table-responsive">
                        <table className="width-100">
                            <thead>
                                <tr>
                                    <th>USER</th>
                                    <th>PRODUCT</th>
                                    <th>PRICE</th>
                                    <th>ORDERED ON</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{paddingTop:0}}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                                <Avatar size="middle" style={{
                                                    backgroundColor: '#f56a00',
                                                    verticalAlign: 'middle',
                                                }}>
                                                    {item.customerId?.name[0]}
                                                </Avatar>{"    "}
                                                <Typography.Text className="font-weight-bold"
                                                    style={{ width: 150 }} ellipsis={true}
                                                >
                                                    {item.customerId?.name}

                                                </Typography.Text>
                                            </div>
                                        </td>
                                        <td><span className="font-weight-bold">
                                            <Typography.Text className="font-weight-bold"
                                                style={{ width: 150 }} ellipsis={true}
                                            >
                                                {item?.name}
                                            </Typography.Text>
                                        </span></td>
                                        <td>
                                            <span className="font-weight-bold">
                                                {"₹"} {item.amount}{" "}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="font-weight-bold">{new Date(item.createdAt).toLocaleDateString("en-US")}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </> :
                    <div style={{ padding: 20 }}>
                        {[1, 2, 3].map(item => <Skeleton key={item} loading={true} active avatar>

                        </Skeleton>)}
                    </div>}

                {orders.length !== 0 && <Button type="link" block className="box-shadow-none"><Link to="/order">Show more</Link></Button>}
            </Card>
        </Col>
    )
}

export default memo(DashbordOrder)