import React, { useState, useEffect } from 'react'

import { EyeTwoTone,MessageTwoTone} from '@ant-design/icons';

import Main from '../../component/layoutnew/Main'
import { Space, Button, Table, Col, Card, Row, Radio, Modal, Descriptions } from 'antd'
import apiRoutes from '../../api//apiRoutes'
import { useNavigate } from "react-router-dom";
import axios from '../../api/axios';


function OrderReturned() {
    const navigate = useNavigate()
    const [order, setorder] = useState([])
    const [orderTemp, setorderTemp] = useState([])
    const [isSearch, setisSearch] = useState(true)
    const [comments, setComments] = useState({})


    useEffect(() => {
        getAllOrder()
    }, [])



    const getAllOrder = async () => {
        try {
            const response = await axios.get(apiRoutes.orderReturn)
            if (response?.data?.data) {
                setorder(response.data.data)
                setorderTemp(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisSearch(false)
        }
    }

    const filterOrder = (event) => {
        console.log(event.target.value)
        const filteredData = orderTemp.filter(item => item.statusCode == event.target.value)
        setorder(filteredData)
    }



    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Order ID',
            dataIndex: 'orderId',
            key: 'orderId',
        },
        {
            title: 'Orderd ON',
            dataIndex: 'orderedOn',
            key: 'orderedOn',
            render: (_, record) => {
                var date = new Date(record.createdAt)
                return date.toLocaleDateString("en-US")
            }
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (_, record) => record.customerId?.name
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => `₹ ${record.price}`
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="small" align="center">
                    <Button type="link" onClick={() => navigate(`/dashboard/orders/${record?.orderId}`)} icon={<EyeTwoTone />} />
                    <Button type="link" onClick={() => setComments(record?.return)} icon={<MessageTwoTone />} />
                </Space>
            ),
        }
    ];


    return (
        <Main page='order'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="header-solid"
                            title={'Orders'}
                            extra={
                                <div className="ant-filtertabs">
                                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                                        <Radio.Group onChange={filterOrder}>
                                            <Radio.Button value="7">REQUEST</Radio.Button>
                                            <Radio.Button value="8">ACCEPTED</Radio.Button>
                                            <Radio.Button value="9">REJECTED</Radio.Button>
                                            <Radio.Button value="10">COMPLETED</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </div>
                            }
                        >

                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table columns={columns} dataSource={order} pagination={true} search={true} loading={isSearch} size="small" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal title="Return Reason" visible={Object.keys(comments).length} onCancel={() => setComments({})} footer={[<Button onClick={() => setComments({})}>Close</Button>]}>
            <Descriptions title="">
                <Descriptions.Item label="Reason">{comments.reason}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="">
                <Descriptions.Item label="Comments">{comments.comment}</Descriptions.Item>
            </Descriptions>

        </Modal>
        </Main >
    )
}

export default OrderReturned