import React from 'react'
import { UploadOutlined } from '@ant-design/icons';
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';
import { Button, Form, Input, Select } from 'antd'


function CreateSpecificationGroup({ showList, dataEdit }) {
    const [isLoading, setIsLoading] = React.useState(false)
    const [action, setAction] = React.useState('')
    const [category, setCategory] = React.useState([])
    const [subcategory, setSubCategory] = React.useState([])

    React.useEffect(() => {
        getAllCategory()
        getAllSubCategory()
    }, [])


    const getAllSubCategory = async () => {
        try {
            const response = await axios.post(apiRoutes.getAllSubCategory)
            if (response?.data?.data) {
                setSubCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }



    const onFinish = (values) => {
        createSpecificationGroup(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const createSpecificationGroup = async (data) => {

        try {
            setIsLoading(true)
            const result = await axios.post(apiRoutes.createSpecificationGroup, data)
            setIsLoading(false)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
            setIsLoading(false)
        }
    }

    return (
        <Form
            multiple={true}
            name="basic"
            colon={false}
            labelCol={{
                span: 6,
            }}
            labelAlign="left"
            wrapperCol={{
                span: 18,
            }}
            initialValues={{
                remember: true,
                attributeTitle: dataEdit?.attributeTitle || ''
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Title required!',
                    },
                ]}
            >
                <Input placeholder="Enter Attribute title" />
            </Form.Item>

            <Form.Item
                label="Assigned to"
                name="type"
                rules={[
                    {
                        required: true,
                        message: 'Attribute is required!',
                    }
                ]}
            >
                <Select
                    placeholder="Choose Attribute type"
                    onChange={(e) => setAction(e)}
                    allowClear
                >
                    <Select.Option key={1} value='category'>Category</Select.Option>
                    <Select.Option key={2} value='subcategory'>Sub Category</Select.Option>
                </Select>
            </Form.Item>

            {action === "category" && <Form.Item
                label="Category"
                name="category"
                rules={[
                    {
                        required: true,
                        message: 'Category is required!',
                    }
                ]}
            >
                <Select
                    placeholder="Choose Attribute type"
                    allowClear
                >
                    {category.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                </Select>
            </Form.Item>}

            {action === "subcategory" && <Form.Item
                label="Sub Category"
                name="subcategory"
                rules={[
                    {
                        required: true,
                        message: 'Category is required!',
                    }
                ]}
            >
                <Select
                    placeholder="Choose Attribute type"
                    allowClear
                    onChange={(e) => console.log(e)}
                >
                    {subcategory.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                </Select>
            </Form.Item>}




            <Form.Item
                labelAlign="left"
                wrapperCol={{
                    offset: 22,

                }}
            >
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default CreateSpecificationGroup