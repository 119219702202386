import React, { useState, useEffect } from 'react'
import { EyeTwoTone, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';


import Main from '../../component/layoutnew/Main'
import { Space, Button, Table, Modal, Col, Card, Row, Avatar, Typography, Tag, Switch } from 'antd'
import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';


function Review() {
    const [review, setReview] = useState([])
    const [comment, setComment] = useState('')
    const [isUpdate, setIsUpdate] = useState("")
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        getAllReviews()
    }, [])



    const updateStatus = async (data, value) => {
        try {
            setIsUpdate(data)
            const response = await axios.post(apiRoutes.updateReviews, { reviewId: data, isActive: value })
            if (response?.data?.success) {
                getAllReviews()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsUpdate("")
        }
    }


    const getAllReviews = async () => {
        try {
            const response = await axios.get(apiRoutes.getAllReviews)
            console.log(response)
            if (response?.data?.data) {
                setReview(response.data.data)

            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }




    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Product',
            dataIndex: 'productId',
            key: 'productId',
            render: (_, record) => (<><Avatar src={record?.productId?.images[0]} /> <Typography.Text
                style={{ width: 150 }} ellipsis={true}
            >
                {record?.productId?.name}
            </Typography.Text> </>)
        },
        {
            title: 'User',
            dataIndex: 'userId?.name',
            key: 'userId?.name',
            render: (_, record) => record?.userId?.name

        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.title}
                </Typography.Text>
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
        },
        {
            title: 'Comments',
            dataIndex: 'review',
            key: 'review',
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.review}
                </Typography.Text>
        },
        // {
        //     title: 'Payment Status',
        //     dataIndex: 'paymentStatus',
        //     key: 'id',
        // },
        {
            title: 'Change Status',
            dataIndex: 'totalAmount',
            key: '_id',
            render: (_, record) => <Switch size="small" loading={record.id === isUpdate} defaultChecked={record?.isActive} onChange={() => updateStatus(record?.id, record?.isActive ? false : true)} />
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: '_id',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Approved
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                Rejected
            </Tag>
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <Button onClick={() => setComment(record.review)} icon={<EyeTwoTone />} />
                </Space>
            ),
        }
    ];



    return (
        <Main page='review'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="tablespace header-solid"
                            title={'Reviews'}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table columns={columns} dataSource={review} pagination={true} search={true} loading={isLoading} size="small" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal title="Comment" visible={comment} onCancel={() => setComment(false)} footer={[<Button onClick={() => setComment(false)}>Close</Button>]}>
                <p>{comment}</p>
            </Modal>
        </Main>
    )
}

export default Review