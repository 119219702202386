import React, { useRef, useState, useEffect } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Space, Button, Table, Modal, Card, Col, Row } from 'antd'
import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import EditAttribute from '../../component/pageComponents/attributeEdit';
import CreateAttribute from '../../component/pageComponents/attributeCreate';
import Main from '../../component/layoutnew/Main';
const { confirm } = Modal;

function Attributes() {

  
    const [attributes, setattributes] = useState([])
    const [pageAction, setpageAction] = useState({ list: true, title: 'Attributes' })
    const [isDataChanged, setisDataChanged] = useState(false)
    const [dataEdit, setdataEdit] = useState({})

    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getAllAttributes()
    }, [isDataChanged])




    const showList = (isdone = false) => {
        setpageAction({ list: true, title: 'Attributes' })
        console.log(isdone, 'is done')
        if (isdone) {
            setisDataChanged(!isDataChanged)
        }
    }

    const showCreate = () => {
        setpageAction({ create: true, title: 'Create Attribute' })
    }
    const hideEdit = () => setdataEdit(false)

    const getAllAttributes = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllAttributes)
            if (response?.data?.data) {
                setattributes(response.data.data)
                console.log(response.data.data)
            }
        } catch (error) {
            console.log(error)

        } finally {
            setisLoading(false)
        }
    }

 


   

    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1,
            dataIndex: '_id',
            key: '_id'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // render: (text) => <span>{text}</span>,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.age - b.age,
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (record, data) => (
                <Space size="middle" >
                    <Button onClick={() => setdataEdit(data)}>Edit </Button>
                    <Button onClick={() => showPromiseConfirm(record)}>Delete</Button>
                </Space>
            ),
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.deleteAttribute, { attributeId: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setisDataChanged(!isDataChanged)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };

    return (

        <Main page="attributes">
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={pageAction?.title}
                            extra={<Button onClick={pageAction?.list ? showCreate : showList} size="small" type="primary">{pageAction?.list ? 'Create' : 'Back to list'}</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {pageAction.create && <div className="mt-25">
                                    <CreateAttribute showList={showList} />
                                </div>}

                                {pageAction.list && <>
                                    {dataEdit?.title && <EditAttribute dataEdit={dataEdit} hideEdit={hideEdit} showList={showList} />}
                                    <Table columns={columns} dataSource={attributes} pagination={true} search={true} loading={isLoading} size="small" rowKey="_id" />
                                </>}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Main>

    )
}

export default Attributes