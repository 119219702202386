
import React, { useCallback } from 'react'
import Main from '../../component/layoutnew/Main'
import { Form, Input, Button, Row, Col, Select, Card, Steps } from 'antd'
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useNavigate } from 'react-router-dom'
import AccountForm from '../../component/profile/seller/account'
import StoreInfo from '../../component/profile/seller/storeInfo'
import OtherInfo from '../../component/profile/seller/otherInfo'

function CreateVendor() {

    const [isUploading, setisUploading] = React.useState(false)
    const [current, setCurrent] = React.useState(1);
    const [data, setdata] = React.useState({})
    const navigate = useNavigate()


    const createVendor = async (data) => {
        try {
            setisUploading(true)
            const result = await axios.post(apiRoutes.createVendor, data)
            if (result?.data?.success) {
                navigate('/vendor')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisUploading(false)
        }
    }


    const steps = [
        {
            title: 'Vendor Info',
        },
        {
            title: 'Store Info',
        },
        {
            title: 'Other Info',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));


    const next = useCallback((newdata) => {
        console.log({ ...newdata, ...data })

        setdata({ ...newdata, ...data })
        setCurrent(current + 1);
    }, [current, data]);

    const prev = useCallback((newdata) => {
        console.log({ ...newdata, ...data })

        setdata({ ...newdata, ...data })
        setCurrent(current - 1);
    }, [current, data])



    const onFinish = (values) => {
        
        createVendor({ ...values, ...data })
    }

    return (

        <Main page='vendor'>
            <div className="tabled">

                <Col xs="24" xl={24}>


                    <Card
                        bordered={false}
                        className="criclebox tablespace header-solid"
                        title={'Create Vendor'}
                    >
                        <div style={{ padding: 25 }}>
                            <Steps current={current - 1} items={items} />

                            <div style={{ padding: 10 }}>
                                {current === 1 && <AccountForm data={data} next={next} />}
                                {current === 2 && <StoreInfo data={data} next={next} prev={prev} />}
                                {current === 3 && <OtherInfo data={data} next={onFinish} prev={prev} isUploading={isUploading} />}

                            </div>



                            {/* <div className="steps-action" style={{justifyContent:'space-between',width:'100%'}}>
                                {current > 0 && (
                                    <Button
                                        style={{
                                            margin: '0 8px',
                                        }}
                                        onClick={() => prev()}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button type="primary" onClick={() => next()}>
                                        Next
                                    </Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button type="primary" onClick={() => { }}>
                                        Done
                                    </Button>
                                )}

                            </div> */}

                        </div>


                    </Card>

                </Col>






                {false && <Form
                    name="basic"
                    labelCol={{
                        span: 0,
                    }}
                    wrapperCol={{
                        span: 0,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[24, 0]}>

                        <Col span={24}><Form.Item
                        >
                            <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={isUploading}>
                                Submit
                            </Button>
                        </Form.Item></Col>


                        <Col xs="24" xl={16}>

                            <Card
                                bordered={false}
                                className="criclebox tablespace"
                                title={'Vendor Info'}
                            >
                                <div className="table-responsive" style={{ margin: "0 25px" }}>

                                    <Col span={24} className="mt-25">


                                        <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Name required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        {/* ant-col ant-col-24 ant-col-offset-22 ant-form-item-control */}

                                        <Form.Item
                                            label="User Name"
                                            name="userName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'User Name required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Email required',
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'password required',
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </div>
                            </Card>

                            <Card
                                bordered={false}
                                className="criclebox tablespace mt-25"
                                title={'Store info'}
                            >
                                <div className="table-responsive" style={{ margin: "0 25px" }}>
                                    <Form.Item label="Store Name" name="store" className="mt-25" rules={[
                                        {
                                            required: true,
                                            message: 'Store name required',
                                        },
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Store Address" name="storeaddress" className="mt-25" rules={[
                                        {
                                            required: true,
                                            message: 'Store address required',
                                        },

                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                            </Card>


                        </Col>
                        <Col xs="24" xl={8}>

                            <Card bordered={false}
                                className="criclebox tablespace"
                                title={'Vendor Status'}>
                                <div className="table-responsive" style={{ margin: "0 25px" }}>

                                    <Form.Item
                                        className="mt-25"
                                        name="isActive"
                                        label=""
                                        hasFeedback
                                        style={{ width: '100%' }}
                                    >
                                        <Select defaultValue="Publish">
                                            <Select.Option value={true}>Active</Select.Option>
                                            <Select.Option value={false}>In Active</Select.Option>
                                        </Select>
                                    </Form.Item>


                                </div>

                            </Card>

                            {/* <Card bordered={false}
                                className="criclebox tablespace mt-25"
                                title={'Other Info'}>
                                <div className="table-responsive" style={{ margin: "0 25px" }}>


                                    <Form.Item
                                        className="mt-25"
                                        name="category"
                                        label="Category"
                                        hasFeedback
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Category required',
                                            },
                                        ]}
                                    >
                                        <Select onChange={filterSubCategory}>
                                            {category.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>


                                    <Form.Item
                                        name="subCategory"
                                        label="Sub Category"
                                        hasFeedback
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'SubCategory required',
                                            },
                                        ]}
                                    >
                                        <Select onChange={() => { }}>
                                            {filterdSubCategory.map(item => <Select.Option key={item.id} value={item.id} >{item.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>




                                    <Form.Item
                                        name="brand"
                                        label="Brand"
                                        hasFeedback
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Brand required',
                                            },
                                        ]}
                                    >
                                        <Select>
                                            {brand.map(item => <Select.Option key={item?.id} value={item?.id}>{item?.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="tags"
                                        label="Tags"
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tags required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="tags"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Enter atleast one tag"
                                        >
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="returnPolicy"
                                        label="Days of to be return"
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Return Policy required',
                                            },
                                        ]}
                                    >
                                        <InputNumber className="w-100" />
                                    </Form.Item>

                                    <Form.Item
                                        name="isCod"
                                        label="Cash on Delivery"
                                        style={{ width: '100%' }}
                                    >
                                        <Switch />
                                    </Form.Item>

                                    <Form.Item
                                        name="deliveryCharge"
                                        label="Delivery Charge"
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <InputNumber className="w-100" />
                                    </Form.Item>




                                </div>

                            </Card> */}


                        </Col>
                    </Row>
                </Form>}


            </div>
        </Main >
    )
}





export default CreateVendor