import React, {  useState, useEffect } from 'react'

import { ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Space, Avatar, Button, Tag, Table, Modal, Card, Col, Row } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import CreateBanner from '../../component/pageComponents/bannerCreate';
import EditBanner from '../../component/pageComponents/bannerEdit';
import axios from '../../api/axios';
import Main from '../../component/layoutnew/Main';
import { motion } from 'framer-motion'
const { confirm } = Modal;




function Banner() {

    const [banner, setbanner] = useState([])
    const [pageAction, setpageAction] = useState({ list: true, title: 'Banners', btnTitle: 'Create' })
    const [isDataChanged, setisDataChanged] = useState(false)
    const [isLoading, setisLoading] = useState(true)
    const [dataEdit, setdataEdit] = useState({})

    const [showEdit, setshowEdit] = useState({})


    useEffect(() => {
        getAllBanner()
    }, [isDataChanged])



    const showList = (isdone = false) => {
        setpageAction({ list: true, title: 'Banners', btnTitle: 'Create Banner' })
        if (isdone) {
            setisDataChanged(!isDataChanged)
        }
    }

    const showCreate = () => {
        setpageAction({ create: true, title: 'Create Banner', btnTitle: 'Back to list' })

    }

    const getAllBanner = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllBanner)
            if (response?.data?.data) {
                setbanner(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const hideEdit = () => {
        setshowEdit({})
        setisDataChanged(!isDataChanged)
    }


    const columns = [

        {
            title: 'Image',
            dataIndex: 'bannerTitle',
            key: 'id',
            render: (_, record) =>
                <Avatar className="shape-avatar" src={record.image} alt="" />
        },
        {
            title: 'Title',
            dataIndex: 'bannerTitle',
            key: 'id',
        },
        {
            title: 'Heading',
            dataIndex: 'alternateTitle',
            key: 'id',

        },
        {
            title: 'Start',
            dataIndex: 'startDate',
            key: 'id',
            render: (_, record) => {
                var date = new Date(record.startDate)
                return date.toLocaleDateString("en-US")
            }

        },
        {
            title: 'Expiry',
            dataIndex: 'endDate',
            key: 'id',
            render: (_, record) => {
                var date = new Date(record.endDate)
                return date.toLocaleDateString("en-US")
            }

        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Published
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                Unpublished
            </Tag>,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" >
                    <Button onClick={() => setshowEdit(record)}>Edit </Button>
                    <Button onClick={() => showPromiseConfirm(record.id)}>Delete</Button>
                </Space>
            ),
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.deleteBanner, { bannerId: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setisDataChanged(!isDataChanged)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };




    return (
        <Main page='banner'>
            <motion.div className="tabled" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title={pageAction?.title}
                            extra={<Button onClick={pageAction?.list ? showCreate : showList} size="small" type="primary">{pageAction?.btnTitle}</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {pageAction.list && <Table rowKey="id" bordered={false} size='small' columns={columns} dataSource={banner} pagination={true} search={true} loading={isLoading} style={{ margin: 0 }} />}
                                {pageAction.create && <CreateBanner showList={showList} />}
                                {Object.keys(showEdit).length !== 0 && <EditBanner close={hideEdit} data={showEdit} />}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </Main>
    )
}

export default Banner