const SETTLEMENT_STATUS = {
    1: { title: "Requested", status: "processing" },
    2: { title: "Pending", status: "warning" },
    3: { title: "Completed", status: "success" },
    4: { title: "Rejected", status: "error" }
}


export {
    SETTLEMENT_STATUS
}
