import { useRef, useState, useEffect } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, ReloadOutlined, TableOutlined, DownloadOutlined } from '@ant-design/icons';
import { Space, Avatar, Button, Table, Modal, Tag, Form, Card, Col, Row, Typography, Input, Radio } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import Main from '../../component/layoutnew/Main';
import Highlighter from 'react-highlight-words';
import axios from '../../api/axios'
import Error from '../../component/main/error';



function LowStock() {
    const [form] = Form.useForm();

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [product, setproduct] = useState([])

    const [isLoading, setisLoading] = useState(true)
    const [isError, setisError] = useState(false)
    const [inventory, setinventory] = useState({})
    const [isUpdating, setisUpdating] = useState(false)

    useEffect(() => {
        getAllProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            stock: inventory?.stock,
        });
    }, [inventory]);



    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };





    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>


                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (

                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Typography.Text>
            ) : (
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {text}
                </Typography.Text>
            ),
    });



    const getAllProduct = async () => {
        try {
            !isLoading && setisLoading(true)
            isError && setisError(false)

            const response = await axios.post(apiRoutes.getLowStock)
            if (response?.data?.data) {
                setproduct(response.data.data)
            }
            setisError(false)
        } catch (error) {
            setisError(true)
            console.log(error)
        } finally {
            setisLoading(false)

        }
    }



    const columns = [
        {
            title: <Typography.Text strong style={{ margin: 10 }}> # </Typography.Text>,
            render: (_, record, index) => <Typography.Text strong style={{ margin: 10 }}> {index + 1}</Typography.Text>,
            dataIndex: 'id',
            key: 'id'
        },

        {
            title: 'Image',
            dataIndex: 'logo',
            key: 'logo',
            render: (_, record) => (<Avatar src={record.images[0]} />)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "30%",
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.name}
                </Typography.Text>,
            ...getColumnSearchProps('name')
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            render: (_, record) => <Typography.Text type={parseInt(record?.stock) >= 5 ? "success" : parseInt(record?.stock) === 0 ? "danger" : "warning"} strong>{record?.stock}</Typography.Text>
        },
        {
            title: 'Category',
            dataIndex: 'productGroup?.category?.title',
            key: 'productGroup?.category?.title'
        },
        {
            title: 'Status',
            dataIndex: 'isApproved',
            key: 'isApproved',
            render: (_, record) => record?.status?.isApproved ? <Tag color="success">
                Approved
            </Tag> : record?.status?.isRejected ? <Tag color="error">
                Rejected
            </Tag> : <Tag color="processing">
                Pending
            </Tag>,
            filters: [{ text: "Active", value: true }, { text: "In Active", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Publish',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Active
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                InActive
            </Tag>,
            filters: [{ text: "Active", value: true }, { text: "In Active", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'Action',
            render: (_, record, { stock }) => (
                <Space size="middle" >
                    <Button type="link" onClick={() => setinventory({ id: record?.id, stock: record?.stock })}>Update stock</Button>
                </Space>
            ),
        }
    ];




    const updatingStock = async data => {
        try {

            setisUpdating(true)
            const result = await axios.post(apiRoutes.updateStock, { ...data, id: inventory.id })
            const { success } = result?.data
            success && setisUpdating(false); setinventory({})
        } catch (error) {

        } finally {
            setisUpdating(false)
        }
    }




    if (isError) return (<Main page='products'> <Error title="Failed to fetch product" retry={getAllProduct} /></Main>)

    return (
        <Main page='products'>
            <div className="tabled">
                <Row gutter={{ xs: 24, sm: 24, md: 24 }} >
                    <Col className="w-100">
                        <Card
                            bordered={true}
                            className="tablespace"
                            title="Low stock products"
                            extra={
                                <Space>
                                    <Button onClick={getAllProduct} icon={<ReloadOutlined />} size="middle" />
                                    <Button icon={<TableOutlined />} size="middle" />
                                    <Button icon={<DownloadOutlined />} size="middle" />
                                </Space>}
                        >
                            <div className="table-responsive" >
                                <Table rowKey="id" columns={columns} dataSource={product} pagination={true} search={true} loading={isLoading} size="small" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal
                open={Object.keys(inventory).length}
                title="Update Stock"
                okText="Update stock"
                onCancel={()=>setinventory({})}
                confirmLoading={isUpdating}
                onOk={() => form.submit()}
            >
                <Form
                    form={form}
                    onFinish={updatingStock}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="stock"
                        label="Stock count"
                        initialValue={inventory?.stock}
                        dependencies={[inventory]}
                        rules={[
                            {
                                required: true,
                                message: 'Stock is required!',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Form>
            </Modal>
        </Main >
    )
}



export default LowStock