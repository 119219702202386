import React from 'react'
import DashboardPageLayout from '../component/Layout/DashboardPageLayout'
// import DashboardPageLayout from 'Layout/DashboardPageLayout'
import AppContent from '../component/content'
import { Select, Button } from 'antd';




const { Option } = Select;


function Test() {
    const children = [];

    const [selectList, setselectList] = React.useState([1])



    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    return (
        <DashboardPageLayout>
            <AppContent>
                <div className="site-card-wrapper" style={{ margin: '16px 0' }}>

                    <Button type="primary" onClick={() => {
                        let t = selectList.concat([1])
                        setselectList(t)
                    }}>create</Button>

                    {selectList.map(item =>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select"
                            defaultValue={['a10', 'c12']}
                            onChange={handleChange}
                        >
                            {children}
                        </Select>)}




                </div>
            </AppContent>
        </DashboardPageLayout>

    )
}

export default Test