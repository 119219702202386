
import React from 'react'
import Main from '../../component/layoutnew/Main'
import { Form, Input, Switch, Button, Row, Col, Select, Upload, Table, Card, InputNumber } from 'antd'
import { PlusOutlined, PlusCircleTwoTone, DeleteTwoTone } from '@ant-design/icons';
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useNavigate } from 'react-router-dom'


function CreateProduct() {
    const [isVeriableProduct, setisVeriableProduct] = React.useState(false)
    const [category, setcategory] = React.useState([])
    const [subCategory, setsubCategory] = React.useState([])
    const [filterdSubCategory, setfilterdSubCategory] = React.useState([])
    const [isUploading, setisUploading] = React.useState(false)
    const [attributes, setattributes] = React.useState([])
    const [attributeItems, setattributeItems] = React.useState([])
    const [filteredAttributeItem, setfilteredAttributeItem] = React.useState([])
    const [attributeCompo, setattributeCompo] = React.useState([])
    const [attributeItemValue, setattributeItemValue] = React.useState([{ _id: '62da4a0dfba34ce2cdfa9aa5', title: '4GB', attribute: '62da49c1fba34ce2cdfa9a9d' }])

    const [data, setdata] = React.useState([])
    const [attributeCompoId, setattributeCompoId] = React.useState([])
    const [brand, setbrand] = React.useState([])
    const [tax, setTax] = React.useState([])

    const navigate = useNavigate()

    React.useEffect(() => {
        getAllCategory()
        getAllSubCategory()
        getAllAttributes()
        getAllAttributeItems()
        getAllBrand()
        getTax()
    }, [])


    const getTax = async () => {
        try {
            const result = await axios.get(apiRoutes.getTax)
            if (result.data?.data) {
                setTax(result.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const createProduct = async (data) => {
        try {
            setisUploading(true)
            const result = await axios.post(apiRoutes.createProduct, data)
            if (result?.data?.success) {
                navigate('/product')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisUploading(false)
        }
    }


    const getAllSubCategory = async () => {
        try {
            const response = await axios.post(apiRoutes.getAllSubCategory)
            if (response?.data?.data) {
                setsubCategory(response.data.data)
                setfilterdSubCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllAttributes = async () => {
        try {
            const result = await axios.get(apiRoutes.attributeList)
            if (result?.data?.data) {
                setattributes(result.data.data)
            }
            // console.log(result.data)
        } catch (error) {
            console.error(error)

        }
    }


    const getAllAttributeItems = async () => {
        try {
            const result = await axios.get(apiRoutes.attributeItemList)
            if (result?.data?.data) {
                setattributeItems(result.data.data)
            }
            // console.log(result.data)
        } catch (error) {
            console.error(error)

        }
    }


    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setcategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const filterSubCategory = async (data) => {
        const filteredData = subCategory.filter(item => item.category === data)
        setfilterdSubCategory(filteredData)
        // console.log(data)
    }

    const filterAttributeItem = async (data) => {
        try {
            const filteredData = attributeItems.filter(item => item.attribute === data)
            setfilteredAttributeItem(filteredData)
        } catch (error) {
            console.error(error)

        }
    }

    const getAllBrand = async () => {
        try {
            const response = await axios.get(apiRoutes.allBrand)
            if (response?.data?.data) {
                setbrand(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const deleteAttributeCompo = async (index) => {
        const tempCompo = [...attributeCompo]
        tempCompo.splice(index, 1)
        setattributeCompo(tempCompo)

    }


    const itemChange = (value, index) => {
        let filterdValue = []
        value.forEach(item => {
            filterdValue.push(JSON.parse(item))
        })
        const itemData = [...attributeItemValue]
        itemData[index] = filterdValue
        setattributeItemValue(itemData)
        const newComppination = compinationGenerator(itemData)
        setdata(newComppination)
        const tempattributeCompoId = []
        newComppination.forEach(item => {
            var tempid = item.map(newitem => newitem._id)
            tempattributeCompoId.push(tempid.toString())
        })
        setattributeCompoId(tempattributeCompoId)
    }


    const attributeCompoIncrement = () => {
        const currentAttribute = [...attributeCompo]
        currentAttribute.push(0)
        setattributeCompo(currentAttribute)
    }


    const compinationGenerator = (data) => {
        let arg = data
        var r = [], max = arg.length - 1;
        const helper = (arr, i) => {
            for (var j = 0, l = arg[i].length; j < l; j++) {
                var a = arr.slice(0); // clone arr
                a.push(arg[i][j])
                if (i === max) {
                    r.push(a);
                } else
                    helper(a, i + 1);
            }
        }
        helper([], 0);
        return r;
    }



    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('name', values.name)
        formData.append('brand', values.brand)
        formData.append('tax', values.tax)
        formData.append('category', values.category)
        formData.append('subCategory', values.subCategory)
        formData.append('isSimpleProduct', !isVeriableProduct)
        formData.append('tags', values.tags)
        formData.append('shortDescription', values.shortDescription)
        formData.append('description', values.description)
        formData.append('returnPolicy', values.returnPolicy)
        formData.append('isCod', values?.isCod || false)
        formData.append('deliveryCharge', values.deliveryCharge)
        values.price && (formData.append('price', values.price))
        values.stock && (formData.append('stock', values.stock))
        values.sku && (formData.append('sku', values.sku))
        values.mrp && (formData.append('mrp', values.mrp))
        values.compinations && (formData.append('compinations', JSON.stringify(values.compinations)))
        attributeCompoId.length !== 0 && (formData.append('veriations', JSON.stringify(attributeCompoId)))

        // formData.append('files',fileData)
        values.files.fileList.forEach(item => formData.append('files', item.originFileObj))
        // console.log(formData)
        createProduct(formData)
    }

    return (

        <Main page='product'>
            <div className="tabled">
                <Form
                    name="basic"
                    labelCol={{
                        span: 0,
                    }}
                    wrapperCol={{
                        span: 0,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[24, 0]}>

                        <Col span={24}><Form.Item
                        >
                            <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={isUploading}>
                                Submit
                            </Button>
                        </Form.Item></Col>


                        <Col xs="24" xl={16}>

                            <Card
                                bordered={false}
                                className="criclebox tablespace"
                                title={'Product Info'}
                            >
                                <div className="table-responsive" style={{ margin: "0 25px" }}>

                                    <Col span={24} className="mt-25">

                                        <Form.Item
                                            label="Product Name"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Product Name required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        {/* ant-col ant-col-24 ant-col-offset-22 ant-form-item-control */}

                                        <Form.Item
                                            label="short Description"
                                            name="shortDescription"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Short Description required',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea rows={4} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Description required',
                                                }
                                            ]}
                                        >
                                            <Input.TextArea rows={6} />
                                        </Form.Item>
                                    </Col>


                                </div>
                            </Card>

                            <Card
                                bordered={false}
                                className="criclebox tablespace mt-25"
                                title={'Product Images'}
                            >
                                <div className="table-responsive" style={{ margin: "0 25px" }}>
                                    <Form.Item label="Images" name="files" className="mt-25" rules={[
                                        {
                                            required: true,
                                            message: 'Image  required',
                                        },

                                    ]}>


                                        <Upload listType="picture-card" onChange={(e) => console.log(e)}>
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Images
                                                </div>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </Card>

                            <Card
                                bordered={false}
                                className="criclebox tablespace mt-25"
                                title={'Product Price Stock'}
                                extra={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Form.Item
                                    layout="vertical"

                                >
                                    <Switch checkedChildren="Veriable" unCheckedChildren="Simple" defaultChecked={isVeriableProduct} onChange={() => setisVeriableProduct(!isVeriableProduct)} />
                                </Form.Item></div>}
                            >
                                <div className="table-responsive" style={{ margin: "0 25px" }}>
                                    {isVeriableProduct && <div>
                                        <Row gutter={10} className="mt-25">
                                            <Col span={6}>
                                                <Form.Item
                                                    name="status"
                                                    label="Attribute"
                                                    hasFeedback
                                                >
                                                    <Select onChange={filterAttributeItem}>
                                                        {attributes.map((item, index) => <Select.Option key={index} value={item._id}>{item.title}</Select.Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={16}>
                                                <Form.Item
                                                    label="Attribute Items"
                                                    hasFeedback
                                                >
                                                    <Select onChange={(data) => itemChange(data, 0)} mode="multiple">
                                                        {filteredAttributeItem.map((item, index) => <Select.Option key={index} value={JSON.stringify(item)}>{item.title}</Select.Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}> <Form.Item
                                                label=" "
                                                hasFeedback
                                            >
                                                <Button onClick={attributeCompoIncrement}><PlusCircleTwoTone /></Button>
                                            </Form.Item></Col>
                                        </Row>

                                        {attributeCompo.map((newitem, index) =>
                                            <Row gutter={10} key={index}>
                                                <Col span={6}>
                                                    <Form.Item
                                                        key={index}
                                                        label="Attribute"
                                                        hasFeedback
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Select onChange={filterAttributeItem}>
                                                            {attributes.map(item => <Select.Option key={item._id} value={item._id}>{item.title}</Select.Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item
                                                        key={`${index}item`}
                                                        label="Attribute Items"
                                                        hasFeedback
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Select onChange={(data) => itemChange(data, index + 1)} mode="multiple">
                                                            {filteredAttributeItem.map((item, index) => <Select.Option key={index} value={JSON.stringify(item)}>{item.title}</Select.Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={2}> <Form.Item
                                                    label=" "
                                                    hasFeedback
                                                >
                                                    <Button type="link" onClick={() => deleteAttributeCompo(index)} danger><DeleteTwoTone twoToneColor="#FF0000" /> </Button>
                                                </Form.Item></Col>

                                            </Row>)}



                                    </div>}


                                    {data.length !== 0 && isVeriableProduct && <Form.List name="compinations">

                                        {() => {
                                            return <Table dataSource={data} pagination={false} size="small" rowKey="_id">

                                                <Table.Column
                                                    dataIndex={"_id"}
                                                    key={'_id'}
                                                    title={"Veriant"}
                                                    render={(value, row, index) => {
                                                        let title = row.map(item => item.title)
                                                        return <h5>{title.toString()}</h5>
                                                    }}
                                                />



                                                <Table.Column
                                                    title={"SKU"}
                                                    key={'_id'}
                                                    render={(value, row, index) => {
                                                        return (
                                                            <Form.Item label=" " required={false} name={[index, "sku"]} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'SKU required',
                                                                },
                                                            ]} >
                                                                <Input />
                                                            </Form.Item>
                                                        );
                                                    }}
                                                />

                                                <Table.Column
                                                    title={"MRP"}
                                                    key={'_id'}
                                                    render={(value, row, index) => {
                                                        return (
                                                            <Form.Item label=" " required={false} name={[index, "mrp"]} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'MRP required',
                                                                },
                                                            ]} >
                                                                <InputNumber className="w-100" />
                                                            </Form.Item>
                                                        );
                                                    }}
                                                />
                                                <Table.Column
                                                    title={"Price"}
                                                    key={'_id'}
                                                    render={(value, row, index) => {
                                                        return (
                                                            <Form.Item label=" " required={false} name={[index, "price"]} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Price required',
                                                                },
                                                            ]} >
                                                                <InputNumber className="w-100" />
                                                            </Form.Item>
                                                        );
                                                    }}
                                                />
                                                <Table.Column
                                                    title={"Stock"}
                                                    key={'_id'}
                                                    render={(value, row, index) => {
                                                        return (
                                                            <Form.Item label=" " required={false} name={[index, "stock"]} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Stock required',
                                                                },
                                                            ]} >
                                                                <InputNumber className="w-100" />
                                                            </Form.Item>
                                                        );
                                                    }}
                                                />

                                                <Table.Column
                                                    title={"Tax"}
                                                    key={'_id'}
                                                    render={(value, row, index) => {
                                                        return (
                                                            <Form.Item label=" " required={false} name={[index, "tax"]} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Tax required',
                                                                },
                                                            ]} >
                                                                <Select showSearch defaultValue={tax[0].id}>
                                                                    {tax.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        );
                                                    }}
                                                />

                                                <Table.Column
                                                    dataIndex={"name"}
                                                    title={"Action"}
                                                    render={(value, row, index) => <Button type="link"> <DeleteTwoTone twoToneColor="#FF0000" /></Button>}
                                                />
                                            </Table>
                                        }}
                                    </Form.List>}


                                    {!isVeriableProduct && <>


                                        <Form.Item
                                            className="mt-25"
                                            label="Tax"
                                            name="tax"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tax required',
                                                },
                                            ]}
                                        >
                                            <Select showSearch>
                                                {tax.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            className="mt-25"
                                            label="MRP"
                                            name="mrp"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'MRP required',
                                                },
                                                {
                                                    type: 'number',
                                                    message: "Please enter a number"
                                                }
                                            ]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Selling Price"
                                            name="price"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Price required',
                                                },
                                            ]}
                                        >
                                            <InputNumber className="w-100" />

                                        </Form.Item>
                                        <Form.Item
                                            label="Stock"
                                            name="stock"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Stock required',
                                                },
                                            ]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Sku"
                                            name="sku"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'sku required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                    </>}


                                </div>
                            </Card>
                        </Col>
                        <Col xs="24" xl={8}>

                            <Card bordered={false}
                                className="criclebox tablespace"
                                title={'Product Status'}>
                                <div className="table-responsive" style={{ margin: "0 25px" }}>

                                    <Form.Item
                                        className="mt-25"
                                        name="isActive"
                                        label=""
                                        hasFeedback
                                        style={{ width: '100%' }}
                                    >
                                        <Select defaultValue="Publish">
                                            <Select.Option value={'Publish'}>Publish</Select.Option>
                                            <Select.Option value={'Draft'}>Draft</Select.Option>
                                        </Select>
                                    </Form.Item>


                                </div>

                            </Card>

                            <Card bordered={false}
                                className="criclebox tablespace mt-25"
                                title={'Other Info'}>
                                <div className="table-responsive" style={{ margin: "0 25px" }}>


                                    <Form.Item
                                        className="mt-25"
                                        name="category"
                                        label="Category"
                                        hasFeedback
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Category required',
                                            },
                                        ]}
                                    >
                                        <Select onChange={filterSubCategory}>
                                            {category.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>


                                    <Form.Item
                                        name="subCategory"
                                        label="Sub Category"
                                        hasFeedback
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'SubCategory required',
                                            },
                                        ]}
                                    >
                                        <Select onChange={() => { }}>
                                            {filterdSubCategory.map(item => <Select.Option key={item.id} value={item.id} >{item.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>




                                    <Form.Item
                                        name="brand"
                                        label="Brand"
                                        hasFeedback
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Brand required',
                                            },
                                        ]}
                                    >
                                        <Select>
                                            {brand.map(item => <Select.Option key={item?.id} value={item?.id}>{item?.title}</Select.Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="tags"
                                        label="Tags"
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tags required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="tags"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Enter atleast one tag"
                                        >
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="returnPolicy"
                                        label="Days of to be return"
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Return Policy required',
                                            },
                                        ]}
                                    >
                                        <InputNumber className="w-100" />
                                    </Form.Item>

                                    <Form.Item
                                        name="isCod"
                                        label="Cash on Delivery"
                                        style={{ width: '100%' }}
                                    >
                                        <Switch />
                                    </Form.Item>

                                    <Form.Item
                                        name="deliveryCharge"
                                        label="Delivery Charge"
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <InputNumber className="w-100" />
                                    </Form.Item>




                                </div>

                            </Card>

                            {/* <Card bordered={false}
                                className="criclebox tablespace mt-25"
                                title={'Product Features'}>
                                <div className="table-responsive" style={{ margin: "0 25px"}}>
                                    
                                    <Form.Item
                                        layout="horizontal"
                                        label="Username"
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                       <Input />
                                    </Form.Item>
                                </div>

                            </Card> */}
                        </Col>
                    </Row>
                </Form>
            </div>
        </Main >




    )
}

export default CreateProduct