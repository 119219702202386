import React from 'react'
import { Space, Empty } from 'antd'

function Blank() {

    return (
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Empty />
        </Space>
    )
}

export default Blank