import React, {useState, useEffect } from 'react'

import Main from '../../component/layoutnew/Main'

import { Descriptions, Card, Col, Row, Button, Avatar, PageHeader, Skeleton, Statistic, Steps, Image, Modal, Select, Form, Tag } from 'antd'
import { useParams } from "react-router-dom";
import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import moment from 'moment'





function OrderItemDetails() {
    const [orderDetails, setorderDetails] = useState({})
    const [orderItems, setorderItems] = useState([])
    const [showModel, setshowModel] = useState(false)
    const [isUpdated, setisUpdated] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isFetching, setisFetching] = useState(true)

    let { id } = useParams();
    const [form] = Form.useForm();



    useEffect(() => {
        getOrderDetails()
    }, [isUpdated])


    const onFinish = (values) => {
        const data = {}
        data.status = values.status
        data.itemId = id
        updateStatus(data)
    }

    const updateStatus = async (data) => {

        try {
            setisLoading(true)
            const result = await axios.post(apiRoutes.updateOrderItemStatus, data)
            if (result?.data?.success) {
                setisUpdated(!isUpdated)
                setshowModel(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const getOrderDetails = async () => {
        try {
            const url = `${apiRoutes.getOrderItemDetails}/${id}`
            const response = await axios.post(url)
            if (response?.data?.data) {
                setorderDetails(response.data.data)
                if (response?.data?.data?.orderItems) {
                    setorderItems(response?.data?.data?.orderItems)
                }
            }

        } catch (error) {
            console.log(error)
        } finally {
            setisFetching(false)
        }
    }



    return (

        <Main page='order'>
            <div className="tabled">
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        {isFetching ? <Skeleton active={isFetching} /> : <Card bordered={false}>
                            <PageHeader
                                style={{ padding: 0 }}
                                onBack={() => window.history.back()}
                                title={orderDetails?.name}
                                extra={[
                                    <Button key="1" type="primary" onClick={() => { setshowModel(true) }}>
                                        Change Status
                                    </Button>,
                                ]}
                            >

                                <Row>
                                    <Col span={3}>
                                        <Image
                                            width={100}
                                            src={orderDetails?.image}
                                        />
                                    </Col>
                                    <Col span={16}>
                                        <Descriptions size="small" column={2}>
                                            <Descriptions.Item label="Order on">{new Date(orderDetails?.createdAt).toLocaleDateString("en-US")}</Descriptions.Item>
                                            <Descriptions.Item label="Payment Method">
                                                {orderDetails?.paymentMethod}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Price">{orderDetails?.price}</Descriptions.Item>
                                            <Descriptions.Item label="Payment Status"><Tag color={orderDetails?.paymentStatus === 'PAID' ? "#87d068" : "red"}>{orderDetails?.paymentStatus}</Tag></Descriptions.Item>
                                            <Descriptions.Item label="Quantity">
                                                {orderDetails?.quantity}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Order ID">
                                                {orderDetails?._id}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col span={5} >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: 'max-content',
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            <Statistic
                                                title="Status"

                                                value={orderDetails?.status}
                                                style={{
                                                    marginRight: 32,
                                                }}
                                            />
                                            {/* <Statistic title="Price" prefix="₹" value={orderDetails?.orderItems?.amount} /> */}
                                        </div>
                                    </Col>
                                </Row>

                                {[2, 3, 4, 5, 6].includes(orderDetails?.statusCode) &&
                                    <Steps current={orderDetails?.statusCode - 2} style={{ marginTop: 40 }} >
                                        <Steps.Step title="Order Recived" description={moment(orderDetails?.createdAt).format('LLL')} />
                                        <Steps.Step title="Processing" description={orderDetails?.statusHistory?.Processing && moment(orderDetails?.statusHistory?.Processing).format('LLL')} />
                                        <Steps.Step title="Shipped" description={orderDetails?.statusHistory?.Shipped && moment(orderDetails?.statusHistory?.Shipped).format('LLL')} />
                                        <Steps.Step title="Out for delivery" description={orderDetails?.statusHistory?.Out_For_Delivery && moment(orderDetails?.statusHistory?.Out_For_Delivery).format('LLL')} />
                                        <Steps.Step title="Delivered" description={orderDetails?.statusHistory?.Delivered && moment(orderDetails?.statusHistory?.Delivered).format('LLL')} />
                                        {/* <Steps.Step title="Waiting" description="This is a description." /> */}
                                    </Steps>}

                                {[7, 8, 10].includes(orderDetails?.statusCode) &&
                                    <Steps current={orderDetails?.statusCode === 10 ? 8 - 4 : orderDetails?.statusCode - 5} style={{ marginTop: 40 }} >
                                        <Steps.Step title="Order Recived" />
                                        <Steps.Step title="Delivered" description={orderDetails?.statusHistory?.Delivered && moment(orderDetails?.statusHistory?.Delivered).format('LLL')} />
                                        <Steps.Step title="Return Requested" />
                                        <Steps.Step title="Accepted" />
                                        <Steps.Step title="Returned" />
                                        {/* <Steps.Step title="Waiting" description="This is a description." /> */}
                                    </Steps>}

                                {[0].includes(orderDetails?.statusCode) &&
                                    <Steps current={orderDetails?.statusCode} style={{ marginTop: 40 }} >
                                        <Steps.Step title="Order Recived" description={moment(orderDetails?.createdAt).format('LLL')} />
                                        <Steps.Step status="error" title="Cancelled" description={orderDetails?.statusHistory?.Processing && moment(orderDetails?.statusHistory?.Processing).format('LLL')} />
                                    </Steps>}

                                {[9].includes(orderDetails?.statusCode) &&
                                    <Steps current={orderDetails?.statusCode - 4} style={{ marginTop: 40 }} >
                                        <Steps.Step title="Order Recived" description={moment(orderDetails?.createdAt).format('LLL')} />
                                        <Steps.Step title="Delivered" description={orderDetails?.statusHistory?.Delivered && moment(orderDetails?.statusHistory?.Delivered).format('LLL')} />
                                        <Steps.Step title="Return Requested" />
                                        <Steps.Step status="error" title="Rejected" description={orderDetails?.statusHistory?.Processing && moment(orderDetails?.statusHistory?.Processing).format('LLL')} />
                                    </Steps>}

                                <Card style={{ marginTop: 20 }} bordered={false} className="box-shadow-none">
                                    <Descriptions title="Shipping Address">
                                        <Descriptions.Item label="Name">{orderDetails?.orderId?.shippingAddress?.fullName}</Descriptions.Item>
                                        <Descriptions.Item label="Phone">{orderDetails?.orderId?.shippingAddress?.mobile}</Descriptions.Item>
                                        <Descriptions.Item label="Address type">{orderDetails?.orderId?.shippingAddress?.addressType === 0 ? "Office" : "Home"}</Descriptions.Item>

                                        <Descriptions.Item label="landmark">
                                            {orderDetails?.orderId?.shippingAddress?.landmark}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="City">
                                            {orderDetails?.orderId?.shippingAddress?.city}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="State">
                                            {orderDetails?.orderId?.shippingAddress?.state}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Country">
                                            {orderDetails?.orderId?.shippingAddress?.country}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Address">{orderDetails?.orderId?.shippingAddress?.address1}</Descriptions.Item>
                                    </Descriptions>
                                </Card>

                                <Modal
                                    title="Change Order Status"
                                    open={showModel}
                                    onOk={() => form.submit()}
                                    confirmLoading={isLoading}
                                    onCancel={() => { setshowModel(false) }}
                                    okText="Update Status"
                                >
                                    <Form
                                        form={form}
                                        name="basic"
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={() => { }}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            name="status"
                                            label=""
                                            initialValue={orderDetails?.statusCode}
                                            hasFeedback
                                            style={{ width: '100%' }}
                                        >
                                            <Select onChange={() => { }}>
                                                <Select.Option key={1} value={1}>Awaiting Payment</Select.Option>
                                                <Select.Option key={2} value={2}>Order Recived</Select.Option>
                                                <Select.Option key={3} value={3}>Processing</Select.Option>
                                                <Select.Option key={4} value={4}>Shipped</Select.Option>
                                                <Select.Option key={5} value={5}>Out for Delivery</Select.Option>
                                                <Select.Option key={6} value={6}>Delivered</Select.Option>
                                                <Select.Option key={7} value={7}>Return Requested</Select.Option>
                                                <Select.Option key={8} value={8}>Return Accepted</Select.Option>
                                                <Select.Option key={9} value={9}>Return Rejected</Select.Option>
                                                <Select.Option key={10} value={10}>Returned</Select.Option>
                                                {/* <Select.Option key={0} value={0}>Cancelled</Select.Option> */}
                                            </Select>
                                        </Form.Item>

                                    </Form>
                                </Modal>
                                {/* <Content extra={extraContent}>{renderContent()}</Content> */}
                            </PageHeader>

                        </Card>}
                    </Col>
                </Row>
            </div>

        </Main >
    )
}



export default OrderItemDetails