import { Descriptions, Skeleton } from 'antd';
import axios from '../../../../api/axios'
import apiRoutes from '../../../../api/apiRoutes'
import { useState, useEffect } from 'react';


const BankInfo = ({ data, id }) => {

    const [bankInfo, setbankInfo] = useState({})
    const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        getBankInfo()
    }, [])




    const getBankInfo = async () => {
        try {
            const response = await axios.post(apiRoutes.bankInfo, { sellerId: id })
            if (response.data?.data) setbankInfo(response.data.data)
        } catch (error) {

        } finally {
            setisLoading(false)
        }
    }



    return (

        <>
            <Descriptions column={1} className="mt-25">
                <Descriptions.Item label="Pancard No.">{data?.panCardNo}</Descriptions.Item>
                <Descriptions.Item label="GST TIN">{data?.gstNo}</Descriptions.Item>

            </Descriptions>

            {/* <Typography.Text strong className="mt-25">Bank Info</Typography.Text> */}

            {isLoading ? <>
                <Skeleton loading={isLoading} active={true} /></>
                : <Descriptions column={1} className="mt-25">
                    <Descriptions.Item label="Bank Name">{bankInfo?.bankName}</Descriptions.Item>
                    <Descriptions.Item label="Account Holder">{bankInfo?.name}</Descriptions.Item>
                    <Descriptions.Item label="Account Number">{bankInfo?.accountNo}</Descriptions.Item>
                    <Descriptions.Item label="IFSC Code">{bankInfo?.ifsc}</Descriptions.Item>
                </Descriptions>}
        </>
    )

}

export default BankInfo