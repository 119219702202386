import React, { useRef, useState, useEffect } from 'react'
import { EyeTwoTone } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';


import Main from '../../component/layoutnew/Main'
import { Space, Button, Table, Col, Card, Row, Typography, Input, Avatar } from 'antd'
import apiRoutes from '../../api/apiRoutes'
import { useNavigate, Link } from "react-router-dom";
import axios from '../../api/axios';





function SellerOnBoard() {
    const navigate = useNavigate()
    const [order, setorder] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [searchText, setSearchText] = useState('');
    const searchInput = useRef(null);
    const [searchedColumn, setSearchedColumn] = useState('');



    useEffect(() => {
        getAllUser()
    }, [])


    const getAllUser = async () => {
        try {
            const response = await axios.post(apiRoutes.pendingVendors)
            if (response?.data?.data) {
                setorder(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>


                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (

                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Typography.Text>
            ) : (
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {text}
                </Typography.Text>
            ),
    });




    const columns = [
        {
            title: '#',
            render: (_, record, index) => <Avatar size="small" style={{
                backgroundColor: '#454545',
            }}
            >{index + 1}</Avatar>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'id',
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.title}
                </Typography.Text>,
            ...getColumnSearchProps('name')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'id',
        },
        {
            title: 'Phone',
            dataIndex: 'mobileNo',
            key: 'id',
            render: (_, record) => `${record?.countryCode || ""} ${record?.mobileNo || ""} `
        },

        {
            title: 'store',
            dataIndex: 'store',
            key: 'id',
            render: (_, record) => record?.store?.name
        },
        {
            title: 'Joined',
            dataIndex: 'createdAt',
            key: 'id',
            render: (_, record) => new Date(record.createdAt).toLocaleDateString("en-US")
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <Button onClick={() => navigate(`/seller/verify/${record._id}`)} icon={<EyeTwoTone />} />
                </Space>
            ),
        }
    ];



    return (

        <Main page="Seller">
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title="Seller Request"
                            extra={<Button size="small" type="primary">
                                <Link to="/vendor/create">Create</Link>
                            </Button>}


                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey={'_id'} columns={columns} dataSource={order} pagination={true} search={true} loading={isLoading} size="small" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

        </Main>

    )
}

export default SellerOnBoard