import React from 'react'
import { BellOutlined, DownOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Divider, Badge, Avatar, Dropdown } from 'antd'
import styled from 'styled-components'
import ProfileMenu from './profileMenu'
import HamburgerMenu from './hamburgerMenu'
import AddNewItem from './addNewItem'

const { Header } = Layout


const DashboardHeader = () => {
  const userName = localStorage.getItem('userName')
  return (
    <StyledHeader >
      <StyledUserActions>
        <StyledSettingsMenu>
          <Badge count={10} dot={true}>
            <BellOutlined style={{ fontSize: 18, color: 'white' }} />
          </Badge>
        </StyledSettingsMenu>
        <Divider type="vertical" />
        <Dropdown overlay={<ProfileMenu />} trigger={['click']}>
          <StyledProfileMenu>
            <Avatar shape="circle" icon={<UserOutlined />} style={{ marginRight: 5 }} />
            {'   '}
            <StyledUserFullname>{userName}</StyledUserFullname>
            <DownOutlined style={{ marginLeft: 15 }} />
          </StyledProfileMenu>
        </Dropdown>
      </StyledUserActions>
    </StyledHeader>
  )
}

const StyledMenu = styled('span')`
  user-select: none;
  cursor: pointer;
`

const StyledSettingsMenu = styled(StyledMenu)``

const StyledProfileMenu = styled(StyledMenu)`
  background: rgb(0 21 41);
  height: 100%;
  display: inline-block;
  padding: 0 5px;
  color: #fff
`

const StyledUserFullname = styled('span')`
  margin-right: 5px;
`

const StyledHeader = styled(Header)`
  background: rgb(0 21 41);
  padding: 0;
  paddingleft: 10px;
  min-width: 470px;
  max-width: 100%;
  color: #fff
  @media only screen and (max-width: 470px) {
    display: none;
  }
`

const StyledUserActions = styled('div')`
  background: rgb(0 0 0 / 85%);
  margin-right: 10px;
  float: right;
  color: #fff
  @media only screen and (max-width: 320px) {
    background: #000;
    color: #fff;
  }

  @media only screen and (max-width: 780px) {
    display: none;
  }
`

export default DashboardHeader
