import React, { useContext, useState } from 'react'
import { Layout } from 'antd'
import SidebarMenuLogo from '../logo/sidebarlogo'
import AppFooter from '../footer'
import AppMenu from '../appMenu'
import DashboardHeader from './DadhboardHeader'
import Logo from '../../assets/img/logo.png'

import styled from 'styled-components'

const { Sider } = Layout



const DashboardPageLayout = ({ children, page }) => {

    const [collapsed, setCollapsed] = useState(false);




    return (
        <DashboardLayout>
            <Sider theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={220}>
                {!collapsed && (
                    <SidebarMenuLogo src={Logo} alt="expanded application logo" />
                )}
                {collapsed && (
                    <SidebarMenuLogo src={Logo} alt="collapsed application logo" />
                )}
                <AppMenu currentPage={page} collapsed={collapsed} />
            </Sider>
            <Layout>
                <DashboardHeader />
                {children}
                <AppFooter />
            </Layout>
        </DashboardLayout>
    )
}

const DashboardLayout = styled(Layout)`
  min-height: 100vh;
`

export default DashboardPageLayout
