
import React from 'react'

import { Form, Input, Switch, Button, Row, Col, Select, Upload, Tabs, Card, Spin, InputNumber, Space, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useNavigate, useParams } from 'react-router-dom'
import ProductVeriation from '../../component/product/productVeriation'
import Specifications from '../../component/product/specifications'
import Main from '../../component/layoutnew/Main'


function EditProduct() {
    const [product, setproduct] = React.useState({})
    const [isApproving, setisApproving] = React.useState(false)
    const [isRejecting, setisRejecting] = React.useState(false)
    const [showReject, setshowReject] = React.useState(false)


    const [isVeriableProduct, setisVeriableProduct] = React.useState(false)
    const [category, setcategory] = React.useState([])
    const [subCategory, setsubCategory] = React.useState([])
    const [brand, setBrand] = React.useState([])
    const [tax, setTax] = React.useState([])

    const [filterdSubCategory, setfilterdSubCategory] = React.useState([])
    const [isUploading, setisUploading] = React.useState(false)
    const [attributes, setattributes] = React.useState([])
    const [attributeItems, setattributeItems] = React.useState([])
    const [attributeCompoId, setattributeCompoId] = React.useState([])

    const navigate = useNavigate()
    let { id } = useParams();
    const [form] = Form.useForm();
    const [returnform] = Form.useForm();



    React.useEffect(() => {
        getProductData()
        getAllCategory()
        getAllSubCategory()
        getAllAttributes()
        getAllAttributeItems()
        getAllBrand()
        getTax()
    }, [])


    const getTax = async () => {
        try {
            const result = await axios.get(apiRoutes.getTax)
            if (result.data?.data) {
                setTax(result.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const approve = async () => {
        try {
            setisApproving(true)
            const response = await axios.post(apiRoutes.updateProductStatus, {
                productId: id,
                isApproved: true
            })
            if (response?.data?.success) {
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisApproving(false)
        }
    }

    const reject = async (data) => {
        try {
            setisRejecting(true)
            const response = await axios.post(apiRoutes.updateProductStatus, {
                productId: id,
                isRejected: true,
                rejectReason: data
            })
            if (response?.data?.success) {
                setshowReject(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisRejecting(false)
        }
    }



    const getProductData = async () => {
        try {
            const result = await axios.get(`${apiRoutes.editProduct}/${id}`)
            if (result?.data?.data?.product) {
                let product = result.data.data.product
                product.img = product.images.map((item, index) => {
                    return { url: item, uid: index }
                })
                setproduct(product)
                setisVeriableProduct(product?.isActive)
                form.resetFields();
            }
        } catch (error) {
            console.log(error)
        } finally {
            // setisUploading(false)
        }

    }


    const updateProduct = async (data) => {
        try {
            setisUploading(true)
            const result = await axios.post(apiRoutes.updateProduct, data)
            if (result?.data?.success) {
                return navigate('/product')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisUploading(false)
        }
    }


    const getAllSubCategory = async () => {
        try {
            const response = await axios.post(apiRoutes.getAllSubCategory)
            if (response?.data?.data) {
                setsubCategory(response.data.data)
                setfilterdSubCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllAttributes = async () => {
        try {
            const result = await axios.get(apiRoutes.attributeList)
            if (result?.data?.data) {
                setattributes(result.data.data)
            }
            // console.log(result.data)
        } catch (error) {
            console.error(error)

        }
    }

    const getAllBrand = async () => {
        try {
            const response = await axios.get(apiRoutes.allBrand)
            if (response?.data?.data) {
                setBrand(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }



    const getAllAttributeItems = async () => {
        try {
            const result = await axios.get(apiRoutes.attributeItemList)
            if (result?.data?.data) {
                setattributeItems(result.data.data)
            }
            // console.log(result.data)
        } catch (error) {
            console.error(error)

        }
    }


    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setcategory(response.data.data)
            }
        } catch (error) {
            console.error(error)

        }
    }

    const filterSubCategory = async (data) => {
        const filteredData = subCategory.filter(item => item.category === data)
        setfilterdSubCategory(filteredData)
        // console.log(data)
    }




    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('name', values.name)
        formData.append('tax', values.tax)
        formData.append('brand', values.brand)
        formData.append('category', values.category)
        formData.append('subCategory', values.subCategory)
        formData.append('tags', values.tags)
        formData.append('shortDescription', values.shortDescription)
        formData.append('description', values.description)
        formData.append('returnPolicy', values.returnPolicy)
        formData.append('isCod', values?.isCod || false)
        formData.append('deliveryCharge', values.deliveryCharge)
        product?.productGroup?._id && (formData.append('productGroupId', product?.productGroup?._id))
        formData.append('productId', product.id)
        formData.append('isActive', isVeriableProduct ? true : false)
        formData.append('productrel', JSON.stringify({
            isDealoftheDay: values.isDealoftheDay,
            isSponsored: values.isSponsored,
        }))
        values?.price && (formData.append('price', values.price))
        values?.stock && (formData.append('stock', values.stock))
        values?.mrp && (formData.append('mrp', values.mrp))

        values.compinations && (formData.append('compinations', JSON.stringify(values.compinations)))
        attributeCompoId.length !== 0 && (formData.append('veriations', JSON.stringify(attributeCompoId)))
        // formData.append('files',fileData)
        values?.files?.fileList.forEach(item => {
            if (item?.originFileObj) return formData.append('files', item.originFileObj)
            if (item?.url) return formData.append('images', item.url)
        })
        // console.log(formData)
        updateProduct(formData)
    }

    return (

        <Main page='product'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="header-solid"
                            title={'Edit Product'}
                            extra={product?.status?.isPending && <Space>
                                <Button type='primary' loading={isApproving} onClick={approve}>Approve</Button>
                                <Button type='danger' loading={isRejecting} onClick={() => setshowReject(true)}> Reject</Button>

                            </Space>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {Object.keys(product).length !== 0 ? <Row >
                                    <Col span={24}>
                                        <Tabs defaultActiveKey="1" tabPosition="right">
                                            <Tabs.TabPane tab="Product Details" key="1">
                                                <Form
                                                    name="basic"
                                                    labelCol={{
                                                        span: 0,
                                                    }}
                                                    wrapperCol={{
                                                        span: 0,
                                                    }}
                                                    initialValues={product}
                                                    onFinish={onFinish}
                                                    autoComplete="off"
                                                    layout="vertical"
                                                    form={form}
                                                >




                                                    <Form.Item
                                                        label="Product Name"
                                                        name="name"
                                                        //initialValue={product?.name}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Product Name required',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                    {/* ant-col ant-col-24 ant-col-offset-22 ant-form-item-control */}

                                                    <Form.Item
                                                        label="short Description"
                                                        name="shortDescription"
                                                        initialValue={product?.productGroup?.shortDescription}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Short Description required',
                                                            },
                                                        ]}
                                                    >
                                                        <Input.TextArea rows={4} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Description"
                                                        name="description"
                                                        initialValue={product?.productGroup?.description}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Description required',
                                                            }
                                                        ]}
                                                    >
                                                        <Input.TextArea rows={6} />
                                                    </Form.Item>


                                                    <Row gutter={24}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                initialValue={product?.productGroup?.category}
                                                                name="category"
                                                                label="Category"
                                                                hasFeedback
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Category required',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select onChange={filterSubCategory}>
                                                                    {category.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                name="subCategory"
                                                                label="Sub Category"
                                                                initialValue={product?.productGroup?.subCategory}
                                                                hasFeedback
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'SubCategory required',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select onChange={() => { }}>
                                                                    {filterdSubCategory.map(item => <Select.Option key={item.id} value={item.id} >{item.title}</Select.Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    {product?.productGroup?.isSimpleProduct && <Row gutter={24}>
                                                        <Col span={8}>
                                                            <Form.Item
                                                                label="MRP"
                                                                name="mrp"
                                                                initialValue={product?.mrp}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'MRP required',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber className="w-100" />
                                                            </Form.Item></Col>
                                                        <Col span={8}><Form.Item
                                                            label="Selling Price"
                                                            name="price"
                                                            initialValue={product?.price}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Price required',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber className="w-100" />
                                                        </Form.Item></Col>
                                                        <Col span={8}><Form.Item
                                                            label="Stock"
                                                            name="stock"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Stock required',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber className="w-100" />
                                                        </Form.Item></Col>
                                                    </Row>}


                                                    <Row gutter={24}>

                                                        <Col span={8}>
                                                            <Form.Item
                                                                name="tax"
                                                                label="Tax"
                                                                size="large"
                                                                initialValue={product?.tax}
                                                                hasFeedback
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Tax required',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select>
                                                                    {tax.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={8}>
                                                            <Form.Item
                                                                name="brand"
                                                                label="Brand"
                                                                size="large"
                                                                initialValue={product?.productGroup?.brand}
                                                                hasFeedback
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Brand required',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select>
                                                                    {brand.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={8}>
                                                            <Form.Item
                                                                name="tags"
                                                                label="Tags"
                                                                initialValue={product?.productGroup?.tags}
                                                                hasFeedback
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Tags required',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    mode="tags"
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    placeholder="Enter atleast one tag"
                                                                >
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={24}>
                                                        <Col span={10}>
                                                            <Form.Item
                                                                name="returnPolicy"
                                                                label="Days of to be return"
                                                                initialValue={product?.productGroup?.returnPolicy}
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Return Policy required',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber className="w-100" />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={10}>
                                                            <Form.Item
                                                                name="deliveryCharge"
                                                                label="Delivery Charge"
                                                                initialValue={product?.productGroup?.deliveryCharge}
                                                                style={{ width: '100%' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'This field is required',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber className="w-100" />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Form.Item
                                                                name="isCod"
                                                                label="Cash on Delivery"
                                                                initialValue={product?.productGroup?.isCod}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <Switch defaultChecked={product?.productGroup?.isCod} />
                                                            </Form.Item>
                                                        </Col>

                                                    </Row>



                                                    {product?.productGroup?.isSimpleProduct &&
                                                        <Form.Item label="Images" name="files">
                                                            <Upload listType="picture-card" action={"image/*"}
                                                                defaultFileList={product.img}
                                                            >
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        Images
                                                                    </div>
                                                                </div>
                                                            </Upload>
                                                        </Form.Item>}

                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                layout="veriticle"
                                                                name="isDealoftheDay"
                                                                label="Deal of the Day"
                                                                labelAlign="right"
                                                                initialValue={product?.productrel?.isDealoftheDay ? true : false}
                                                            >
                                                                <Switch size="small" defaultChecked={product?.productrel?.isDealoftheDay} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={12}>
                                                            <Form.Item
                                                                layout="veriticle"
                                                                name="isSponsored"
                                                                label="Sponcered"
                                                                labelAlign="right"
                                                                initialValue={product?.productrel?.isSponsored ? true : false}
                                                            >
                                                                <Switch size="small" defaultChecked={product?.productrel?.isSponsored} />
                                                            </Form.Item></Col>
                                                    </Row>




                                                    <Form.Item
                                                        layout="horizontal"
                                                        label="Published"
                                                        labelAlign="right"
                                                    >
                                                        <Switch size="small" defaultChecked={product?.isActive} onChange={() => setisVeriableProduct(!isVeriableProduct)} />
                                                    </Form.Item>


                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" style={{ float: 'right', marginTop: 40 }} loading={isUploading}>
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </Form>

                                            </Tabs.TabPane>
                                            {!product?.productGroup?.isSimpleProduct && <Tabs.TabPane tab="Veriations" key="2">
                                                <ProductVeriation id={product?.productGroup?._id} tax={tax} />


                                            </Tabs.TabPane>}

                                            <Tabs.TabPane tab="Specifications" key="3">
                                                <Specifications productId={product?.id} category={product?.productGroup?.category} subCategory={product?.productGroup?.subCategory} />
                                            </Tabs.TabPane>
                                        </Tabs>

                                    </Col>
                                    {/* <Col span={6} style={{paddingLeft:10}}>
                            <PageContent title='Create Product' titleDivider={false}>
                            </PageContent>
                        </Col> */}
                                </Row> :
                                    <Row type="flex" justify="center" align="middle" style={{ minHeight: '59vh' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><Spin /></div>
                                    </Row>}

                            </div>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    title="Reject"
                    open={showReject}
                    onOk={() => returnform.submit()}
                    confirmLoading={isRejecting}
                    onCancel={() => setshowReject(false)}
                >
                    <Form
                        layout="vertical"
                        name="basic"
                        form={returnform}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={(value) => reject(value.reason)}
                        onFinishFailed={() => { }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Rejection Reason"
                            name="reason"
                            rules={[
                                {
                                    required: true,
                                    message: 'Reason required',
                                }
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>





                    </Form>
                </Modal>
            </div>
        </Main >



    )
}

export default EditProduct