import { useState, useEffect } from 'react'
import { Card, Table, Button, Avatar } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


import apiRoutes from '../../../api/apiRoutes'
import axios from '../../../api/axios'
import Typography from 'antd/es/typography/Typography'

function LowStock() {
    const navigate = useNavigate()

    const [product, setproduct] = useState([])
    const [isLoading, setisLoading] = useState(true)



    useEffect(() => {
        getAllProduct()
    }, [])



    const getAllProduct = async () => {
        try {


            const response = await axios.post(apiRoutes.getLowStock, { limit: true })
            if (response?.data?.data) {
                setproduct(response.data.data)
            }
        } catch (error) {
        } finally {
            setisLoading(false)

        }
    }



    const columns = [
        {
            title: '#',
            dataIndex: 'price',
            key: 'price',
            render: (_, record, index) => index + 1

        },
        {
            title: 'Image',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => (<Avatar src={record.images[0]} />)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',

        },
        {
            title: 'Action',
            dataIndex: 'rating',
            key: 'rating',
            render: (_, record) => <Button type="link" onClick={() => navigate(`/products/${record.id}`)} icon={<EyeOutlined  />}></Button>
        },



    ];


    return (

        <div>
            <Card title="Low stock product" className="header-solid" extra={<Link to="/inventory/lowstock"><Typography.Link>View more</Typography.Link></Link>}>
                <Table style={{minHeight:200}}  rowKey="id" loading={isLoading} columns={columns} dataSource={product} pagination={false} search={true} size="small" bordered={false} />
            </Card>
        </div>
    )
}

export default LowStock