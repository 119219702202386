import React, { useState, useEffect } from 'react'

import { TableOutlined, ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Space, Button, Table, Card, Col, Row, Typography, Badge, Drawer, Divider, Descriptions } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import Main from '../../component/layoutnew/Main';
import { motion } from 'framer-motion'
import { SETTLEMENT_STATUS } from '../../utils/constants/revenue';




function Settlement() {
    const [plans, setplans] = useState([])
    const [showMoreInfo, setshowMoreInfo] = useState({})
    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getRevenue()
    }, [])






    const getRevenue = async () => {
        try {
            setisLoading(true)
            const response = await axios.post(apiRoutes.settlements)
            if (response?.data?.data) {
                setplans(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }



    const columns = [
        {
            title: '#',
            key: "createdAt",
            render: (_, record, index) => index + 1
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Seller',
            key: "seller",
            render: (_, record) => record?.seller?.name
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => <Typography.Text>₹ {record?.amount}</Typography.Text>
        },
        {
            title: 'Transcation Id',
            dataIndex: 'paymentRef',
            key: 'paymentRef',
        },
        {
            title: 'Paid on',
            dataIndex: 'paidOn',
            key: 'paidOn',
            render: (_, record) => {
                var date = new Date(record?.paidOn)
                return date.toLocaleDateString("en-GB")
            }
        },

        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => <Badge status={SETTLEMENT_STATUS[record?.status].status} text={SETTLEMENT_STATUS[record?.status].title} />,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => <Button onClick={() => setshowMoreInfo(record)} type="link">More info</Button>
        },
    ];




    return (
        <Main page='Revenue'>
            <motion.div className="tabled" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title="Settlements"
                            extra={<Space>
                                <Button onClick={getRevenue} icon={<ReloadOutlined />} size="middle" />
                                <Button icon={<TableOutlined />} size="middle" />
                                <Button icon={<DownloadOutlined />} size="middle" /></Space>}                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey="id" bordered={false} size='small' columns={columns} dataSource={plans} pagination={true} search={true} loading={isLoading} style={{ margin: 0 }} />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Drawer title="Settlement info" width={'30%'} placement="right" onClose={() => setshowMoreInfo({})} open={Object.keys(showMoreInfo).length}>

                    <Descriptions title="Transaction info" column={1}>
                        <Descriptions.Item label="Transaction id">{showMoreInfo?.paymentRef}</Descriptions.Item>
                        <Descriptions.Item label="Paid on">{new Date(showMoreInfo?.paidOn).toLocaleDateString("en-GB")}</Descriptions.Item>
                        <Descriptions.Item label="Payment status"><Badge text="Success" status="success" /></Descriptions.Item>
                    </Descriptions>
                    <Divider />

                    <Descriptions title="Bank Info" column={1}>
                        <Descriptions.Item label="Customer name">{showMoreInfo?.bankInfo?.name}</Descriptions.Item>
                        <Descriptions.Item label="Bank name">{showMoreInfo?.bankInfo?.bankName}</Descriptions.Item>
                        <Descriptions.Item label="Account NO">{showMoreInfo?.bankInfo?.accountNo}</Descriptions.Item>
                        <Descriptions.Item label="IFSC">{showMoreInfo?.bankInfo?.ifsc}</Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions title="Other Info" column={1}>
                        <Descriptions.Item label="Request id">{showMoreInfo?.request}</Descriptions.Item>
                        <Descriptions.Item label="Seller">{showMoreInfo?.seller?.name}</Descriptions.Item>
                    </Descriptions>


                </Drawer>
            </motion.div>
        </Main>
    )
}

export default Settlement