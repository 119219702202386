import { Outlet, Navigate } from 'react-router-dom'
import React from 'react'

const PrivateRoutes = () => {

    // const navigate = useNavigate()
    const [isAuth, setisAuth] = React.useState(localStorage.getItem('token') ? true : false)
    return(
        isAuth ? <Outlet/> : <Navigate to="/login" replace="true" />
    )
}

export default PrivateRoutes