import Dashboard from '../pages/dashboard'
import Category from '../pages/category/category'
import SubCategory from '../pages/category/subCategory';
import CreateProduct from '../pages/product/productCreate';
import User from '../pages/user/user'
import Product from '../pages/product/products';
import Banner from '../pages/category/banner';
import Order from '../pages/order/order'
import Attributes from '../pages/attribute/attributes';
import OrderDetails from '../pages/order/orderDetails';
import OrderItemDetails from '../pages/order/orderItemDetails'
import Test from '../pages/test'
import PrivateRoutes from '../utils/authController'
import React from 'react';
import Login from '../pages/auth/login';
import EditProduct from '../pages/product/productEdit';
import UserProfile from '../pages/user/userProfile';
import AttributeItems from '../pages/attribute/attributesItem';
import SpecificationAttributes from '../pages/attribute/specificationAttributes';
import SpecificationGroup from '../pages/attribute/specificationGroup';
import SpecificationGroupItem from '../pages/attribute/groupItem';
import PromoCode from '../pages/ecom/promocode';
import Message from '../pages/message';
import Review from '../pages/ecom/review'
import DealOfTheDay from '../pages/ecom/dealoftheday';
import Sponsored from '../pages/ecom/sponsored'
import Offers from '../pages/ecom/offers'
import OrderItems from '../pages/order/orderItems'
import OrderReturned from '../pages/order/orderreturned'
import ForNotFor from '../pages/404';
import Brand from '../pages/category/brand'
import Settings from '../pages/ecom/settings'
import Vendor from '../pages/user/vendor'
import VendorCreate from '../pages/user/vendorCreate'
import VendorProduct from '../pages/product/pendingproducts'
import VendorProfile from '../pages/user/vendorProfile'
import Tax from '../pages/ecom/tax'
import SellerOnBoard from '../pages/user/onboardseller'
import VendorVerify from '../pages/user/vendorVerify';
import SellerBanner from '../pages/category/sellerbanner';
import Plan from '../pages/ecom/plan';
import SellerRevenue from '../pages/settlement/sellerRevenue';
import SettlementRequest from '../pages/settlement/settlementRequest';
import RequestInfo from '../pages/settlement/requestInfo'
import Settlements from '../pages/settlement/settlement';
import Inventory from '../pages/product/inventory';
import LowStock from '../pages/product/lowStock';
import OutOfStock from '../pages/product/outOfStock';

import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";




function PageRoutes() {
    const location = useLocation()
    const isAuth = localStorage.getItem('token') ? true : false

    return (
        // <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route element={<PrivateRoutes />}>
                <Route element={<Dashboard />} path="/" />
                <Route element={<Product />} path="product" />
                <Route element={<Test />} path="test" />
                <Route element={<Banner />} path="banner" />
                <Route element={<Plan />} path="plans" />
                <Route element={<SellerBanner />} path="banner/seller" />
                <Route element={<Category />} path="category" />
                <Route element={<SubCategory />} path="subcategory" />
                <Route element={<Order />} path="order" />
                <Route element={<OrderDetails />} path="order/:id" />
                <Route element={<OrderItemDetails />} path="orderitem/:id" />
                <Route element={<User />} path="user" />
                <Route element={<OrderItems />} path="orderitems" />
                <Route element={<CreateProduct />} path="product/create" />
                <Route element={<EditProduct />} path="product/edit/:id" />
                <Route element={<Attributes />} path="attributes" />
                <Route element={<AttributeItems />} path="attributeitems" />
                <Route element={<UserProfile />} path="user/:id" />
                <Route element={<VendorProfile />} path="vendor/:id" />
                <Route element={<SpecificationAttributes />} path="specificationattributes" />
                <Route element={<SpecificationGroup />} path="specificationgroup" />
                <Route element={<SpecificationGroupItem />} path="specificationgroup/:id" />
                <Route element={<PromoCode />} path="promocode" />
                <Route element={<Message />} path="message" />
                <Route element={<Review />} path="review" />
                <Route element={<DealOfTheDay />} path="deal-of-the-day" />
                <Route element={<Sponsored />} path="sponsored" />
                <Route element={<Offers />} path="offers" />
                <Route element={<OrderReturned />} path="order/return" />
                <Route element={<ForNotFor />} path="*" />
                <Route element={<Brand />} path="brand" />
                <Route element={<Settings />} path="settings" />
                <Route element={<Vendor />} path="seller" />
                <Route element={<VendorCreate />} path="vendor/create" />
                <Route element={<VendorProduct />} path="product/vendor" />
                <Route element={<Tax />} path="tax" />
                <Route element={<SellerOnBoard />} path="seller/pending" />
                <Route element={<VendorVerify />} path="seller/verify/:id" />
                <Route element={<SellerRevenue />} path="seller/revenue" />
                <Route element={<SettlementRequest />} path="settlement/request" />
                <Route element={<RequestInfo />} path="settlement/request/:id" />
                <Route element={<Settlements />} path="settlement" />
                <Route element={<Inventory/>} path="inventory" />
                <Route element={<LowStock/>} path="inventory/lowstock" />
                <Route element={<OutOfStock/>} path="inventory/outofstock" />


            </Route>
            <Route path="login" element={isAuth ? <Dashboard /> : <Login />} />
        </Routes>
        // </AnimatePresence>
    )
}

export default PageRoutes