import { useState, useEffect } from 'react'
import Main from '../../component/layoutnew/Main'
import { Col, Card, Descriptions, Button, Row, Drawer, List, Modal, Typography, Form, Input, InputNumber, Tag, Badge } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'

function RequestInfo() {

    const [isLoading, setisLoading] = useState(true)
    const [requestInfo, setrequestInfo] = useState({})
    const [showTransaction, setshowTransaction] = useState(false)
    const [isApprove, setisApprove] = useState(false)

    let { id } = useParams();
    const [form] = Form.useForm();



    useEffect(() => {
        getStoreInfo()
    }, [])




    const getStoreInfo = async (req, res) => {
        try {
            const response = await axios.post(apiRoutes.settlementRequestInfo, { id })
            if (response?.data?.data) setrequestInfo(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const approve = values => createSettlement({ ...values, request: requestInfo?.request?.id })


    const createSettlement = async (data) => {
        try {
            const response = await axios.post(apiRoutes.settlementCreate, data)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <Main page='Revenue'>
            <Col span={24}>
                <Card loading={isLoading} title="Store Withdraw Information" extra={<Button onClick={() => setshowTransaction(true)} type="link">Show Breakup</Button>}>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <Descriptions column={2}>
                            <Descriptions.Item label="Amount">{requestInfo?.request?.amount}</Descriptions.Item>
                            <Descriptions.Item label="note"></Descriptions.Item>
                            <Descriptions.Item label="Requested">{requestInfo?.request?.createdAt}</Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <Badge
                                    status={requestInfo?.request?.status === 1 ? "processing" : requestInfo?.request?.status === 3 ? 'success' : 'error'}
                                    text={requestInfo?.request?.status === 1 ? "Pending" : requestInfo?.request?.status === 3 ? 'Completed' : 'Cancel'} />

                            </Descriptions.Item>

                        </Descriptions>
                        {requestInfo?.request?.status === 1 && <Button type="primary" onClick={() => setisApprove(true)} icon={<ArrowRightOutlined />}>Proceed</Button>}
                    </div>
                </Card>
            </Col>
            <Row gutter={[24, 0]} className="mt-25">
                <Col span={8}>
                    <Card loading={isLoading} title="Bank Information" bordered={true}>
                        <Descriptions column={1}>
                            <Descriptions.Item label="Account holder">{requestInfo?.request?.bankInfo?.name}</Descriptions.Item>
                            <Descriptions.Item label="Bank name">{requestInfo?.request?.bankInfo?.bankName}</Descriptions.Item>
                            <Descriptions.Item label="Account no">{requestInfo?.request?.bankInfo?.accountNo}</Descriptions.Item>
                            <Descriptions.Item label="IFSC">{requestInfo?.request?.bankInfo?.ifsc}</Descriptions.Item>

                        </Descriptions>

                    </Card>
                </Col>

                <Col span={8}>
                    <Card loading={isLoading} title="Store Information">
                        <Descriptions column={1}>
                            <Descriptions.Item label="Store">{requestInfo?.store?.name}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{requestInfo?.store?.phone}</Descriptions.Item>
                            <Descriptions.Item label="Address">{requestInfo?.store?.address}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>


                <Col span={8}>
                    <Card loading={isLoading} title="Owner Information">
                        <Descriptions column={1}>
                            <Descriptions.Item label="Name">{requestInfo?.store?.user?.name}</Descriptions.Item>
                            <Descriptions.Item label="Email">{requestInfo?.store?.user?.email}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{requestInfo?.store?.user?.phone}</Descriptions.Item>

                        </Descriptions>
                    </Card>
                </Col>
            </Row>
            <Modal title="Approve Settlement" okText={"Approve"} open={isApprove} onOk={() => form.submit()} onCancel={() => setisApprove(false)}>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={approve}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Amount Paid"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input amount!',
                            },
                        ]}
                    >
                        <InputNumber className="w-100" />
                    </Form.Item>

                    <Form.Item
                        label="Transaction Id"
                        name="paymentRef"
                        rules={[
                            {
                                required: true,
                                message: 'Please input transaction id!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Modal>
            <Drawer title="Transactions" width={'50%'} placement="right" onClose={() => setshowTransaction(false)} open={showTransaction}>
                <List
                    bordered={true}
                    itemLayout="horizontal"
                    dataSource={requestInfo?.request?.transactions}
                    renderItem={(item, index) => (
                        <List.Item actions={[<Typography.Text type="success" strong>+ 245</Typography.Text>]}>

                            <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                                <Typography.Text type="secondary" strong>06/12/1996</Typography.Text>
                                <Typography.Text>Sale</Typography.Text>
                                <Typography.Link >#78354343</Typography.Link>

                            </div>
                        </List.Item>
                    )}
                />
            </Drawer>
        </Main>
    )
}

export default RequestInfo