import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    DashboardOutlined,
    UnorderedListOutlined,
    InsertRowAboveOutlined,
    AppstoreOutlined,
    UserOutlined,
    CodeSandboxOutlined,
    ShoppingCartOutlined,
    GoldOutlined
} from '@ant-design/icons'

import { Menu, Layout } from 'antd'




const AppMenu = ({ currentPage, collapsed }) => {
    let navigate = useNavigate();

    const onMenuClick = (event) => {
        const { key } = event
        navigate(`/${key}`, { replace: true })


    }


    const menuItems = [
        {
            key: '',
            icon: <DashboardOutlined />,
            label: 'Dashboard',
        },
        {
            key: 'banner',
            icon: <InsertRowAboveOutlined />,
            label: 'Banner'
        },
        {
            key: 'category',
            icon: <AppstoreOutlined />,
            label: 'Category'
        },
        {
            key: 'subcategory',
            icon: <UnorderedListOutlined />,
            label: 'Subcategory'
        },
        {
            key: 'attributes',
            icon: <GoldOutlined />,
            label: 'Attributes'
        },
        {
            key: 'attributeitems',
            icon: <GoldOutlined />,
            label: 'Attributeitems'
        },
        {
            key: 'specificationattributes',
            icon: <GoldOutlined />,
            label: 'Specification'
        },
        {
            key: 'specificationgroup',
            icon: <GoldOutlined />,
            label: 'Specification Group'
        },
        {
            key: 'product',
            icon: <CodeSandboxOutlined />,
            label: 'Product'
        },
        {
            key: 'order',
            icon: <ShoppingCartOutlined />,
            label: 'Order'
        },
        {
            key: 'promocode',
            icon: <ShoppingCartOutlined />,
            label: 'Promocode'
        },
        {
            key: 'user',
            icon: <UserOutlined />,
            label: 'User'
        }
    ]


    return (
        <Layout.Sider collapsed={collapsed} width="220" style={{
            backgroundColor: "white",
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 50,
            bottom: 0,
        }}>
            <Menu theme="light" selectedKeys={[currentPage || 'dashboard']} onClick={onMenuClick} items={menuItems} mode="inline" />
        </Layout.Sider>
    )
}

export default AppMenu
