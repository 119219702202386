
import React from 'react'

import Main from '../../component/layoutnew/Main'
import { Col, Row, Card, Menu, Skeleton, notification, Descriptions, Button, Space } from 'antd'

import { UserOutlined, ShopOutlined, BankOutlined, FileDoneOutlined } from '@ant-design/icons';
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useParams, useNavigate } from 'react-router-dom'
import BankInfo from '../../component/profile/seller/verification/bankinfo';
import BusinessInfo from '../../component/profile/seller/verification/businessInfo'
import Documents from '../../component/profile/seller/verification/document'



function VendorVerify() {
    let { id } = useParams();
    const navigate = useNavigate();

    const [currentMenu, setcurrentMenu] = React.useState('userInfo')
    const [userInfo, setuserInfo] = React.useState({})
    const [bsInfo, setbsInfo] = React.useState({})

    const [isLoading, setisLoading] = React.useState(true)

    const [selectedItem, setselectedItem] = React.useState('1')

    const [isUpdating, setisUpdating] = React.useState(null)
    const [isChecked, setisChecked] = React.useState(userInfo?.isActive)



    React.useEffect(() => {
        getUserInfo()
    }, [])


    const getUserInfo = async () => {
        try {
            const response = await axios.post(apiRoutes.getVendorInfo, { sellerId: id })
            response?.data?.data?.user && setuserInfo(response?.data?.data?.user)
            response?.data?.data?.businessInfo && setbsInfo(response?.data?.data?.businessInfo)
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const changeStatus = status => {
        setisUpdating(status)
        updateStatus(status)
    }

    const openNotificationWithIcon = (type) => {
        notification[type]({
            message: 'Something Wrong',
            description:
                'Failed to update ,Try again later',
        });
    };

    const updateStatus = async (status) => {
        try {
            const data = { sellerId: id, status }
            const response = await axios.post(apiRoutes.sellerStatus, data)
            if (response?.data?.success) {
                navigate("/seller/pending")
            }
            setisUpdating(null)
        } catch (error) {
            setisUpdating(null)
            openNotificationWithIcon('warning')

        }
    }

    const menuItems = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: 'Seller info',
            onClick: () => setcurrentMenu("userInfo")
        },
        {
            key: '5',
            icon: <ShopOutlined />,
            label: 'Business info',
            onClick: () => setcurrentMenu("storeInfo")
        },
        {
            key: '6',
            icon: <BankOutlined />,
            label: 'Bank Details',
            onClick: () => setcurrentMenu("bankinfo")
        },
        {
            key: '7',
            icon: <FileDoneOutlined />,
            label: 'Documents',
            onClick: () => setcurrentMenu("otherinfo")
        }
    ]

    return (


        <Main page='vendor'>
            <div className="tabled">
                <Row gutter={24}>

                    <Col span={24} >
                        <Card title="Seller informations" bordered={false} className="header-solid mb-24" extra={
                            <Space>
                                <Button loading={isUpdating === 1} disabled={isUpdating === 0} onClick={() => changeStatus(1)} type="primary">Accept</Button>
                                <Button loading={isUpdating === 0} disabled={isUpdating === 1} onClick={() => changeStatus(0)}>Reject</Button></Space>}>

                            <Menu onClick={(e) => setselectedItem(e.key)} selectedKeys={[selectedItem]} mode="horizontal" items={menuItems} />

                            {currentMenu === 'userInfo' &&
                                <div className="mt-25">
                                    {isLoading ?
                                        <Skeleton loading={isLoading} active={true} />
                                        : <Descriptions column={2}>
                                            <Descriptions.Item label="Name" labelStyle={{ fontColor: 'grey' }}>{userInfo?.name}</Descriptions.Item>
                                            {userInfo?.userName && <Descriptions.Item label="User Name">{userInfo?.userName}</Descriptions.Item>}
                                            <Descriptions.Item label="Email">{userInfo?.email}</Descriptions.Item>
                                            {userInfo?.mobileNo && <Descriptions.Item label="Phone">{`${userInfo?.countryCode} ${userInfo?.mobileNo}`}</Descriptions.Item>}
                                        </Descriptions>}
                                </div>}


                            {currentMenu === 'storeInfo' && <BusinessInfo data={bsInfo} />}

                            {currentMenu === 'bankinfo' &&
                                <div className="mt-25">
                                    {isLoading ?
                                        <Skeleton loading={isLoading} active={true} />
                                        :
                                        <BankInfo data={bsInfo} id={id} />}
                                </div>}

                            {currentMenu === 'otherinfo' &&
                                <div className="mt-25">
                                    {isLoading ?
                                        <Skeleton loading={isLoading} active={true} />
                                        :
                                        <Documents data={bsInfo} />}
                                </div>}


                        </Card>

                    </Col>
                </Row>
            </div>

        </Main >
    )
}

export default VendorVerify