import Chart from "react-apexcharts";
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useState,useEffect } from "react";
import { Skeleton } from "antd";


const InventorySummary = () => {
    const [isLoading, setisLoading] = useState(true)
    const [countData, setcountData] = useState({})


    useEffect(() => {
        getCounts()
    }, [])


    const getCounts = async () => {
        try {
            const response = await axios.post(apiRoutes.getInventoryCount)
            const { data } = response?.data
            data && setcountData(data)
            console.log(data)
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }



    const options = {
        series: [countData?.total?.value - countData?.outOfStock?.value - countData?.lowStock?.value || 0, countData?.outOfStock?.value || 0, countData?.lowStock?.value || 0],
        fill: {
            colors: ['#F44336', '#E91E63', '#9C27B0']
        },
        options: {
            colors: ['rgb(0, 227, 150)', '#FFCC00', '#E91E63'],
            labels: ['In Stock', 'Low stock', 'Out of stock'],
            chart: {
                id: "basic-bar"
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                value: countData?.total?.value,
                            }
                        }
                    }
                }
            }

        }
    }



    return (

        <div style={{ minWidth: '100%', minHeight: '100%', margin: 0, padding: 0 }} >
            {!isLoading ? <Chart
                options={options.options}
                series={options.series}
                type='donut'
                height={300}
                width="100%"
            /> : <Skeleton active={true} />}
        </div>
    )
}





export default InventorySummary