const staticUrl = "http://localhost:7000"

const url = false ? staticUrl : ""


const slug = {
    appVersion: 'app-version',
}

const api = {
    url: url,
    path: "/admin/"
}

module.exports = {
    slug,
    api,
    staticUrl
}