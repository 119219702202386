import React, { useState, useEffect } from 'react'

import { TableOutlined, ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Space, Button, Table, Card, Col, Row, Typography, Badge } from 'antd'

import apiRoutes from '../../api/apiRoutes'
import axios from '../../api/axios';
import Main from '../../component/layoutnew/Main';
import { motion } from 'framer-motion'
import { SETTLEMENT_STATUS } from '../../utils/constants/revenue';




function SellerRevenue() {
    const [plans, setplans] = useState([])
    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getRevenue()
    }, [])






    const getRevenue = async () => {
        try {
            setisLoading(true)
            const response = await axios.post(apiRoutes.allRevenue)
            if (response?.data?.data) {
                setplans(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }



    const columns = [
        {
            title: 'Seller',
            dataIndex: 'seller',
            key: 'seller',
            render: (_, record) => record?.seller?.name
        },
        {
            title: 'Order item ID',
            dataIndex: 'orderItemId',
            key: 'orderItemId',
        },
        {
            title: 'Sale Amount',
            dataIndex: 'saleAmount',
            key: 'saleAmount',
            render: (_, record) => <Typography.Text type="success">₹ {record?.saleAmount}</Typography.Text>
        },
        {
            title: 'Refund Amount',
            dataIndex: 'refundAmount',
            key: 'refundAmount',
            render: (_, record) => <Typography.Text type={parseInt(record?.refundAmount) === 0 ? "success" : "danger"}>₹ {record?.refundAmount}</Typography.Text>
        },
        {
            title: 'Platform Fee',
            dataIndex: 'platformCommissions',
            key: 'platformCommissions',
            render: (_, record) => <Typography.Text type={parseInt(record?.platformCommission) === 0 ? "success" : "danger"}>₹ {record?.platformCommission}</Typography.Text>
        },
        {
            title: 'Settlement Amount',
            dataIndex: 'productCount',
            key: 'id',
            // render:(_,record) => <Typography.Text type={parseInt(record?.platformCommissions) === 0 ? "success" : "danger"}> {record?.platformCommissions}</Typography.Text>
            render: (_, record) => {
                const settleAmount = parseInt(record?.saleAmount || 0) - parseInt(record?.platformCommission || 0) - parseInt(record?.refundAmount || 0)
                return <Typography.Text type={parseInt(settleAmount) === 0 ? "danger" : "success"}>₹ {settleAmount}</Typography.Text>
            }
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (_, record) => {
                var date = new Date(record?.dueDate)
                return date.toLocaleDateString("en-US")
            }
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',

        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => <Badge status={SETTLEMENT_STATUS[record?.status].status} text={SETTLEMENT_STATUS[record?.status].title} />,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        }
    ];




    return (
        <Main page='Revenue'>
            <motion.div className="tabled" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title="Revenue"
                            extra={<Space>
                                <Button onClick={getRevenue} icon={<ReloadOutlined />} size="middle" />
                                <Button icon={<TableOutlined />} size="middle" />
                                <Button icon={<DownloadOutlined />} size="middle" /></Space>}                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey="id" bordered={false} size='small' columns={columns} dataSource={plans} pagination={true} search={true} loading={isLoading} style={{ margin: 0 }} />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </Main>
    )
}

export default SellerRevenue