import { Descriptions, Checkbox, Form, Input, Col } from 'antd';



const BankInfo = () => {



    return (

        <Descriptions column={1} >
            <Descriptions.Item label="Bank Name">Federal Bank</Descriptions.Item>
            <Descriptions.Item label="Account Holder">Fazil Cm</Descriptions.Item>
            <Descriptions.Item label="Account Number">2273283232732932</Descriptions.Item>
            <Descriptions.Item label="IFSC Code">FDRL0002292</Descriptions.Item>
        </Descriptions>
    )

}

export default BankInfo