import React from 'react'

import { Popconfirm, Table, Space, Button, Tag, Col, Card, Row } from 'antd'

import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { CloseCircleOutlined, CheckCircleOutlined, EditTwoTone, DeleteTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import PromocodeCreate from '../../component/pageComponents/promocodeCreate'
import PromocodeEdit from '../../component/pageComponents/promocodeEdit'
import Main from '../../component/layoutnew/Main'

function PromoCode() {

    const [promocodes, setPromocodes] = React.useState([])
    const [isCreate, setIsCreate] = React.useState(false)
    const [isClosed, setisClosed] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState({})
    const [trash, setTrash] = React.useState("")
    const [isDeleting, setisDeleting] = React.useState(false)

    const [isLoading, setisLoading] = React.useState(true)


    React.useEffect(() => {
        getAllPromocodes()
    }, [isClosed])


    const getAllPromocodes = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllPromocodes)
            if (response?.data?.data) {
                setPromocodes(response.data.data)
            }
        } catch (error) {
            console.log(error)

        }finally{
            setisLoading(false)
        }
    }

    const trashPromocodes = async (data) => {
        try {
            setisDeleting(true)
            const response = await axios.post(apiRoutes.trashPromocode, { promocodeId: data })
            if (response?.data?.success) {
                setisDeleting(false)
                setTrash("")
            }
        } catch (error) {
            console.log(error)
            setisDeleting(false)

        }
    }

    const create = React.useCallback(() => {
        setIsCreate(true)
    }, []);

    const close = React.useCallback(() => {
        setIsCreate(false)
        setisClosed(!isClosed)
    }, [isClosed]);

    const editClose = React.useCallback(() => {
        setIsEdit(false)
        setisClosed(!isClosed)
    }, [isClosed]);


    const columns = [
        {
            title: '#',
            key: "createdAt",
            render: (_, record, index) => index + 1
        },
        {
            title: 'TITLE',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'CODE',
            dataIndex: 'code',
            key: 'code',

        },
        {
            title: 'EXPIRY',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (_, record) => {
                var date = new Date(record.endDate)
                return date.toLocaleDateString("en-US")
            }
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Active
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                InActive
            </Tag>
        },
        {
            title: 'Action',
            key: 'id',
            render: (record) => (
                <Space size="middle" >
                    <Button onClick={() => setIsEdit(record)} icon={<EditTwoTone />} />
                    <Button onClick={() => setTrash(record?.id)} icon={<DeleteTwoTone />} />
                    <Popconfirm
                        title="Are you sure？"
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />}
                        open={trash === record?.id}
                        onConfirm={() => trashPromocodes(record?.id)}
                        okButtonProps={{
                            loading: isDeleting,
                        }}
                        onCancel={() => setTrash("")}
                    />
                </Space>
            ),
        }
    ]

    return (

        <Main page="promocode">
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={'Promocodes'}
                            extra={<Button onClick={create} size="small" type="primary">{'Create'}</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey="id" columns={columns} dataSource={promocodes} pagination={true} search={true} loading={isLoading} size="small" />
                                {isCreate && <PromocodeCreate isCreate={isCreate} close={close} />}
                                {Object.keys(isEdit).length > 0 && <PromocodeEdit isEdit={isEdit} close={editClose} />}

                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

        </Main>

    )
}

export default PromoCode