import { useState, memo } from 'react'
import { Drawer, Space, Button, Input, InputNumber, Form, Row, Col, Radio, Select } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';

function PlanEdit({ editData, close, dataChanged }) {
    const [form] = Form.useForm();

    const [isCreating, setisCreating] = useState(false)
    // const [features, setfeatures] = useState( editData?.features?.length || 0)
    const [features, setfeatures] = useState(editData?.features)

    const onSuccess = value => updatePlan({ ...value, planId: editData?.id })


    const updatePlan = async (data) => {
        try {
            setisCreating(true)
            const response = await axios.post(apiRoutes.updatePlan, data)
            if (response?.data?.success) {
                setisCreating(false)
                dataChanged()
                close()
            }
        } catch (error) {
            setisCreating(false)
        }
    }

    const removeFeature = (index) => {
        console.log(features)
        const tempFeatures = structuredClone(features)
        console.log(tempFeatures)
        console.log(index)
        // console.log(JSON.stringify(tempFeatures))
        // console.log(tempFeatures)
        tempFeatures.splice(index, 1)
        console.log({ tempFeatures })
        setfeatures([{ isAvailable: false, title: "hello" }])
        form.resetFields()

        setTimeout(() => {
            console.log({ features })

        }, 9000);

    }



    return (
        <div>

            <Drawer
                title="Edit plan"
                width={720}
                onClose={() => close({})}
                open={Object.keys(editData).length}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button type="link" disabled={isCreating} onClick={() => setfeatures([...features, 3])}>Add feature</Button>
                        <Button disabled={isCreating} onClick={() => close()}>Cancel</Button>
                        <Button loading={isCreating} onClick={() => form.submit()} type="primary">Submit  </Button>
                    </Space>
                }
            >
                <Form layout="vertical" form={form} onFinish={onSuccess} initialValues={editData} >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="subTitle"
                                label="Subtitle"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="price"
                                label="Price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <InputNumber className="w-100" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="productCount"
                                label="Product"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <InputNumber className="w-100" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="commissionType"
                                label="Commission Type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Radio.Group buttonStyle="solid" value={editData?.commissionType} size="middle" >
                                    <Radio.Button value={1}>Fixed</Radio.Button>
                                    <Radio.Button value={0}>Percentage</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="commission"
                                label="Commission"
                                extra="If plan accept commision on order"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <InputNumber className="w-100" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item
                                name="planType"
                                label="Plan Type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value={1}>Monthly</Radio.Button>
                                    <Radio.Button value={2}>Yearly</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="validity"
                                label="Validity"
                                extra="How much days avilble this benefit"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <InputNumber className="w-100" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>

                            <Form.List name={'features'} initialValues={[
                                { isAvailable: true, title: "yes" }

                            ]}>
                                {(fields, { add, remove }) => (
                                    <>
                                         <Button type="link" onClick={() => add()} >Add feature</Button>
                                        {fields.map(({ key, name, ...restField }, index) => {
                                            return (

                                                <Input.Group compact className="w-100" key={key}>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: '20%' }}
                                                        name={[name, 'isAvailable']} >
                                                        <Select>
                                                            <Select.Option value={true}>Available</Select.Option>
                                                            <Select.Option value={false}>Not available</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: '72%' }}
                                                        name={[name, 'title']} >
                                                        <Input />
                                                    </Form.Item>
                                                    <Button onClick={() => remove(name)} type="danger" size="large"><DeleteOutlined /></Button>
                                                </Input.Group>
                                            )
                                        })}
                                    </>
                                )}
                            </Form.List>

                        </Col>
                    </Row>


                </Form>
            </Drawer>

        </div>
    )
}

export default memo(PlanEdit)