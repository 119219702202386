import { useState, useEffect } from 'react'

import { CheckCircleOutlined, CloseCircleOutlined, EyeTwoTone } from '@ant-design/icons';
import { notification, Avatar, Button, Tag, Table, Card, Col, Row, Badge, Drawer, Divider, Descriptions, Form, Input, Radio } from 'antd'

import apiRoutes from '../../api/apiRoutes'

import axios from '../../api/axios';
import Main from '../../component/layoutnew/Main';
import { motion } from 'framer-motion'




function SellerBanner() {

    const [banner, setbanner] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [status, setstatus] = useState("")
    const [data, setdata] = useState({})
    const [isUpdating, setisUpdating] = useState(false)
    const [isDataChanged, setisDataChanged] = useState(false)


    useEffect(() => {
        getAllBanner()
    }, [isDataChanged])




    const getAllBanner = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllSellerBanner)
            if (response?.data?.data) {
                setbanner(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const openNotificationWithIcon = (type) => {
        notification[type]({
            message: 'Something went wrong',
            description:
                'Failed to update data,Please try again later',
        });
    };

    const onFinish = values => approveBanner(values)



    const approveBanner = async (values) => {
        try {
            setisUpdating(true)
            const options = {
                ...values,
                id: data?.id
            }
            const response = await axios.post(apiRoutes.approveBanner, options)
            if (response?.data?.success) {
                setisUpdating(false)
                setisDataChanged(!isDataChanged)
                setdata({})
                return null
            }
            setisUpdating(false)
            openNotificationWithIcon('error')
        } catch (error) {
            setisUpdating(false)
            openNotificationWithIcon('error')
        }
    }



    const columns = [

        {
            title: 'Image',
            dataIndex: 'bannerTitle',
            key: 'id',
            render: (_, record) =>
                <Avatar className="shape-avatar" src={record.image} alt="" />
        },
        {
            title: 'Title',
            dataIndex: 'bannerTitle',
            key: 'id',
        },
        {
            title: 'Start',
            dataIndex: 'startDate',
            key: 'id',
            render: (_, record) => {
                var date = new Date(record.startDate)
                return date.toLocaleDateString("en-US")
            }

        },
        {
            title: 'Expiry',
            dataIndex: 'endDate',
            key: 'id',
            render: (_, record) => {
                var date = new Date(record.endDate)
                return date.toLocaleDateString("en-US")
            }

        },
        {
            title: 'seller',
            dataIndex: 'sellerId',
            key: 'sellerId',
            render: (_, record) => record?.sellerId?.name
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => <Badge status={record.isApproved ? "success" : record.isRejected ? "error" : "warning"}
                text={record?.isApproved ? "Approved" : record?.isRejected ? "Rejected" : "Pending"} />,
            filters: [{ text: "Approved", value: "isApproved" }, { text: "Rejected", value: "isRejected" }, { text: "Pending", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Publish',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Published
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                Unpublished
            </Tag>,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => <Button onClick={() => setdata(record)} icon={<EyeTwoTone />} />

        }
    ];






    return (
        <Main page='banner'>
            <motion.div className="tabled" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace header-solid"
                            title="Banner request"
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {<Table rowKey="id" bordered={false} size='small' columns={columns} dataSource={banner} pagination={true} search={true} loading={isLoading} style={{ margin: 0 }} 
                                expandable={{
                                    expandedRowRender: (record) => (<p><span>Reason</span>: {record.reason}</p>),
                                    rowExpandable: (record) => record?.isRejected  === true && record?.reason ,
                                }} />}
                                {/* {pageAction.create && <CreateBanner showList={showList} />}
                                {Object.keys(showEdit).length !== 0 && <EditBanner close={hideEdit} data={showEdit} />} */}
                                <Drawer width={640} placement="right" closable={true} onClose={() => setdata({})} open={Object.keys(data).length} title="Banner Details">

                                    <Descriptions column={2}>
                                        <Descriptions.Item label="Title">{data?.bannerTitle}</Descriptions.Item>
                                        <Descriptions.Item label="Seller">{data?.sellerId?.name}</Descriptions.Item>
                                        <Descriptions.Item label="Start On">{new Date(data?.startDate).toLocaleDateString('en-GB')}</Descriptions.Item>
                                        <Descriptions.Item label="End On">{new Date(data?.endDate).toLocaleDateString('en-GB')}</Descriptions.Item>
                                        <Descriptions.Item label="Image">
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Card
                                        hoverable
                                        style={{
                                            width: '100%',
                                            height: 200
                                        }}
                                        cover={<img height={200} width={'100%'} alt="example" src={data?.image} />}
                                    >
                                    </Card>

                                    <Divider />

                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        onFinish={onFinish}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="Status"
                                            name="status"
                                            initialValue={"1"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'please choose status!',
                                                },
                                            ]}
                                        >
                                            <Radio.Group onChange={(e) => setstatus(e.target.value)}>
                                                <Radio.Button value="1">Accept</Radio.Button>
                                                <Radio.Button value="2">Reject</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>

                                        {status == 2 && <Form.Item
                                            label="Reject reason"
                                            name="reason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input reject reason!',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea rows={4} />
                                        </Form.Item>}



                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={isUpdating}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>





                                </Drawer>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </Main>
    )
}

export default SellerBanner