import React, { useState, useEffect } from 'react'
import { EyeTwoTone } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';


import Main from '../../component/layoutnew/Main'
import { Space, Button, Table, Modal, Col, Card, Row } from 'antd'
import apiRoutes from '../../api/apiRoutes'
import { useNavigate } from "react-router-dom";
import axios from '../../api/axios';
const { confirm } = Modal;

function Order() {
    const navigate = useNavigate()
    const [order, setorder] = useState([])
    const [isDataChanged, setisDataChanged] = useState(false)

    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getAllOrder()
    }, [])






    const getAllOrder = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllorders)
            if (response?.data?.data) {
                setorder(response.data.data)
            }
        } catch (error) {
            console.log(error)

        } finally {
            setisLoading(false)
        }
    }




    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Order ID',
            dataIndex: 'id',
            key: 'id',
            // render: (text) => <span>{text}</span>,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.age - b.age,
        },
        {
            title: 'Orderd ON',
            dataIndex: 'createdAt',
            key: 'id',
            render: (_, record) => {
                var date = new Date(record.createdAt)
                return date.toLocaleDateString("en-US")
            }
        },
        {
            title: 'Customer',
            dataIndex: 'customerId.name',
            key: 'id',
            render: (_, record) => record.customerId.name
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'id',
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'id',
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'id',
            render: (_, record) => `₹ ${record.totalAmount}`
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'isActive',
        //     key: 'id',
        //     render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
        //         Active
        //     </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
        //         InActive
        //     </Tag>
        // },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <Button onClick={() => navigate(`/order/${record.id}`)} icon={<EyeTwoTone />} />
                </Space>
            ),
        }
    ];

    const showPromiseConfirm = (data) => {
        confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.deleteSubCategory, { id: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setisDataChanged(!isDataChanged)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };

    return (
        <Main page='order'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={'Orders'}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table columns={columns} dataSource={order} pagination={true} search={true} loading={isLoading} size="small" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Main>
    )
}

export default Order