import { Form, Button, Space, Input, Drawer } from "antd"
import { useState } from "react"
import apiRoutes from "../../api/apiRoutes";
import axios from "../../api/axios";

const TaxCreate = ({ isShow, close, isChange, setIsChange }) => {

    const [isCreating, setIsCreating] = useState(false)

    const [form] = Form.useForm();

    const onFinish = value => {
        createTax(value)
    }


    const createTax = async (data) => {
        try {
            setIsCreating(true)
            const response = await axios.post(apiRoutes.createTax, data)
            if (response.data?.success) {
                setIsCreating(false)
                close(false)
                setIsChange(!isChange)
            }
        } catch (error) {
            console.log(error)
            setIsCreating(false)
        }
    }



    return (

        <div>
            <Drawer width="30%" onClose={() => close(false)} title="Create Tax" placement="right" open={isShow} extra={
                <Space>
                    <Button onClick={() => close(false)}>Cancel</Button>
                    <Button onClick={() => form.submit()} type="primary" loading={isCreating}>
                        Submit
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    size="middle"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Title is requird!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Tax Percentage"
                        name="percentage"
                        rules={[
                            {
                                required: true,
                                message: 'Tax is required!',
                            },
                            {
                                pattern: new RegExp(/^[0-9]+$/),
                                message: 'Please input valid number'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>



                </Form>
            </Drawer>
        </div>

    )
}


export default TaxCreate