import React from 'react'
import { Col, Row, Space, Button, InputNumber, Drawer, Form, Input, Select, DatePicker, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'

function MessageCreate({ isShow, close }) {


    const [fileList, setfileList] = React.useState([])
    const [isToAll, setisToAll] = React.useState(true)
    const [users, setUsers] = React.useState([])
    const [usersLoading, setUsersLoading] = React.useState(false)


    const [selectedCondition, setsSelectedCondition] = React.useState([])
    const [actionType, setActionType] = React.useState("")
    const [category, setCategory] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [form] = Form.useForm();


    // React.useEffect(() => {
    //     getAllUsers()
    // }, [])




    // const getAllUsers = async () => {
    //     try {
    //         setUsersLoading(true)
    //         const response = await axios.get(apiRoutes.getAllUsers)
    //         console.log(response)
    //         if (response?.data?.data) {
    //             setUsers(response.data.data)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     } finally {
    //         setUsersLoading(false)
    //     }
    // }


    const onSuccess = (value) => {
        const notification = {
            notification: {
                title: value.title,
                body: value.message
            }
        }

        const priority = {
            priority: value.priority,
            timeToLive: 86400
        }
        sendMessage({ notification, priority })
    }

    const sendMessage = async (message) => {
        try {
            const response = await axios.post(apiRoutes.sendNotification, message)
            if(response.data?.data.status){
                close()
            }

        } catch (error) {
            console.error(error)
        }

    }


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );


    return (
        <Drawer
            title="Send new Message"
            width='40%'
            onClose={close}
            visible={isShow}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={close}>Cancel</Button>
                    <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical"
                form={form}
                onFinish={onSuccess}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Title" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="message"
                            label="Message"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="Please Enter Terms & Conditions Separate by Comma" />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="priority"
                            label="Priority"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an owner',
                                },
                            ]}
                        >
                            <Select placeholder="Choose Priority" onChange={(value) => setActionType(value)}>
                                <Select.Option value="low">Low</Select.Option>
                                <Select.Option value="Medium">Medium</Select.Option>
                                <Select.Option value="Heigh">High</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="toAll"
                            label="Message To"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an owner',
                                },
                            ]}
                        >
                            <Select placeholder="Choose The Action Type" onChange={(value) => setisToAll(JSON.parse(value))}>
                                <Select.Option value="true">All</Select.Option>
                                {/* <Select.Option value="false">Specific People</Select.Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>



                {!isToAll && <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="clientId"
                            label="Users"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an owner',
                                },
                            ]}
                        >
                            <Select placeholder="Select User" loading={usersLoading} showSearch={true}>
                                {users.map(item => <Select.Option value={item._id}>{item?.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>}


                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Image"
                            name="image"
                        >
                            <Upload maxCount={1} listType="picture-card" fileList={fileList} >
                                {fileList.length >= 1 ? null : uploadButton}
                                {/* {<Button icon={<UploadOutlined />}>Select File</Button>} */}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Drawer>
    )
}

export default React.memo(MessageCreate)