import Chart from "react-apexcharts";
import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'
import { useState,useEffect } from "react";


const SalesReport = () => {
    const [salesData, setsalesData] = useState([])
    const [isLoading, setisLoading] = useState(true)


    useEffect(() => {
        getSalesData()
    }, [])


    const getSalesData = async () => {
        try {
            const response = await axios.post(apiRoutes.inventorySalesReport)
            const { data } = response?.data
            data && setsalesData(data)
            console.log(data)
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }


    const options = {
        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            }
        },
        series: [
            {
                name: "series-1",
                data: salesData
            }
        ]
    }



    return (

        <div style={{ minWidth: '100%', minHeight: '100%', margin: 0, padding: 0 }}>
            <Chart
                options={options.options}
                series={options.series}
                type="bar"
                height={282}
                width="100%"
            />

        </div >
    )
}



export default SalesReport