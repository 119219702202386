import React from 'react'

import Main from '../../component/layoutnew/Main'
import { Col, Row, Card, Menu, Divider } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import AppVersion from '../../component/settings/appversion'



function UserProfile() {
    const [currentMenu, setcurrentMenu] = React.useState('appVersion')


  






    const menuItems = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: 'App Version',
            onClick: () => setcurrentMenu("appVersion")
        }

    ]

    return (


        <Main page='settings'>
            <div className="tabled">
                <Row gutter={24}>
                    <Col span={6} style={{ padding: 0 }}>
                        <Card
                            bordered={false}
                            style={{ textAlign: 'center' }}
                            bodyStyle={{ padding: "0" }}
                        >
                            <Menu
                                mode="inline"
                                theme="light"

                                onClick={() => { }}
                                style={{
                                    width: '100%',
                                    border: 'none'
                                }}
                                defaultSelectedKeys={['appVersion']}
                                items={menuItems}
                            />

                        </Card>

                    </Col>
                    <Divider type="vertical" plain={true} orientation="left" style={{ borderLeftColor: "rgb(240 242 245)", height: "auto", margin: 0 }} />

                    <Col span={17} >
                        {currentMenu === 'appVersion' && <AppVersion />}



                    </Col>
                </Row>
            </div>

        </Main >
    )
}

export default UserProfile