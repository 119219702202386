import React from 'react'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';


import { Button, Form, Input, Select, Modal } from 'antd'

function EditAttributeItem({ hideEdit, dataEdit, showList }) {
    const [form] = Form.useForm();

    const [editData, seteditData] = React.useState({})
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [attributes, setAttributes] = React.useState([])


    React.useEffect(() => {
        console.log(dataEdit)
        seteditData(dataEdit)
    }, [dataEdit])

    React.useEffect(() => {
        getAttributes()
    }, [])



    const getAttributes = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllAttributes)
            if (response?.data) {
                setAttributes(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const onFinish = (values) => {
        console.log(values)
        editAttributeItem(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const editAttributeItem = async (data) => {
        try {
            setConfirmLoading(true)
            data.attributeItemId = dataEdit._id
            const result = await axios.post(apiRoutes.updateAttributeItems, data)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
        } finally {
            setConfirmLoading(false)
            hideEdit()
        }
    }


    return (
        <Modal title="Edit Attribute" confirmLoading={confirmLoading} okText="Update" visible={dataEdit?.title} onOk={() => form.submit()} onCancel={() => { hideEdit() }}>
            <Form
                form={form}
                multiple={true}
                name="basic"
                colon={false}
                labelCol={{
                    span: 6,
                }}
                labelAlign="left"
                wrapperCol={{
                    span: 20,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    initialValue={dataEdit?.title}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Attribue"
                    name="attribute"
                    initialValue={dataEdit?.attribute?._id}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        onChange={() => { }}
                        allowClear
                    >
                        {attributes.map(item => <Select.Option key={item._id} value={item._id}>{item.title}</Select.Option>)}
                    </Select>
                </Form.Item>


            </Form>
        </Modal>
    );
}

export default EditAttributeItem