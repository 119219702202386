import React from 'react'
import { Image, Descriptions } from 'antd'


function BusinessInfo({ data }) {


    return (
        <div className="mt-25">

            <div>
                <Image
                    style={{ marginBottom: 15 }}
                    width={100}
                    src={data?.logo}
                />
                <Descriptions column={2}>
                    <Descriptions.Item label="Name of Business" labelStyle={{ fontColor: 'grey' }}>{data?.name}</Descriptions.Item>
                    <Descriptions.Item label="Description">{data?.description}</Descriptions.Item>
                    <Descriptions.Item label="Lattitude">{data?.lattitude}</Descriptions.Item>
                    <Descriptions.Item label="Longtitude">{data?.longtitude}</Descriptions.Item>
                </Descriptions>

                <Descriptions column={1}>
                    <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>
                </Descriptions>

            </div>
        </div>
    )
}

export default BusinessInfo