import {memo} from 'react'
import { Col, Form, Select, Button, Space } from 'antd'

function OtherInfo({ next, prev, data, isUploading }) {
    return (
        <Col span={24} className="mt-25">
            <Form
                labelAlign="left"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                data={data}
                onFinish={(data) => next(data)}
                autoComplete="off"
            >
                <Form.Item
                    className="mt-25"
                    name="isActive"
                    label="Product Status"
                    hasFeedback
                >
                    <Select defaultValue="Publish">
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>In Active</Select.Option>
                    </Select>
                </Form.Item>


                <Space style={{width: '100%',justifyContent: 'space-between',marginTop:'25%'}}>
                    <Button onClick={() => prev()}>
                        Prev
                    </Button>
                    <Button type="primary" htmlType="submit" loading={isUploading}>
                        Create Vendor
                    </Button>
                </Space>
            </Form>

        </Col>
    )
}

export default memo(OtherInfo)