import { useState, useEffect } from 'react'
import Main from '../component/layoutnew/Main'
import { Row, Col, Card, Button } from 'antd'
import MessageCreate from '../component/pageComponents/messageCreate'

function Message() {

    const [showCreate, setShowCreate] = useState(false)



    return (
        <Main page='message'>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title={'Messages'}
                            extra={<Button onClick={()=>setShowCreate(true)}>Create</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                {showCreate && <MessageCreate isShow={showCreate} close={()=>setShowCreate(false)} />}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Main>
    )
}

export default Message