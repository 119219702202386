import { Button, Descriptions } from 'antd';
import { staticUrl } from '../../../../utils/constant'


const Documents = ({ data, id }) => {

    const bankInfo = {}


    const showFiles = file => {
        // console.log(staticUrl,'url')
        window.open(staticUrl + file)
    }


    return (

        <>

            {/* <Typography.Text strong className="mt-25">Bank Info</Typography.Text> */}


            <Descriptions column={1} className="mt-25">
                <Descriptions.Item label="1.Address Proof"><Button type='link' onClick={() => showFiles(data?.documents?.addProof)}>View</Button></Descriptions.Item>
                <Descriptions.Item label="2.GST "><Button type='link' onClick={() => showFiles(data?.documents?.gst)}>View</Button></Descriptions.Item>
                <Descriptions.Item label="3.Pancard"><Button type='link' onClick={() => showFiles(data?.documents?.panCard)}>View</Button></Descriptions.Item>
                <Descriptions.Item label="4.cheque"><Button type='link' onClick={() => showFiles(data?.documents?.cheque)}>View</Button></Descriptions.Item>
            </Descriptions>
        </>
    )

}

export default Documents