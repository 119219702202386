import React from 'react'

import axios from '../../api/axios';
import apiRoutes from '../../api/apiRoutes';


import { Form, Input, Select, Modal,Switch } from 'antd'

function EditSpecificationGroup({ hideEdit, dataEdit, showList }) {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [action, setAction] = React.useState()
    const [category, setCategory] = React.useState([])
    const [subcategory, setSubCategory] = React.useState([])



    React.useEffect(() => {
        let actionValue = "";
        dataEdit?.category && (actionValue = "category")
        dataEdit?.subCategory && (actionValue = "subcategory")
        setAction(actionValue)
        getAllCategory()
        getAllSubCategory()
    }, [])


    const getAllSubCategory = async () => {
        try {
            const response = await axios.post(apiRoutes.getAllSubCategory)
            if (response?.data?.data) {
                setSubCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const onFinish = (values) => {
        editAttributeItem(values)
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    const editAttributeItem = async (data) => {
        try {
            setConfirmLoading(true)
            data.groupId = dataEdit._id
            const result = await axios.post(apiRoutes.updateSpecificationGRoup, data)
            if (result?.data?.success) {
                showList(true)
            }
        } catch (error) {
            console.log('Failed:', error)
        } finally {
            setConfirmLoading(false)
            hideEdit()
        }
    }


    return (
        <Modal title="Edit Specification group" confirmLoading={confirmLoading} okText="Update" visible={dataEdit?.title} onOk={() => form.submit()} onCancel={() => { hideEdit() }}>
            <Form
                form={form}
                multiple={true}
                name="basic"
                colon={false}
                labelCol={{
                    span: 6,
                }}
                labelAlign="left"
                wrapperCol={{
                    span: 20,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    initialValue={dataEdit?.title}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Assigned to"
                    name="type"
                    initialValue={dataEdit?.category ? "category" : "subcategory"}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required!'
                        },
                    ]}
                >
                    <Select
                        placeholder="Choose Attribute type"
                        onChange={(e) => setAction(e)}
                        allowClear
                    >
                        <Select.Option key={1} value='category'>Category</Select.Option>
                        <Select.Option key={2} value='subcategory'>Sub Category</Select.Option>
                    </Select>
                </Form.Item>

                {action === "category" && <Form.Item
                    label="Category"
                    name="category"
                    initialValue={dataEdit?.category?.id}
                    rules={[
                        {
                            required: true,
                            message: 'Category is required!',
                        }
                    ]}
                >
                    <Select
                        placeholder="Choose Attribute type"
                        allowClear
                    >
                        {category.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                    </Select>
                </Form.Item>}

                {action === "subcategory" && <Form.Item
                    label="Sub Category"
                    name="subCategory"
                    initialValue={dataEdit?.subCategory?.id}
                    rules={[
                        {
                            required: true,
                            message: 'Category is required!',
                        }
                    ]}
                >
                    <Select
                        placeholder="Choose Attribute type"
                        allowClear
                    >
                        {subcategory.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                    </Select>
                </Form.Item>}


                <Form.Item
                    label="Publish"
                    name="isActive"
                    initialValue={dataEdit?.isActive}
                >
                    <Switch size="small" defaultChecked={dataEdit?.isActive} />
                </Form.Item>



            </Form>
        </Modal>
    );
}

export default EditSpecificationGroup