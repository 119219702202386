import React from 'react'
import Main from '../../component/layoutnew/Main'
import { Card, Col, Row, } from 'antd'
import Count from '../../component/pageComponents/inventory/count'
import InventorySummary from '../../component/chart/inventorySummary'
import SalesReport from '../../component/chart/saleReport'
import LowStock from '../../component/pageComponents/inventory/lowStock'
import OutStock from '../../component/pageComponents/inventory/outOfStock'


function Inventory() {
    return (
        <Main page="inventory">
            <Count />
            <Row gutter={[24, 0]} className="mt-20">
                <Col span={8}>
                    <Card title="Inventory summary">
                        <div className="mt-25 mb-25">
                            <InventorySummary />
                        </div>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card title="Sales report" className="header-solid">
                        <SalesReport />
                    </Card>
                </Col>
            </Row>

            <Row gutter={[24, 0]} className="mt-20">
                <Col span={12}>
                    <LowStock />
                </Col>
                <Col span={12}>
                    <OutStock />
                </Col>
            </Row>
        </Main>
    )
}

export default Inventory