import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

const { Content } = Layout



const AppContent = ({ children }) => {
  return <StyledContent>{children}</StyledContent>
}

const StyledContent = styled(Content)`
  margin: 0 0px;
  padding 0 20px;
`

export default AppContent
