import React from 'react'
import { Col, Row, Space, Button, InputNumber, Drawer, Form, Input, Select, DatePicker } from 'antd'

import axios from '../../api/axios'
import apiRoutes from '../../api/apiRoutes'

function PromocodeCreate({ isCreate, close }) {
    const [selectedCondition, setsSelectedCondition] = React.useState([])
    const [actionType, setActionType] = React.useState("")
    const [category, setCategory] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [products, setProducts] = React.useState([])
    const [form] = Form.useForm();


    React.useEffect(() => {
        getAllCategory()
        getAllProducts()
    }, [])

    const onFinish = (value) => {
        const tempValue = {}
        tempValue.title = value.title
        tempValue.code = value.code
        tempValue.startDate = value.duration[0]._d
        tempValue.endDate = value.duration[1]._d
        tempValue.perCustomerLimit = value.perCustomerLimit
        tempValue.totalCustomerLimit = value.totalCustomerLimit
        const action = {}
        action.actionType = value.actionType
        action.discount = value.discount
        action.maxDiscount = value.maxDiscount
        tempValue.action = action
        const condition = {}
        value?.minimumPrice && (condition.totalAmount = value.minimumPrice)
        value?.productId && (condition.Product = value.productId)
        value?.category && (condition.ProductCategory = value.category)
        tempValue.condition = condition
        tempValue.termsAndConditions = value.tc
        createPromoCode(tempValue)
    }


    const createPromoCode = async (data) => {
        setIsLoading(true)
        try {
            const response = await axios.post(apiRoutes.createPromocode, data)
            if (response?.data?.success) {
                close()
            }
            setIsLoading(false)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    const getAllCategory = async () => {
        try {
            const response = await axios.get(apiRoutes.findAllCategory)
            if (response?.data?.data) {
                setCategory(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const getAllProducts = async () => {
        try {
            const response = await axios.get(apiRoutes.getProduts)
            if (response?.data?.data) {
                setProducts(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <Drawer
            title="Create New Promocode"
            width='70%'
            onClose={close}
            open={isCreate}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={close}>Cancel</Button>
                    <Button onClick={() => form.submit()} type="primary" loading={isLoading}>
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Title" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="code"
                            label="Code"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Code should be unique"
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="duration"
                            label="Duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please start date and end date',
                                },
                            ]}
                        >
                            <DatePicker.RangePicker
                                style={{
                                    width: '100%',
                                }}
                                getPopupContainer={(trigger) => trigger.parentElement}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="condition"
                            label="Conditions"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Select placeholder="Please choose atleast one condition" mode="multiple" onChange={(value) => setsSelectedCondition(value)}>
                                <Select.Option value="minimum">Minimum Purchase</Select.Option>
                                <Select.Option value="product">Product</Select.Option>
                                <Select.Option value="category">category</Select.Option>

                            </Select>

                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {selectedCondition.includes('minimum') && <Col span={8}>
                        <Form.Item
                            name="minimumPrice"
                            label="Minimum Purchase"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <InputNumber style={{
                                width: '100%',
                            }} />

                        </Form.Item>
                    </Col>}

                    {selectedCondition.includes('product') && <Col span={8}>
                        <Form.Item
                            name="productId"
                            label="Product Id"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={products.map(item => ({label: item.name,value: item.id}) )}
                            />

                        </Form.Item>
                    </Col>}

                    {selectedCondition.includes('category') &&
                        <Col span={8}>
                            <Form.Item
                                name="category"
                                label="category"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Select placeholder="Please Choose category">
                                    {category.map(item => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>}
                </Row>

                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="actionType"
                            label="Action type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an owner',
                                },
                            ]}
                        >
                            <Select placeholder="Please Choose The Action Type" onChange={(value) => setActionType(value)}>
                                <Select.Option value="percentage">Percentage</Select.Option>
                                <Select.Option value="fixed">Fixed</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="discount"
                            label="Discount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose the type',
                                },
                            ]}
                        >
                            <InputNumber addonAfter={actionType === "percentage" && '%'} style={{
                                width: '100%',
                            }} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="maxDiscount"
                            label="Max Discount Amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose the type',
                                },
                            ]}
                        >
                            <InputNumber style={{
                                width: '100%',
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="perCustomerLimit"
                            label="Per User Limit"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an owner',
                                },
                            ]}
                        >
                            <InputNumber style={{
                                width: '100%',
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="totalCustomerLimit"
                            label="Total User Limit"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose the type',
                                },
                            ]}
                        >
                            <InputNumber style={{
                                width: '100%',
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="tc"
                            label="Terms & Conditions"
                            rules={[
                                {
                                    required: true,
                                    message: 'This Field is required',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="Please Enter Terms & Conditions Separate by Comma" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default React.memo(PromocodeCreate)