import Main from "../../component/layoutnew/Main"
import { Row, Col, Card, Button, Table, Typography, Tag, Space, Modal, Form } from "antd"
import { useState, useEffect } from "react"
import { CheckCircleOutlined, CloseCircleOutlined,ExclamationCircleOutlined } from "@ant-design/icons"
import TaxCreate from "../../component/product/taxCreate"
import TaxEdit from "../../component/product/taxEdit"
import axios from "../../api/axios"
import apiRoutes from "../../api/apiRoutes"



const Tax = () => {

    const [tax, setTax] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState({})
    const [isDataChange, setIsDataChanged] = useState(false)

    useEffect(() => {
        fetchTax()
    }, [isDataChange])

    const fetchTax = async () => {
        try {
            setIsloading(true)
            const response = await axios.get(apiRoutes.getTax)
            console.log(response.data)
            if (response?.data?.data) {
                setTax(response.data.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false)
        }
    }

    const showPromiseConfirm = (data) => {
        Modal.confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to delete these items ?',
            okText: "Delete",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.post(apiRoutes.trashTax, { id: data })
                        .then(res => {
                            if (res?.data?.success) {
                                setIsDataChanged(!isDataChange)
                            }
                            resolve(res)
                        })
                        .catch(err => reject(err));
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };


    const columns = [
        {
            title: '#',
            render: (_, record, index) => index + 1
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'id',
            render: (_, record) =>
                <Typography.Text
                    style={{ width: 150 }} ellipsis={true}
                >
                    {record?.title}
                </Typography.Text>,
        },
        {
            title: 'Tax Percentage',
            dataIndex: 'percentage'
        },
        // {
        //     title: 'CreatedBy',
        //     dataIndex: 'addedBy?.userName',
        //     key: 'id',
        //     render: (_, record) => record.addedBy?.userName

        // },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => record.isActive ? <Tag icon={<CheckCircleOutlined />} color="success">
                Published
            </Tag> : <Tag icon={<CloseCircleOutlined />} color="error">
                Unpublished
            </Tag>,
            filters: [{ text: "Published", value: true }, { text: "Unpublished", value: false }],
            onFilter: (value, record) => record?.isActive === value
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle" >
                    <Button onClick={() => setIsEdit(record)}>Edit </Button>
                    <Button onClick={() => showPromiseConfirm(record?.id || record?._id)}>Delete</Button>
                </Space>
            ),
        }
    ]


    return (
        <Main page="tax">
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace"
                            title="Tax"
                            extra={<Button onClick={() => setIsCreate(true)} size="small" type="primary">Create</Button>}
                        >
                            <div className="table-responsive" style={{ margin: "0 15px" }}>
                                <Table rowKey="_id" columns={columns} dataSource={tax} pagination={true} search={true} loading={isLoading} size="small" />
                                <TaxCreate isShow={isCreate} close={setIsCreate} isChange={isDataChange} setIsChange={setIsDataChanged} />
                                <TaxEdit isShow={isEdit} close={setIsEdit} isChange={isDataChange} setIsChange={setIsDataChanged} />

                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>


        </Main>
    )
}




export default Tax